import { Routes } from '@packages/constants';
import { DistanceUnit } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import { formatDate, FormatDateOptions } from '@packages/utils';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuthUser } from '../../contexts/useAuthUser';
import { updateUserSettings } from '../../lib/firebase/firestore';
import Button from './Button';
import Card from './Card';
import Modal from './Modal';
import Switch from './Switch';

export type ModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

const SettingsModal: FC<ModalProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user } = useAuthUser();

  const [includeTimezone, setIncludeTimezone] = useState(user?.settings.includeTimezone ?? false);
  const [force24HoursFormat, setForce24HoursFormat] = useState(user?.settings.force24HoursFormat ?? true);
  const [allowMarketingMails, setAllowMarketingMails] = useState(user?.settings.allowMarketingMails ?? false);
  const [allowSessionReportMails, setAllowSessionReportMails] = useState(user?.settings.allowSessionReportMails ?? true);
  const [forceDistanceUnit, setForceDistanceUnit] = useState<DistanceUnit>(DistanceUnit.METRIC);

  const [previewDate, setPreviewDate] = useState(formatDate(new Date()));

  function onSave() {
    updateUserSettings({
      includeTimezone,
      force24HoursFormat,
      allowMarketingMails,
      allowSessionReportMails,
      forceDistanceUnit,
    })
      ?.then(() => toast.success(t('settings_save_success_message'), { autoClose: 2000 }))
      .catch(err => toast.error(t('settings_save_error_message').replace('%s', err.message)));

    setIsOpen(false);
  }

  useEffect(() => {
    setIncludeTimezone(user?.settings.includeTimezone ?? false);
    setForce24HoursFormat(user?.settings.force24HoursFormat ?? true);
    setAllowMarketingMails(user?.settings.allowMarketingMails ?? false);
    setAllowSessionReportMails(user?.settings.allowSessionReportMails ?? true);
    setForceDistanceUnit(user?.settings.forceDistanceUnit);
  }, [user?.settings]);

  useEffect(() => {
    const options: FormatDateOptions = {
      hasTimezone: includeTimezone,
      has24Hours: force24HoursFormat,
      today: undefined,
    };

    setPreviewDate(formatDate(new Date(), options));
  }, [includeTimezone, force24HoursFormat]);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      setIsOpen={() => {
        setIsOpen(false);
      }}
    >
      <div className="mb-8 flex items-start justify-between border-b px-5 py-5">
        <span className="text-[16px] font-[500]">Settings</span>
        <button
          type="button"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <MdClose className="text-muted h-5 w-5" />
        </button>
      </div>
      <div>
        <div className="space-y-8 px-5 pb-5">
          <Card title={t('settings_date_and_time_title')} className="space-y-3 border pb-10" noShadow>
            <div className="text-muted mt-10">{previewDate}</div>
            <Switch checked={includeTimezone} onChange={setIncludeTimezone}>
              {t('setting_include_timezone')}
            </Switch>
            <Switch checked={force24HoursFormat} onChange={setForce24HoursFormat}>
              {t('setting_force_24_hours')}
            </Switch>
          </Card>

          <Card title={t('settings_distance_unit_title')} className="border pb-10" noShadow>
            <div className="flex w-full">
              <button
                type="button"
                onClick={() => setForceDistanceUnit(DistanceUnit.METRIC)}
                className={classNames(
                  { 'bg-[#343a40] text-[#eff2f7] hover:bg-[#2c3136]': forceDistanceUnit === DistanceUnit.METRIC },
                  { 'bg-[#eff2f7] text-black hover:bg-[#f1f4f8]': forceDistanceUnit !== DistanceUnit.METRIC },
                  'w-1/2 rounded-l px-4 py-3',
                )}
              >
                {t('distance_kilometers')}
              </button>
              <button
                type="button"
                onClick={() => setForceDistanceUnit(DistanceUnit.IMPERIAL)}
                className={classNames(
                  { 'bg-[#343a40] text-[#eff2f7] hover:bg-[#2c3136]': forceDistanceUnit === DistanceUnit.IMPERIAL },
                  { 'bg-[#eff2f7] text-black hover:bg-[#f1f4f8]': forceDistanceUnit !== DistanceUnit.IMPERIAL },
                  'w-1/2 rounded-r px-4 py-3',
                )}
              >
                {t('distance_miles')}
              </button>
            </div>
          </Card>

          <Card title={t('settings_marketing_mails_title')} className="border pb-10" noShadow>
            <Switch checked={allowMarketingMails} onChange={setAllowMarketingMails}>
              {t('settings_marketing_mails')}
            </Switch>
          </Card>

          <Card title={t('settings_session_report_mails_title')} className="border pb-10" noShadow>
            <Switch checked={allowSessionReportMails} onChange={setAllowSessionReportMails}>
              {t('settings_session_report_mails')}
            </Switch>
          </Card>

          <Card title="Delete Account" className="border pb-10" noShadow>
            <p className="text-muted font-[14px] pb-6">
              To initiate the permanent deletion of your account, please click on the button below. You will be redirected to a separate
              account deletion page.
            </p>

            <Button
              variant="danger"
              onClick={() => {
                navigate(Routes.web.DELETE_ACCOUNT);
              }}
            >
              Delete Account
            </Button>
          </Card>
        </div>
      </div>

      <div className="flex justify-end gap-2 border-t px-5 py-5">
        <Button
          className="w-full"
          onClick={() => {
            closeModal();
          }}
        >
          {t('close')}
        </Button>

        <Button
          className="w-full"
          variant="success"
          onClick={() => {
            onSave();
          }}
        >
          {t('save')}
        </Button>
      </div>
    </Modal>
  );
};

export default SettingsModal;
