import { ReactNode } from 'react';

interface BadgeProps {
  variant?: string;
  children: ReactNode;
}

export default function Badge({ variant = 'neutral', children }: BadgeProps) {
  return (
    <span className={`badge-${variant} inline-flex items-center rounded px-[4px] py-[3px] text-[12px] font-[500] leading-none`}>
      {children}
    </span>
  );
}
