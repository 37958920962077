import { OrganType, OrganTypeUtil } from '@packages/firebase';
import Badge from './Badge';

function OrganTypeBadge({ organ }: { organ: OrganType }) {
  return (
    <Badge variant={OrganTypeUtil.cssClass(organ)}>
      <span className=" mr-1.5 h-[12px] w-[12px]">
        <img src={OrganTypeUtil.iconPath(organ)} alt="" />
      </span>
      {OrganTypeUtil.organString(organ)}
    </Badge>
  );
}

export default OrganTypeBadge;
