// import { Routes } from '@packages/constants';
import { Routes } from '@packages/constants';
import { AuditLogType } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { MdOutlineAddCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import InputField from '../components/Forms/InputField';
import AppContent from '../components/Layouts/AppLayout/Content';
import Button from '../components/Shared/Button';
import Card from '../components/Shared/Card';
import { useAuthUser } from '../contexts/useAuthUser';
import { createAuditLog } from '../lib/firebase/firestore';
import { joinSession } from '../lib/firebase/functions';

const SessionsJoin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authUser } = useAuthUser();
  const [isLoading, setIsLoading] = useState(false);

  const [error] = useState<any>(null);

  const initialValues = {
    sessionKey: '',
  };

  const validationSchema = yup.object().shape({
    sessionKey: yup.string().required('Session key is required'),
  });

  async function onSubmit({ sessionKey }) {
    setIsLoading(true);
    const joinKey = Number(sessionKey);

    joinSession(joinKey)
      .then((result: any) => {
        createAuditLog(authUser.uid, result.data, AuditLogType.SESSION_JOINED);

        navigate(Routes.web.SESSION_DETAIL.replace(':id', result.data));

        toast.success(t('session_join_success'), {
          autoClose: 3000,
        });
      })
      .catch(err => {
        toast.error(t('session_join_error').replace('%s', err.message));
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <AppContent>
      <Helmet>
        <title>{t('meta_title_join_session')} | Paragonix</title>
      </Helmet>
      <Card title={t('join_session')}>
        <p className="text-muted">{t('join_subtitle')}</p>

        <div className="mt-10">
          {error && error.message}
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={values => onSubmit(values)}>
            {({ errors, touched }) => (
              <Form className="sm:grid sm:grid-cols-12 sm:gap-4">
                <div className="pt-1 sm:col-span-3">
                  <label htmlFor="sessionKey" className={classNames('block font-medium text-dark', { 'text-red-500': error && touched })}>
                    {t('session_key')}
                  </label>
                </div>
                <div className="sm:col-span-9">
                  <InputField
                    type="text"
                    placeholder={t('join_placeholder')}
                    name="sessionKey"
                    error={errors.sessionKey}
                    touched={touched.sessionKey}
                  />
                  <Button type="submit" isLoading={isLoading || false} className="mt-4" Icon={MdOutlineAddCircle}>
                    {t('join_session')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Card>
    </AppContent>
  );
};

export default SessionsJoin;
