import Avatar from '../../Avatar';

export default function avatarFormatter({ value }: { value: { firstName: string; lastName: string } }) {
  return (
    <div className="pr-3 whitespace-nowrap">
      <div className="flex items-center">
        <Avatar firstname={value.firstName} lastname={value.lastName} />
      </div>
    </div>
  );
}
