import { DonationType, DonationTypeUtil } from '@packages/firebase';
import { BadgeStyleTypes } from '../../Badge';
import Badge from '../../Badges/Badge';

function donationTypeFormatter({ value }: { value: DonationType }) {
  if (value == null) {
    return <Badge variant="danger">Unknown</Badge>;
  }

  const styleType: BadgeStyleTypes = value === DonationType.DBD ? 'success' : 'warning';

  return <Badge variant={styleType}>{DonationTypeUtil.toUIString(value)}</Badge>;
}

export default donationTypeFormatter;
