import { Assets } from '@packages/assets';
import { Routes } from '@packages/constants';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import EmptyLayout from '../components/Layouts/EmptyLayout';
import { confirmEmailVerification } from '../lib/firebase/functions';
import { detectEnvironment } from '../utils/detectEnvironment';
import { getMobileAppRedirectUrlScheme } from '../utils/getMobileAppRedirectUrlScheme';

const VerifyEmail = () => {
  const environment = detectEnvironment();
  const urlScheme = getMobileAppRedirectUrlScheme();

  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isCodeError, setIsCodeError] = useState(false);
  const [isEmailVerificationError, setIsEmailVerificationError] = useState(false);
  const [isEmailVerificationSuccess, setIsEmailVerificationSuccess] = useState(false);

  useEffect(() => {
    const code = searchParams.get('code');

    if (code == null) {
      setIsCodeError(true);
      setIsLoading(false);

      return;
    }

    confirmEmailVerification(code)
      .then(() => {
        setIsEmailVerificationSuccess(true);
      })
      .catch(() => {
        setIsEmailVerificationError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <div className="spinner-border inline-block h-7 w-7 animate-spin rounded-full border-2" role="status" />
      </div>
    );
  }

  return (
    <EmptyLayout title="Verify Email | Paragonix">
      <div className="mx-auto max-w-xl px-6 py-12">
        <div className="flex justify-center pb-8">
          <img src={Assets.logo.paragonixLogoLightPng} style={{ height: '18px' }} alt="" />
        </div>

        {(isCodeError || isEmailVerificationError) && (
          <div className="mb-6 space-y-6">
            <div className="rounded border border-[#fcd2d2] bg-[#fde1e1] px-6 py-3 text-center text-[#924040]">
              Invalid or expired email verification code. Please try again.
            </div>

            {environment !== 'web' ? (
              <a
                href={urlScheme}
                className="inline-flex w-full items-center justify-center gap-2 rounded border border-[#343a40] bg-[#343a40] px-3 py-2 text-base text-[#eff2f7] outline-none hover:border-[#2c3136] hover:bg-[#2c3136]"
              >
                Back to App
              </a>
            ) : (
              <a href={Routes.web.DASHBOARD} className="block text-center underline">
                Go back
              </a>
            )}
          </div>
        )}

        {!isCodeError && isEmailVerificationSuccess && (
          <div className="mb-6 space-y-6">
            <div className="rounded border border-[#d2fcd5] bg-[#e1fdea] px-6 py-3 text-center text-[#40925d]">
              Your email has been verified successfully. You can login to your account now.
            </div>

            {environment !== 'web' ? (
              <a
                href={urlScheme}
                className="inline-flex w-full items-center justify-center gap-2 rounded border border-[#343a40] bg-[#343a40] px-3 py-2 text-base text-[#eff2f7] outline-none hover:border-[#2c3136] hover:bg-[#2c3136]"
              >
                Back to App
              </a>
            ) : (
              <a href={Routes.web.DASHBOARD} className="block text-center underline">
                Go back
              </a>
            )}
          </div>
        )}
      </div>
    </EmptyLayout>
  );
};

export default VerifyEmail;
