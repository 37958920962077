import { Routes } from '@packages/constants';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const AuthActionRedirect = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const mode = searchParams.get('mode');
    const code = searchParams.get('oobCode');

    if (mode === 'verifyEmail') {
      navigate(`${Routes.web.VERIFY_EMAIL}?code=${code}`);
    } else if (mode === 'resetPassword') {
      navigate(`${Routes.web.RESET_PASSWORD}?code=${code}`);
    }
  }, []);

  return null;
};

export default AuthActionRedirect;
