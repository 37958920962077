import classNames from 'classnames';
import { ReactNode } from 'react';

type AppContentProps = {
  noPadding?: boolean;
  children: ReactNode;
};

export default function AppContent({ noPadding, children }: AppContentProps) {
  return <div className={classNames({ 'p-0': noPadding, 'p-6 sm:p-10': !noPadding })}>{children}</div>;
}
