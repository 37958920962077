import { Assets } from '@packages/assets';
import { OrganizationMemberStatus } from '@packages/firebase';
import { useNavigate, useSearchParams } from 'react-router-dom';
import withUser from '../components/HOC/withUser';
import EmptyLayout from '../components/Layouts/EmptyLayout';
import Button from '../components/Shared/Button';
import Card from '../components/Shared/Card';
import { useAuthUser } from '../contexts/useAuthUser';

const OrganizationJoinNotice = () => {
  const navigate = useNavigate();
  const { user, member } = useAuthUser();

  const [params] = useSearchParams();

  const id = params.get('id');
  const name = params.get('name');

  if (user != null && member != null && member.status === OrganizationMemberStatus.ACTIVE) {
    navigate(`/organization/${id}`);
  }

  return (
    <EmptyLayout title="Organization join | Paragonix">
      <div className="mx-auto max-w-xl px-6 py-12">
        <div className="flex justify-center pb-8">
          <img src={Assets.logo.paragonixLogoLightPng} style={{ height: '18px' }} alt="" />
        </div>

        <Card title="Join Organization">
          <div className="space-y-4">
            <p className="text-muted font-[14px]">
              You are invited to join <strong>{name}</strong>. Click the button below to provide your profile data and accept the
              invitation.
            </p>

            <a className="block" href={`/organization/${id}/join`}>
              <Button variant="success" className="w-full">
                Complete Organization Profile
              </Button>
            </a>
          </div>
        </Card>
      </div>
    </EmptyLayout>
  );
};

export default withUser(OrganizationJoinNotice);
