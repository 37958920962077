import { Notification } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import { toast } from 'react-toastify';
import Button from '../Button';

function NotificationContent({ notification }: { notification: Notification }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row">
      {notification.iconSrc != null && (
        <div className="mr-3">
          <img src={notification.iconSrc} alt="" height="26px" width="26px" />
        </div>
      )}
      <div className="mt-1">
        <h5 className="text-xl text-dark mb-2">{notification.title}</h5>
        <p className="text-muted mb-3">{notification.body}</p>
        <div>
          {notification.action != null &&
            notification.action.text != null &&
            notification.action.text.length > 0 &&
            notification.action.to != null && (
              <Button type="button" variant="outline" className="border-none mr-2 font-bold text-dark" onClick={() => toast.dismiss()}>
                {notification.action.text}
              </Button>
            )}

          {notification.isDismissable && (
            <Button type="button" variant="outline" className="border-none font-bold text-muted" onClick={() => toast.dismiss()}>
              {t('dismiss')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default NotificationContent;
