import { Colors } from '@packages/constants';
import { OrganType, ProductType, Session, SessionState } from '@packages/firebase';
import { createContext, useContext, useMemo, useState } from 'react';

export type SessionContextProps = {
  currentSession: Session | undefined;
  setCurrentSession: (session: Session | undefined) => void;
  product: ProductType | undefined;
  cssClass?: string;
  color:
    | {
        primary: string;
        primaryLight: string;
      }
    | undefined;
  isLive: boolean;
};

const SessionContext = createContext<SessionContextProps | undefined>(undefined);

export const SessionProvider = ({ children }) => {
  const [currentSession, setCurrentSession] = useState<Session | undefined>(undefined);

  const value = useMemo(() => {
    return {
      currentSession,
      setCurrentSession,
      product: currentSession?.product,
      color: getOrganColors(currentSession?.organ),
      isLive: currentSession?.getState() !== SessionState.FINISHED,
      cssClass: currentSession?.organ.toLowerCase(),
    };
  }, [currentSession]);

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};

export function useSession() {
  const context = useContext(SessionContext);

  if (context == null) {
    throw Error('useSession must be used inside session context');
  }

  return context;
}

export function getOrganColors(organ?: OrganType) {
  if (organ === OrganType.LUNG) {
    return {
      primary: Colors.COLOR_LUNG_PRIMARY,
      primaryLight: Colors.COLOR_LUNG_PRIMARY_LIGHT,
    };
  }

  if (organ === OrganType.LIVER) {
    return {
      primary: Colors.COLOR_LIVER_PRIMARY,
      primaryLight: Colors.COLOR_LIVER_PRIMARY_LIGHT,
    };
  }

  if (organ === OrganType.PANCREAS) {
    return {
      primary: Colors.COLOR_PANCREAS_PRIMARY,
      primaryLight: Colors.COLOR_PANCREAS_PRIMARY_LIGHT,
    };
  }

  if (organ === OrganType.KIDNEY) {
    return {
      primary: Colors.COLOR_KIDNEY_PRIMARY,
      primaryLight: Colors.COLOR_KIDNEY_PRIMARY_LIGHT,
    };
  }

  return {
    primary: Colors.COLOR_HEART_PRIMARY,
    primaryLight: Colors.COLOR_HEART_PRIMARY_LIGHT,
  };
}
