import { SessionMember } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import Table from '../../Shared/Table';
import CellFormatters from '../../Shared/Table/CellFormatters';

export default function MembersTable({ members }) {
  const { t } = useTranslation();

  const columns = [
    {
      Header: '',
      id: 'avatar',
      accessor: row => ({ firstName: row.firstname, lastName: row.lastname }),
      Cell: CellFormatters.avatar,
    },
    {
      id: 'name',
      Header: t('name'),
      accessor: row => `${row.firstname} ${row.lastname}`,
    },
    {
      Header: t('joined_at'),
      accessor: 'joinedAt',
      Cell: CellFormatters.datetime,
      sortType: 'datetime',
    },
    {
      Header: t('email'),
      accessor: 'email',
    },
    {
      Header: t('organization'),
      accessor: 'organization',
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        data={members as SessionMember[]}
        options={{ hasPagination: false, hasRowSelect: false, initialSortBy: [{ id: 'joinedAt', desc: true }] }}
      />
    </div>
  );
}
