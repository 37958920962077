import { useTranslation } from '@packages/translations';
import { useEffect, useState } from 'react';
import { MdEmail } from 'react-icons/md';
import { useAuthUser } from '../../contexts/useAuthUser';
import { updateUserSettings } from '../../lib/firebase/firestore';
import Button from './Button';
import Modal from './Modal';

function AllowMarketingMailsModal() {
  const { t } = useTranslation();

  const { user } = useAuthUser();

  const [isOpen, setOpen] = useState(false);

  function onAccept() {
    updateUserSettings({ allowMarketingMails: true });
    setOpen(false);
  }

  function onDecline() {
    updateUserSettings({ allowMarketingMails: false });
    setOpen(false);
  }

  useEffect(() => {
    if (user == null || user?.settings.allowMarketingMails != null) {
      return;
    }

    if (document.getElementById('notifications_modal') != null) {
      return;
    }

    setTimeout(() => setOpen(true), 2000);
  }, [user]);

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      setIsOpen={value => {
        setOpen(value);
      }}
    >
      <div className="px-20 pt-5 pb-10 text-center">
        <div className="inline-block mb-4">
          <div className="p-6 bg-gray-100 rounded-full text-dark">
            <MdEmail className="w-10 h-10" />
          </div>
        </div>

        <div className="">
          <h4 className="text-black text-[19px] font-[500] mb-4">{t('marketing_mails_title')}</h4>
          <p className="text-muted font-[14px] mb-12">{t('marketing_mails_info')}</p>

          <div>
            <Button
              type="button"
              variant="success"
              className="mx-1"
              onClick={() => {
                onAccept();
              }}
            >
              {t('yes')}
            </Button>
            <Button
              type="button"
              variant="primary"
              className="mx-1"
              onClick={() => {
                onDecline();
              }}
            >
              {t('no')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AllowMarketingMailsModal;
