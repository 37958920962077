import { OrganizationMemberStatus, OrganizationStatus } from '@packages/firebase';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuthUser } from '../../contexts/useAuthUser';
import { logout } from '../../lib/firebase/auth';
import {
  changeOrganizationUserRole,
  changeOrganizationUserTitle,
  updateUserFirstname,
  updateUserLastname,
  useFetchOrganizationUserRolesConfig,
} from '../../lib/firebase/firestore';
import { leaveOrganization } from '../../lib/firebase/functions';
import InputField from '../Forms/InputField';
import SelectField from '../Forms/SelectField';
import Button from './Button';
import Card from './Card';
import Modal from './Modal';

export type ProfileModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

const ProfileModal = ({ isOpen, setIsOpen }: ProfileModalProps) => {
  const navigate = useNavigate();

  const { user, organization, member, isLoading: isUserLoading } = useAuthUser();
  const config = useFetchOrganizationUserRolesConfig();

  const [isLeavingOrganization, setIsLeavingOrganization] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  async function onHandleLeaveOrganization() {
    if (organization == null) {
      return;
    }

    const confirm = window.confirm(
      member?.isEmailWhitelisted
        ? 'We’re sorry to see you go! Please be aware that closing your account is permanent, you will lose access to all account data including the session history of the organization. You won’t be able to log back in.'
        : 'We’re sorry to see you go! Please be aware that by leaving the organization you will lose access to all account data including the session history of the organization, and your account will be reverted to an individual account.',
    );

    if (!confirm) {
      return;
    }

    setIsLeavingOrganization(true);

    try {
      await leaveOrganization(organization.id);
      setIsLeavingOrganization(false);
    } catch (err: any) {
      toast.error(`Failed to leave organization: ${err.message}`);
    }

    if (member?.isEmailWhitelisted) {
      logout();
    } else {
      navigate('/select-organization?leave=true');
    }
  }

  if (user == null || isUserLoading) {
    return null;
  }

  const organizationId = user.organizationIds?.[0];

  return (
    <Modal size="xl" isOpen={isOpen} setIsOpen={() => closeModal()}>
      <div className="mb-8 flex items-start justify-between border-b px-5 py-5">
        <span className="text-[16px] font-[500]">Profile</span>

        <button
          type="button"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <MdClose className="text-muted h-5 w-5" />
        </button>
      </div>

      <Formik
        initialValues={{
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          organization: organization?.name,
          role: member?.role,
          title: member?.title,
          startOfEmployment: member?.startOfEmployment
            ? `${member.startOfEmployment.getFullYear()}-${(member.startOfEmployment.getMonth() + 1)
                .toString()
                .padStart(2, '0')}-${member.startOfEmployment.getDate().toString().padStart(2, '0')}`
            : '',
        }}
        onSubmit={async values => {
          if (values.firstname != null && values.firstname.length > 0 && values.firstname !== user.firstname) {
            await updateUserFirstname(values.firstname);
          }

          if (values.lastname != null && values.lastname.length > 0 && values.lastname !== user.lastname) {
            await updateUserLastname(values.lastname);
          }

          if (organization?.id != null) {
            if (values.role != null && values.role.length > 0 && values.role !== member?.role) {
              await changeOrganizationUserRole(organization.id, user.id, values.role);
            }

            if (values.title != null && values.title.length > 0 && values.title !== member?.title) {
              await changeOrganizationUserTitle(organization.id, user.id, values.title);
            }
          }

          toast.success('Saved Profile', { autoClose: 2000 });
        }}
      >
        <Form>
          <div className="px-5 pb-5">
            <div className="space-y-8">
              <Card title="Personal Information" noShadow className="border pb-10">
                <div className="space-y-4">
                  <InputField type="text" name="email" label="Email" disabled />
                  <InputField type="text" name="firstname" label="First name" />
                  <InputField type="text" name="lastname" label="Last name" />
                </div>
              </Card>

              {organization == null && member == null && (
                <Card title="Choose an Organization" noShadow className="border">
                  <div className="space-y-4">
                    <p className="text-muted font-[14px]">
                      Let us know which organization you are currently a part of. Click the button below to choose an organization.
                    </p>

                    <a className="block" href="/select-organization">
                      <Button variant="success" className="w-full">
                        Choose Organization
                      </Button>
                    </a>
                  </div>
                </Card>
              )}

              {organization != null && member != null && member.status === OrganizationMemberStatus.INVITED && (
                <Card title="Join Organization" noShadow className="border">
                  <div className="space-y-4">
                    <p className="text-muted font-[14px]">
                      You are invited to join <strong>{organization.name}</strong>. Click the button below to provide your profile data and
                      accept the invitation.
                    </p>

                    <a className="block" href={`/organization/${organizationId}/join`}>
                      <Button variant="success" className="w-full">
                        Complete Organization Profile
                      </Button>
                    </a>
                  </div>
                </Card>
              )}

              {organization != null && member != null && member.status !== OrganizationMemberStatus.INVITED && (
                <Card title="Organization Profile" noShadow className="border">
                  <div className="space-y-4">
                    <InputField type="text" name="organization" label="Organization" disabled />

                    <SelectField name="role" label="Role">
                      <option key="select-role">--- Select a Role ---</option>

                      {config.userRoles?.map(role => (
                        <option key={role.id} value={role.id}>
                          {role.locales?.en}
                        </option>
                      ))}
                    </SelectField>

                    <InputField type="text" name="title" label="Title" />

                    <InputField type="date" name="startOfEmployment" label="Start of Employment" />

                    {member.status === OrganizationMemberStatus.ACTIVE && (
                      <Button
                        variant="outline"
                        className="w-full"
                        isLoading={isLeavingOrganization}
                        onClick={() => onHandleLeaveOrganization()}
                      >
                        <span className="text-red-500">Leave Organization</span>
                      </Button>
                    )}

                    {organization.status === OrganizationStatus.ACTIVE && member.status === OrganizationMemberStatus.ACCESS_REQUESTED && (
                      <p className="text-muted text-center">
                        Your request to join <strong>{organization.name}</strong> is pending.
                      </p>
                    )}
                  </div>
                </Card>
              )}
            </div>
          </div>

          <div className="flex justify-end gap-2 border-t px-5 py-5">
            <Button
              className="w-full"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </Button>

            <Button type="submit" variant="success" className="w-full">
              Save
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ProfileModal;
