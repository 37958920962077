import { Session, TimelineEntry } from '@packages/firebase';
import TimelineItem from './TimelineItem';

export type TimelineProps = {
  session: Session;
  timeline: TimelineEntry[] | undefined;
};

export default function Timeline({ session, timeline }: TimelineProps) {
  return (
    <div className="flow-root">
      <ul className="py-5 border-l-[3px] border-dashed border-[#f6f6f6] space-y-10">
        {(timeline as TimelineEntry[]).map((event, index) => (
          <li key={index} className="">
            <div className="relative">
              <TimelineItem event={event} product={session.product} withCircle />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
