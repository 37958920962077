export function getMobileAppRedirectUrlScheme() {
  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    return 'paragonix-development://';
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    return 'paragonix-staging://';
  }

  return 'paragonix://';
}
