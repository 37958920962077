import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import app from './config';

export function getFirebaseStorage() {
  return getStorage(app);
}

export function getDownloadUrl(storageRef: string): Promise<string> {
  const storage = getStorage();

  return getDownloadURL(ref(storage, storageRef));
}
