import { Routes as RouteDefs } from '@packages/constants';
import { BrowserRouter, Navigate, Route, Routes as Switch } from 'react-router-dom';
import AcceptTerms from '../pages/AcceptTerms';
import AuthActionRedirect from '../pages/AuthActionRedirect';
import AuthOutlet from '../pages/AuthOutlet';
import Dashboard from '../pages/Dashboard';
import DeleteAccount from '../pages/DeleteAccount';
import ForgotPassword from '../pages/ForgotPassword';
import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import OrganizationDetail from '../pages/OrganizationDetail';
import OrganizationJoin from '../pages/OrganizationJoin';
import OrganizationJoinNotice from '../pages/OrganizationJoinNotice';
import OrganizationRegister from '../pages/OrganizationRegister';
import OrganizationSelect from '../pages/OrganizationSelect';
import OrganizationSetup from '../pages/OrganizationSetup';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Register from '../pages/Register';
import ResetPassword from '../pages/ResetPassword';
import Sessions from '../pages/Sessions';
import SessionsDetail from '../pages/SessionsDetail';
import SessionsJoin from '../pages/SessionsJoin';
import Support from '../pages/Support';
import TermsOfUse from '../pages/TermsOfUse';
import VerifyEmail from '../pages/VerifyEmail';
import VerifyEmailNotice from '../pages/VerifyEmailNotice';

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={RouteDefs.web.LOGIN} element={<Login />} />
        <Route path={RouteDefs.web.REGISTER} element={<Register />} />
        <Route path={RouteDefs.web.AUTH_ACTION_REDIRECT} element={<AuthActionRedirect />} />
        <Route path={RouteDefs.web.FORGOT_PASSWORD} element={<ForgotPassword />} />

        <Route path="/" element={<Navigate to={RouteDefs.web.DASHBOARD} replace />} />

        <Route path="/" element={<AuthOutlet />}>
          <Route path={RouteDefs.web.DASHBOARD} element={<Dashboard />} />
          <Route path={RouteDefs.web.SESSIONS} element={<Sessions />} />

          <Route path={RouteDefs.web.SESSION_DETAIL}>
            <Route index element={<SessionsDetail />} />
          </Route>

          <Route path={RouteDefs.web.SUPPORT} element={<Support />} />

          <Route path={RouteDefs.web.JOIN_NO_KEY}>
            <Route index element={<SessionsJoin />} />
            <Route path=":joinKey" element={<SessionsJoin />} />
          </Route>

          <Route path="organization/:organizationId">
            <Route index element={<OrganizationDetail />} />
            <Route path="setup" element={<OrganizationSetup />} />
            <Route path="join" element={<OrganizationJoin />} />
            <Route path="register" element={<OrganizationRegister />} />
          </Route>
        </Route>

        <Route path="/organization/join-notice" element={<OrganizationJoinNotice />} />
        <Route path="/select-organization" element={<OrganizationSelect />} />

        <Route path={RouteDefs.web.RESET_PASSWORD} element={<ResetPassword />} />

        <Route path={RouteDefs.web.VERIFY_EMAIL_NOTICE} element={<VerifyEmailNotice />} />

        <Route path={RouteDefs.web.VERIFY_EMAIL} element={<VerifyEmail />} />

        <Route path={RouteDefs.web.DELETE_ACCOUNT} element={<DeleteAccount />} />

        <Route path="/">
          <Route path={RouteDefs.web.ACCEPT_TERMS} element={<AcceptTerms />} />
        </Route>

        <Route path={RouteDefs.web.TERMS_OF_USE} element={<TermsOfUse />} />
        <Route path={RouteDefs.web.PRIVACY_POLICY} element={<PrivacyPolicy />} />

        <Route path="*" element={<NotFound />} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
