import { useTranslation } from '@packages/translations';
import CsvDownloader from 'react-csv-downloader';
import { MdDownload } from 'react-icons/md';
import Button from './Button';

function ExportAction({ filename, columns, data }) {
  const { t } = useTranslation();

  return (
    <CsvDownloader filename={filename} columns={columns} datas={data}>
      <Button className="mt-4" Icon={MdDownload}>
        {t('export')}
      </Button>
    </CsvDownloader>
  );
}

export default ExportAction;
