import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import app, { FIREBASE_CONFIG } from './config';
import { storePushToken } from './firestore';

export function getFirebaseMessaging() {
  return getMessaging(app);
}

export async function getMessagingToken(userId: string) {
  const messaging = getFirebaseMessaging();

  const serviceWorkerRegistration = await registerMessagingServiceWorker();

  const currentToken = await getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    serviceWorkerRegistration,
  });

  if (currentToken != null) {
    await storePushToken(userId, currentToken);
  }

  return currentToken;
}

export function onFirebaseMessage(callback) {
  const messaging = getFirebaseMessaging();

  onMessage(messaging, callback);
}

async function registerMessagingServiceWorker() {
  const firebaseConfig = encodeURIComponent(JSON.stringify(FIREBASE_CONFIG));

  try {
    const registration = await navigator.serviceWorker.register(`/firebase-messaging-sw.js?firebaseConfig=${firebaseConfig}`);
    return registration;
  } catch (error) {
    console.log('Messaging error');
  }

  return undefined;
}
