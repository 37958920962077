import { Routes } from '@packages/constants';
import { ProductUtil, Session, TimelineEntryTypeUtil } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import CopyToClipboardForm from '../../Shared/CopyToClipboardForm';

function InfoWindowContent({ session }: { session: Session }) {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <h3 className="text-center text-[22px] font-[500]" style={{ color: ProductUtil.colors(session.product).primary ?? 'black' }}>
          {ProductUtil.toHeading(session.product)}
        </h3>
      </div>
      {session.lastTimelineEvent != null && (
        <div className="text-[18px] text-center my-2">
          <span>{TimelineEntryTypeUtil.toUIString(session.lastTimelineEvent, session.product)}</span>
        </div>
      )}
      <div className="text-[16px] text-center my-4">
        <a href={Routes.web.SESSION_DETAIL.replace(':id', session.id)} className="text-dark">
          <u>{t('view_details')}</u>
        </a>
      </div>
      <div>
        <CopyToClipboardForm text={session.joinKey.toString()} />
      </div>
    </>
  );
}

export default InfoWindowContent;
