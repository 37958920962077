import { WiBarometer } from 'react-icons/wi';

function ChartPlaceholder() {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full py-10 text-center">
      <div className="p-6 mb-6 rounded-full bg-grey-100 bg-product-light">
        <WiBarometer className="h-9 w-9 text-product" />
      </div>
      <div className="text-[18px] font-[600] mb-3">Waiting for pressure readings</div>
      <div className="text-muted text-[14px]">Once there are sufficient readings the pressure timeline chart will be displayed here.</div>
    </div>
  );
}

export default ChartPlaceholder;
