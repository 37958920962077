import { Routes } from '@packages/constants';
import { useTranslation } from '@packages/translations';
import { SessionTrackingMap } from '@packages/ui';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { MdOutlineAddCircle } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import AppContent from '../components/Layouts/AppLayout/Content';
import ProductDetails from '../components/ProductDetails';
import InfoWindowContent from '../components/Session/SessionTrackingMap/InfoWindowContent';
import SessionTable from '../components/Session/Table/index';
import Button from '../components/Shared/Button';
import Card from '../components/Shared/Card';
import EmptyPlaceholder from '../components/Shared/EmptyPlaceholder';
import { useAuthUser } from '../contexts/useAuthUser';
import { useFetchSessions } from '../lib/firebase/firestore';

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organization } = useAuthUser();

  const [sessions, areSessionsLoading] = useFetchSessions({ organizationId: organization?.id });

  const latestSessions = useMemo(() => {
    if (areSessionsLoading || sessions == null) {
      return [];
    }

    return sessions.slice(0, 10);
  }, [areSessionsLoading, sessions]);

  const mapSessions = useMemo(() => {
    if (areSessionsLoading || sessions == null) {
      return [];
    }

    return sessions.filter(session => !session.finished && session.location != null).map(session => ({ session, locations: undefined }));
  }, [areSessionsLoading, sessions]);

  if (areSessionsLoading) return null;

  if (sessions?.length === 0) {
    return (
      <AppContent>
        <Helmet>
          <title>{t('meta_title_dashboard')} | Paragonix</title>
        </Helmet>
        <EmptyPlaceholder>
          <h3 className="text-[20px] font-medium">{t('dashboard_empty_state_title')}</h3>
          <p className="text-muted mt-2 mb-10">{t('dashboard_empty_state_subtitle')}</p>
          <Link to={Routes.web.JOIN_NO_KEY}>
            <Button Icon={MdOutlineAddCircle}>{t('join_session')}</Button>
          </Link>
        </EmptyPlaceholder>
      </AppContent>
    );
  }

  return (
    <AppContent>
      <Helmet>
        <title>{t('meta_title_dashboard')} | Paragonix</title>
      </Helmet>

      <div className="space-y-8">
        <Card title={t('latest_sessions')}>
          <SessionTable
            columns={['joinKey', 'createdAt', 'sessionState', 'organ', 'probeAvg', 'unosId', 'participation', 'details']}
            sessions={latestSessions}
            options={{ hasPagination: false, hasRowSelect: false, initialSortBy: [{ id: 'createdAt', desc: true }] }}
          />

          <Button
            variant="outline"
            onClick={() => {
              navigate(Routes.web.SESSIONS);
            }}
            className="mt-5 block w-full md:inline md:w-auto"
          >
            {t('view_all')}{' '}
          </Button>
        </Card>

        <Card title={t('live_session_tracking')}>
          <SessionTrackingMap
            sessions={mapSessions}
            options={{ showInfoWindows: true, height: '700px', showPolylines: false }}
            InfoWindowContent={InfoWindowContent}
          />
        </Card>

        <Card title={t('product_metrics_title')} noPadding>
          <ProductDetails />
        </Card>
      </div>
    </AppContent>
  );
};

export default Dashboard;
