import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy, MdCheck } from 'react-icons/md';

function CopyToClipboardForm({ text }) {
  // const copyClasses = 'bg-dark text-light rounded-end h-100';
  // const copiedClasses = 'bg-success text-light rounded-end h-100';

  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    if (!copied) {
      return;
    }

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, [copied]);

  return (
    <div>
      <div className="mt-1 flex rounded-md max-w-[200px]">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <input type="text" value={text} className="block w-full rounded-none rounded-l-md border-gray-300" disabled />
        </div>
        <div>
          <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
            <button
              type="button"
              className={classNames(
                { 'bg-dark text-white border-dark': !copied, 'bg-[#34c38f] border-[#34c38f] text-white': copied },
                'h-full relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 px-4 py-2 font-medium',
              )}
            >
              {copied ? <MdCheck className="w-4 h-4" /> : <MdContentCopy className="w-4 h-4" />}
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}

export default CopyToClipboardForm;
