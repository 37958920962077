import { Switch as TwSwitch } from '@headlessui/react';
import classNames from 'classnames';

export default function Switch({ checked, onChange, children }) {
  return (
    <TwSwitch.Group as="div" className="flex items-center">
      <TwSwitch
        checked={checked}
        onChange={onChange}
        className={classNames(
          checked ? 'bg-black' : 'bg-white',
          'relative items-center inline-flex h-7 w-14 flex-shrink-0 cursor-pointer rounded-full border border-grey-700 transition-colors duration-200 ease-in-out focus:outline-none',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            checked ? 'translate-x-[1.85rem] bg-white' : 'translate-x-1 bg-gray-400',
            'x-1 pointer-events-none inline-block h-5 w-5 transform rounded-full shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </TwSwitch>
      <TwSwitch.Label as="span" className="ml-3">
        {children}
      </TwSwitch.Label>
    </TwSwitch.Group>
  );
}
