import { Assets } from '@packages/assets';
import { Routes } from '@packages/constants';
import { useTranslation } from '@packages/translations';
import { FirebaseError } from 'firebase/app';
import { AuthError } from 'firebase/auth';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import InputField from '../components/Forms/InputField';
import AuthLayout from '../components/Layouts/AuthLayout';

import Button from '../components/Shared/Button';
import { login } from '../lib/firebase/auth';
import { FIREBASE_AUTH_ERRORS } from '../lib/firebase/authErrors';

export type LoginFormValues = {
  email: string;
  password: string;
  isRememberMeSet?: boolean;
};

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [error, setError] = useState<AuthError>();

  const initialValues = {
    email: '',
    password: '',
    isRememberMeSet: false,
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email('Email is required').required('Email is required'),
    password: yup.string().required('Password is required'),
    isRememberMeSet: yup.boolean().default(false),
  });

  async function onSubmit({ email, password, isRememberMeSet }: LoginFormValues) {
    try {
      await login(email, password, isRememberMeSet);

      const origin = new URLSearchParams(location.search).get('origin');

      if (origin != null) {
        navigate(origin);
        return;
      }

      navigate(Routes.web.DASHBOARD);
    } catch (err: any) {
      const firebaseError = err as FirebaseError;
      setError(FIREBASE_AUTH_ERRORS[firebaseError.code] || err.message);
    }
  }

  return (
    <AuthLayout title={t('meta_title_login')}>
      <div className="mt-6 mb-8 md:mb-16">
        <Link to={Routes.web.LOGIN}>
          <img src={Assets.logo.paragonixLogoLightPng} alt="" style={{ height: '18px' }} />
        </Link>
      </div>
      <div>
        <h5 className="mb-2 text-xl font-medium text-dark">{t('login_title')}</h5>
        <p className="text-muted">{t('login_subtitle')}</p>
      </div>

      <div className="mt-8">
        <div className="mt-6">
          {error && <div className="mb-6 border rounded px-6 py-3 text-[#924040] border-[#fcd2d2] bg-[#fde1e1]">{error}</div>}
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={values => onSubmit(values)}>
            {({ errors, touched }) => (
              <Form className="space-y-6">
                <div>
                  <InputField
                    type="text"
                    label={t('email')}
                    placeholder={t('email_placeholder')}
                    name="email"
                    error={errors.email}
                    touched={touched.email}
                  />
                </div>

                <div className="space-y-1">
                  <InputField
                    type="password"
                    name="password"
                    label={t('password')}
                    placeholder={t('password_placeholder')}
                    error={errors.password}
                    touched={touched.password}
                    cornerElement={
                      <Link to={Routes.web.FORGOT_PASSWORD} className="text-muted">
                        {t('forgot_password')}
                      </Link>
                    }
                  />
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <Field
                      id="isRememberMeSet"
                      name="isRememberMeSet"
                      type="checkbox"
                      className="w-4 h-4 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <label htmlFor="isRememberMeSet" className="block ml-2 font-medium text-dark">
                      {t('remember_me')}
                    </label>
                  </div>
                </div>

                <div>
                  <Button type="submit" block>
                    {t('login')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <div className="text-center my-14">
        <p>
          {t('register_text').replace('%s', '')}{' '}
          <Link to={Routes.web.REGISTER} className="font-medium text-dark">
            {t('register')}
          </Link>
        </p>
      </div>

      <p className="mt-4 text-center text-muted">© {new Date().getFullYear()} Paragonix Technologies Inc.</p>
    </AuthLayout>
  );
};

export default Login;
