import { Routes } from '@packages/constants';
import { OrganizationMemberStatus, OrganizationStatus } from '@packages/firebase';
import { User as AuthUser, onAuthStateChanged } from 'firebase/auth';
import { FC, VFC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CURRENT_TERMS_VERSION } from '../../constants';
import { AuthUserContext, AuthUserContextProps } from '../../contexts/useAuthUser';
import { getFirebaseAuth } from '../../lib/firebase/auth';
import { useFetchOrganization, useFetchUser } from '../../lib/firebase/firestore';
import { appStoreRedirect } from '../../utils/appStoreRedirect';

const withUser = (Component: FC) => {
  const WithUser: VFC<Record<string, unknown>> = (props = {}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = getFirebaseAuth();

    const { user, isUserLoading } = useFetchUser();
    const organizationData = useFetchOrganization(user?.organizationIds?.[0] as string);

    const [authUser, setAuthUser] = useState<AuthUser | undefined>();

    const value = useMemo(
      () => ({
        authUser,
        user,
        organization: organizationData?.organization ?? undefined,
        member: organizationData?.members?.find(member => member.id === user?.id),
        isLoading: isUserLoading || organizationData?.isLoading,
      }),
      [authUser, isUserLoading, organizationData, user],
    );

    useEffect(() => {
      if (user == null || isUserLoading || organizationData?.isLoading) {
        return;
      }

      const { organization, members } = organizationData;

      const hasAcceptedTerms = user.acceptedTermsVersion != null && user.acceptedTermsVersion >= CURRENT_TERMS_VERSION;

      const isEmailVerified = user.emailVerified;

      const isInvitedMember =
        organization != null &&
        organization.status === OrganizationStatus.ACTIVE &&
        members?.some(member => member.id === user.id && member.status === OrganizationMemberStatus.INVITED);

      const isOrganizationMember = user.organizationIds != null && user.organizationIds.length > 0;

      if (!hasAcceptedTerms) {
        if (location.pathname !== Routes.web.ACCEPT_TERMS) {
          navigate(Routes.web.ACCEPT_TERMS);
          return;
        }

        return;
      }

      if (!isEmailVerified && user.needsEmailVerification) {
        if (location.pathname !== Routes.web.VERIFY_EMAIL_NOTICE) {
          navigate(Routes.web.VERIFY_EMAIL_NOTICE);
          return;
        }

        return;
      }

      if (isInvitedMember) {
        if (location.pathname !== '/organization/join-notice' && location.pathname !== `/organization/${organization.id}/join`) {
          navigate(`/organization/join-notice?id=${organization.id}&name=${organization.name}`);
          return;
        }

        return;
      }

      if (!isOrganizationMember && user.needsOrganizationProfile) {
        if (location.pathname !== '/select-organization') {
          navigate('/select-organization');
        }
      }
    }, [user, isUserLoading, organizationData]);

    useEffect(() => {
      onAuthStateChanged(auth, authStateChangeUser => {
        if (authStateChangeUser == null) {
          if (location.pathname.startsWith('/organization/') && location.pathname.endsWith('/register')) {
            appStoreRedirect();

            navigate(`${Routes.web.REGISTER}${location.search ?? ''}`);
            return;
          }

          if (location.pathname.startsWith('/organization/') && location.pathname.endsWith('/join')) {
            appStoreRedirect();

            navigate(`${Routes.web.REGISTER}${location.search ?? ''}`);
            return;
          }

          navigate(`${Routes.web.LOGIN}?origin=${encodeURIComponent(`${location.pathname}${location.search ?? ''}`)}`);
          return;
        }

        setAuthUser(authStateChangeUser);
      });
    }, []);

    return (
      <AuthUserContext.Provider value={value as AuthUserContextProps}>
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  };

  return WithUser;
};

export default withUser;
