// import { Assets } from '@packages/ui';
import { Tab } from '@headlessui/react';
import { useTranslation } from '@packages/translations';
import classNames from 'classnames';
import { Fragment, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { MdChatBubbleOutline, MdGridView, MdOutlineHelpOutline, MdOutlineHistory, MdOutlineInfo } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import AppContent from '../components/Layouts/AppLayout/Content';
import ChatTab from '../components/Session/ChatTab';
import InfoTab from '../components/Session/InfoTab';
import SessionTab from '../components/Session/SessionTab';
import SupportTab from '../components/Session/SupportTab';
import TimelineTab from '../components/Session/TimelineTab';
import { useSession } from '../contexts/useSession';
// import SupportTabs from '../components/Support/SupportTabs';
import { useAuthUser } from '../contexts/useAuthUser';
import { useChat } from '../contexts/useChat';
import { useFetchSession, useFetchSessionDetails } from '../lib/firebase/firestore';

const SessionsDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { authUser } = useAuthUser();
  const { setCurrentSession, isLive, cssClass } = useSession();
  const { unreadChannelsCount } = useChat();

  const { session } = useFetchSession(id!);
  const { timeline, readings, locations, members } = useFetchSessionDetails(id!);

  useEffect(() => {
    setCurrentSession(session);

    return () => {
      setCurrentSession(undefined);
    };
  }, [session]);

  const enableChat = useMemo(() => {
    return session?.userIds?.includes(authUser.uid) && !session?.finished;
  }, [session, authUser.uid]);

  return (
    <AppContent noPadding>
      <Helmet>
        <title>{t('meta_title_session_detail').replace('%s', session?.joinKey != null ? `(${session.joinKey})` : '')} | Paragonix</title>
      </Helmet>
      <div className={`${cssClass}-theme`}>
        {session && (
          <Tab.Group>
            <Tab.List className="smd:border-none sticky top-[70px] z-[11] w-full border-b bg-white">
              <div className="md:bg-product flex flex-row flex-nowrap items-center justify-start overflow-x-auto bg-white py-5 text-white md:justify-center md:py-0">
                <SessionTabsPill>
                  <MdGridView className="mr-2 h-7 w-7 flex-shrink-0" />
                  {t('session')}
                  {isLive && <div className="text-product ml-2 rounded bg-white px-1">Live</div>}
                </SessionTabsPill>

                <SessionTabsPill>
                  <MdOutlineHistory className="mr-2 h-7 w-7 flex-shrink-0" />
                  {t('session_timeline_title')}
                </SessionTabsPill>

                <SessionTabsPill>
                  <MdOutlineInfo className="mr-2 h-7 w-7 flex-shrink-0" />
                  {t('session_info')}
                </SessionTabsPill>

                {enableChat && (
                  <SessionTabsPill>
                    <MdChatBubbleOutline className="mr-2 h-7 w-7 flex-shrink-0" />
                    {t('chat')}
                    {!!unreadChannelsCount[session.id] && unreadChannelsCount[session.id] > 0 && (
                      <div className="text-product ml-2 rounded bg-white px-1.5 text-sm">{unreadChannelsCount[session.id]}</div>
                    )}
                  </SessionTabsPill>
                )}

                <SessionTabsPill>
                  <MdOutlineHelpOutline className="mr-2 h-7 w-7 flex-shrink-0" />
                  {t('support')}
                </SessionTabsPill>
              </div>
            </Tab.List>

            <Tab.Panels>
              <div className="p-10">
                <Tab.Panel>
                  {session && readings && locations && timeline && (
                    <SessionTab session={session} readings={readings} locations={locations} members={members} timeline={timeline} />
                  )}
                </Tab.Panel>

                <Tab.Panel>{session && timeline && <TimelineTab session={session} timeline={timeline} />}</Tab.Panel>

                <Tab.Panel>{session && members && <InfoTab session={session} members={members} />}</Tab.Panel>

                {enableChat && <Tab.Panel>{session && <ChatTab session={session} />}</Tab.Panel>}

                <Tab.Panel>{session && <SupportTab session={session} />}</Tab.Panel>
              </div>
            </Tab.Panels>
          </Tab.Group>
        )}
      </div>
    </AppContent>
  );
};

function SessionTabsPill({ children }) {
  return (
    <Tab as={Fragment}>
      {({ selected }) => (
        <button
          type="button"
          className={classNames(
            { 'bg-product border-white': selected },
            { 'bg-product-light md:bg-product border-transparent': !selected },
            'mx-3 flex items-center rounded p-2 text-white outline-none md:rounded-none md:border-b-4 md:py-7 md:px-6 md:text-xl',
          )}
        >
          {children}
        </button>
      )}
    </Tab>
  );
}

// const syncTimeout = (ms: number) =>
//   new Promise(resolve => {
//     setTimeout(resolve, ms);
//   });

export default SessionsDetail;
