import { useEffect, useRef, VFC } from 'react';
import { TableToggleAllRowsSelectedProps, TableToggleRowsSelectedProps } from 'react-table';

export type RowSelectCheckboxProps = TableToggleRowsSelectedProps | TableToggleAllRowsSelectedProps;

const RowSelectCheckbox: VFC<RowSelectCheckboxProps> = ({ indeterminate, ...rest }) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (indeterminate != null && ref.current != null) {
      ref.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <input
      ref={ref}
      type="checkbox"
      className="w-4 h-4 border border-gray-300 rounded-sm bg-gray-50 focus:ring-3 focus:ring-blue-300"
      {...rest}
    />
  );
};

export default RowSelectCheckbox;
