import { Column, Hooks, useFilters, usePagination, useRowSelect, useSortBy } from 'react-table';
import RowSelectCheckbox from './RowSelectCheckbox';

export type ConfigureTablePluginOptions = {
  hasFilters?: boolean;
  hasPagination?: boolean;
  hasRowSelect?: boolean;
  initialSortBy?: Array<{ id: string; desc: boolean }>;
};

export default function configureTable<D extends {}>(columns: Column<D>[], data: D[], options: ConfigureTablePluginOptions = {}) {
  return {
    tableOptions: {
      columns,
      data,
      initialState: {
        sortBy: options.initialSortBy ?? [],
        pageSize: 10,
      },
    },
    tablePlugins: [
      useFilters,
      useSortBy,
      ...(options.hasPagination ? configurePagination() : []),
      ...(options.hasRowSelect ? configureRowSelect() : []),
    ],
  };
}

// function configureFilters() {
//   return [useFilters];
// }

function configurePagination() {
  return [usePagination];
}

function configureRowSelect() {
  return [
    useRowSelect,
    (hooks: Hooks) => {
      hooks.visibleColumns.push(cols => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => {
            return <RowSelectCheckbox {...getToggleAllRowsSelectedProps()} />;
          },
          Cell: ({ row }) => <RowSelectCheckbox {...row.getToggleRowSelectedProps()} />,
        },
        ...cols,
      ]);
    },
  ];
}
