import {
  AuditLog,
  AuditLogType,
  DistanceUnit,
  Document,
  Log,
  LoggerLocation,
  OrganType,
  Organization,
  OrganizationMember,
  OrganizationMemberAccessRole,
  OrganizationMemberStatus,
  OrganizationPreselectedMember,
  OrganizationStatus,
  OrganizationUnregisteredInvite,
  Session,
  SessionMember,
  TimelineEntry,
  Tutorial,
  User,
  auditLogConverter,
  destinationConverter,
  documentConverter,
  locationConverter,
  logConverter,
  organizationConverter,
  organizationMemberConverter,
  organizationMemberRoleConverter,
  organizationOptionConverter,
  organizationPreselectedMemberConverter,
  organizationTypeConverter,
  organizationUnregisteredInviteConverter,
  sessionConverter,
  sessionMemberConverter,
  timelineConverter,
  tutorialConverter,
  userConverter,
} from '@packages/firebase';
import {
  QueryConstraint,
  Timestamp,
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  deleteField,
  doc,
  documentId,
  getFirestore,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useCollectionData, useCollectionDataOnce, useDocumentData } from 'react-firebase-hooks/firestore';
import { CURRENT_TERMS_VERSION } from '../../constants';
import { getFirebaseAuth } from './auth';
import app from './config';

export type CreateUserParams = {
  firstname: string;
  lastname: string;
  settings: {
    hasTimezone: boolean;
    has24Hours: boolean;
    distanceUnit: DistanceUnit;
  };
};

export function getFirebaseStore() {
  return getFirestore(app);
}

export function useFetchUser() {
  const auth = getFirebaseAuth();
  const firestore = getFirebaseStore();

  const [user, isUserLoading] = useDocumentData(
    auth.currentUser?.uid != null ? doc(firestore, 'users', auth.currentUser.uid).withConverter<User>(userConverter) : null,
  );

  return {
    user,
    isUserLoading,
  };
}

export function acceptTerms(userId: string) {
  if (!userId) {
    return null;
  }

  const firestore = getFirebaseStore();

  return updateDoc(doc(firestore, 'users', userId), { acceptedTermsVersion: CURRENT_TERMS_VERSION });
}

export function getSessions({ uid, organizationId, finished }: { uid?: string; organizationId?: string; finished?: boolean }) {
  const firestore = getFirebaseStore();

  const conditions: QueryConstraint[] = [];

  if (uid != null) {
    conditions.push(where('userIds', 'array-contains', uid));
  }

  if (organizationId != null) {
    conditions.push(where('organizationIds', 'array-contains', organizationId));
  }

  if (finished != null) {
    conditions.push(where('finished', '==', finished));
  }

  return query(collection(firestore, 'sessions'), ...conditions).withConverter<Session>(sessionConverter);
}

export function useFetchSessions({
  organizationId,
  finished,
  limit,
}: {
  organizationId?: string;
  finished?: boolean;
  limit?: number;
}): [Session[], boolean] {
  const auth = getFirebaseAuth();

  const [userSessions, isLoadingUserSessions] = useCollectionData(
    auth.currentUser?.uid != null ? getSessions({ uid: auth.currentUser.uid, finished }) : null,
  );

  const [organizationSessions, isLoadingOrganizationSessions] = useCollectionData(
    organizationId != null ? getSessions({ organizationId, finished }) : null,
  );

  const sessions = useMemo(() => {
    if (isLoadingUserSessions || isLoadingOrganizationSessions) {
      return [];
    }

    const userSessionsIds = userSessions?.map(session => session.id) ?? [];

    return [...(userSessions ?? []), ...(organizationSessions ?? []).filter(session => !userSessionsIds.includes(session.id))]
      .filter(session => session != null && session.createdAt != null)
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
      .slice(0, limit);
  }, [isLoadingUserSessions, isLoadingOrganizationSessions, limit, organizationSessions, userSessions]);

  return [sessions, isLoadingUserSessions || isLoadingOrganizationSessions];
}

export function useFetchSession(sessionId: string) {
  const firestore = getFirebaseStore();

  const [session, isSessionLoading] = useDocumentData(doc(firestore, 'sessions', sessionId).withConverter<Session>(sessionConverter));

  return {
    session,
    isSessionLoading,
  };
}

export function useFetchSessionDetails(sessionId: string) {
  const [timeline, isTimelineLoading] = useCollectionData(getSessionTimeline(sessionId));
  const [members, areMembersLoading] = useCollectionData(getSessionMembers(sessionId));
  const [auditLogs, areAuditLogsLoading] = useCollectionData(getSessionAuditLogs(sessionId));
  const [locations, areLocationsLoading] = useCollectionData(getLocations(sessionId));
  const [log, isLogLoading] = useDocumentData(getSessionReadings(sessionId));

  const readings = useMemo(() => {
    if (log?.log == null) {
      return [];
    }

    return log.log.map(elem => elem).sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
  }, [log]);

  const sortedAuditLogs = useMemo(() => {
    if (auditLogs == null) {
      return [];
    }

    return auditLogs.sort((a, b) => a.timestampUser.getTime() - b.timestampUser.getTime());
  }, [auditLogs]);

  return {
    timeline,
    members,
    auditLogs: sortedAuditLogs,
    readings,
    locations,
    isLoading: isTimelineLoading || areMembersLoading || isLogLoading || areAuditLogsLoading || areLocationsLoading,
  };
}

export function getSessionTimeline(sessionId: string) {
  const firestore = getFirebaseStore();

  return query(collection(firestore, 'sessions', sessionId, 'timeline'), orderBy('createdAt', 'asc')).withConverter<TimelineEntry>(
    timelineConverter,
  );
}

export function getSessionMembers(sessionId: string) {
  const firestore = getFirebaseStore();

  return query(collection(firestore, 'sessions', sessionId, 'users')).withConverter<SessionMember>(sessionMemberConverter);
}

export function getSessionReadings(sessionId: string) {
  const firestore = getFirebaseStore();

  return doc(firestore, 'sessions', sessionId, 'log', 'log').withConverter<Log>(logConverter);
}

export function getSessionAuditLogs(sessionId: string) {
  const firestore = getFirebaseStore();

  return query(collection(firestore, 'sessions', sessionId, 'auditLogs')).withConverter<AuditLog>(auditLogConverter);
}

export function getLocations(sessionId: string) {
  const firestore = getFirebaseStore();

  return query(collection(firestore, 'sessions', sessionId, 'locations'), orderBy('timestamp', 'asc')).withConverter<LoggerLocation>(
    locationConverter,
  );
}

export function useFetchOrganizations() {
  const firestore = getFirebaseStore();

  const [organizations, areOrganizationsLoading] = useCollectionData(
    collection(firestore, 'organizations').withConverter<Organization>(organizationConverter),
  );

  return {
    organizations,
    areOrganizationsLoading,
  };
}

export async function deleteOrganization(organizationId: string) {
  const firestore = getFirebaseStore();

  return deleteDoc(doc(firestore, 'organizations', organizationId));
}

export function getProductDocuments(productId?: string) {
  if (productId == null) {
    return [];
  }

  const firestore = getFirebaseStore();
  const productDocumentsQuery = query(collection(firestore, 'products', productId, 'documents')).withConverter<Document>(documentConverter);

  return useCollectionDataOnce<Document>(productDocumentsQuery);
}

export function getProductTutorials(productId?: string) {
  if (productId == null) {
    return [];
  }

  const firestore = getFirebaseStore();
  const productTutorialsQuery = query(collection(firestore, 'products', productId, 'tutorials')).withConverter<Tutorial>(tutorialConverter);

  return useCollectionDataOnce<Tutorial>(productTutorialsQuery);
}

export function getAppTrainingTutorials() {
  const firestore = getFirebaseStore();
  const appTrainingTutorialsQuery = query(collection(firestore, 'resources', 'app-training', 'tutorials')).withConverter<Tutorial>(
    tutorialConverter,
  );

  return useCollectionDataOnce<Tutorial>(appTrainingTutorialsQuery);
}

export function updateUserFirstname(firstname: string) {
  const auth = getFirebaseAuth();

  if (!auth?.currentUser?.uid) {
    return null;
  }

  const firestore = getFirebaseStore();

  return updateDoc(doc(firestore, 'users', auth.currentUser.uid), { firstname });
}

export function updateUserLastname(lastname: string) {
  const auth = getFirebaseAuth();

  if (!auth?.currentUser?.uid) {
    return null;
  }

  const firestore = getFirebaseStore();

  return updateDoc(doc(firestore, 'users', auth.currentUser.uid), { lastname });
}

export function updateUserSettings(settings: {
  includeTimezone?: boolean;
  force24HoursFormat?: boolean;
  allowMarketingMails?: boolean;
  allowSessionReportMails?: boolean;
  forceDistanceUnit?: DistanceUnit;
}) {
  const auth = getFirebaseAuth();

  if (!auth?.currentUser?.uid) {
    return null;
  }

  const firestore = getFirebaseStore();

  const firestoreKeys = {
    allowMarketingMails: 'marketingMails',
    allowSessionReportMails: 'sessionReportMails',
    force24HoursFormat: 'force24hours',
  };

  return updateDoc(doc(firestore, 'users', auth.currentUser.uid), {
    ...Object.assign({}, ...Object.keys(settings).map(key => ({ [`settings.${firestoreKeys[key] ?? key}`]: settings[key] }))),
  });
}

export async function createAuditLog(userId: string, sessionId: string, event: AuditLogType) {
  if (!userId) {
    return null;
  }

  const firestore = getFirebaseStore();

  return addDoc(collection(firestore, 'sessions', sessionId, 'auditLogs'), {
    userId,
    event,
    timestampUser: new Date(),
    timestampServer: serverTimestamp(),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    appVersion: process.env.REACT_APP_VERSION,
    osVersion: navigator.userAgent,
    os: 'Web',
    device: navigator.userAgent,
  });
}

export async function storePushToken(userId: string, token: string) {
  if (!userId) {
    return null;
  }

  const firestore = getFirebaseStore();

  return setDoc(doc(firestore, 'users', userId, 'pushTokens', token), {
    environment: process.env.REACT_APP_ENVIRONMENT,
    platform: 'web',
  });
}

export async function muteSession(sessionId: string) {
  const auth = getFirebaseAuth();
  const firestore = getFirebaseStore();

  if (!auth.currentUser) {
    return null;
  }

  return setDoc(doc(firestore, 'sessions', sessionId, 'users', auth.currentUser.uid), { muted: true }, { merge: true });
}

export async function unmuteSession(sessionId: string) {
  const auth = getFirebaseAuth();
  const firestore = getFirebaseStore();

  if (!auth.currentUser) {
    return null;
  }

  return setDoc(doc(firestore, 'sessions', sessionId, 'users', auth.currentUser.uid), { muted: false }, { merge: true });
}

export function useFetchCurrentAdminOrganizations() {
  const auth = getFirebaseAuth();
  const firestore = getFirebaseStore();

  return useCollectionData(
    auth.currentUser &&
      query(collection(firestore, 'organizations'), where('adminIds', 'array-contains', auth.currentUser.uid)).withConverter<Organization>(
        organizationConverter,
      ),
  );
}

export function useFetchOrganization(id?: string) {
  const auth = getFirebaseAuth();
  const firestore = getFirebaseStore();

  const [organization, isOrganizationLoading] = useDocumentData(
    id != null ? doc(firestore, 'organizations', id).withConverter<Organization>(organizationConverter) : null,
  );

  const [members, areMembersLoading] = useCollectionData(
    id != null
      ? query(collection(firestore, 'organizations', id, 'users')).withConverter<OrganizationMember>(organizationMemberConverter)
      : null,
  );

  const [unregisteredInvites, areUnregisteredInvitesLoading] = useCollectionData(
    id != null
      ? query(
          collection(firestore, 'organizations_unregistered_invites'),
          where('organizationId', '==', id),
        ).withConverter<OrganizationUnregisteredInvite>(organizationUnregisteredInviteConverter)
      : null,
  );

  const isAdmin = useMemo(() => {
    if (id == null) {
      return false;
    }

    const uid = auth.currentUser?.uid;

    if (uid == null || organization == null) {
      return false;
    }

    return organization.adminIds?.includes(uid);
  }, [auth.currentUser?.uid, organization]);

  const isActive = useMemo(() => {
    if (id == null) {
      return false;
    }

    const uid = auth.currentUser?.uid;

    if (uid == null || organization == null) {
      return false;
    }

    return members?.some(member => member.id === uid && member.status === OrganizationMemberStatus.ACTIVE);
  }, [auth.currentUser?.uid, organization, members]);

  return {
    organization: organization ?? null,
    members: members ?? [],
    unregisteredInvites: unregisteredInvites ?? [],
    isAdmin,
    isActive,
    isLoading: isOrganizationLoading || areMembersLoading || areUnregisteredInvitesLoading,
  };
}

export function useFetchOrganizationsToBeValidated() {
  const auth = getFirebaseAuth();
  const firestore = getFirebaseStore();

  return useCollectionData(
    auth.currentUser &&
      query(
        collection(firestore, 'organizations'),
        where('adminIds', 'array-contains', auth.currentUser.uid),
        where('status', '==', OrganizationStatus.IN_VALIDATION),
      ).withConverter<Organization>(organizationConverter),
  );
}

export function useFetchOrganizationForValidation(id: string) {
  const auth = getFirebaseAuth();
  const firestore = getFirebaseStore();

  const [organizations, areOrganizationsLoading] = useCollectionData(
    auth.currentUser &&
      query(
        collection(firestore, 'organizations'),
        where(documentId(), '==', id),
        where('adminIds', 'array-contains', auth.currentUser.uid),
      ).withConverter<Organization>(organizationConverter),
  );

  const [members, areMembersLoading] = useCollectionData(
    organizations?.[0] &&
      query(collection(firestore, 'organizations', organizations?.[0].id, 'users')).withConverter<OrganizationMember>(
        organizationPreselectedMemberConverter,
      ),
  );

  const [preselectedMembers, arePreselectedMembersLoading] = useCollectionData(
    organizations?.[0] &&
      query(collection(firestore, 'organizations', organizations?.[0].id, 'preselectedUsers')).withConverter<OrganizationPreselectedMember>(
        organizationPreselectedMemberConverter,
      ),
  );

  return {
    organization: organizations?.[0],
    members,
    preselectedMembers,
    isLoading: areOrganizationsLoading || areMembersLoading || arePreselectedMembersLoading,
  };
}

export function useFetchOrganizationUserRolesConfig() {
  const firestore = getFirebaseStore();

  const [userRoles, areUserRolesLoading] = useCollectionData(
    collection(firestore, 'config', 'organizations', 'user_roles').withConverter(organizationMemberRoleConverter),
  );

  return {
    userRoles,
    isLoading: areUserRolesLoading,
  };
}

export function useFetchOrganizationTypesConfig() {
  const firestore = getFirebaseStore();

  const [organizationTypes, isLoading] = useCollectionData(
    query(collection(firestore, 'config', 'organizations', 'types'), orderBy('sort', 'asc')).withConverter(organizationTypeConverter),
  );

  return {
    organizationTypes,
    isLoading,
  };
}

export function useFetchOrganizationOptionsConfig() {
  const firestore = getFirebaseStore();

  const [organizationOptions, isLoading] = useCollectionData(
    query(collection(firestore, 'config', 'organizations', 'organization_options')).withConverter(organizationOptionConverter),
  );

  return {
    organizationOptions,
    isLoading,
  };
}

export function useFetchDestinationsTmp(organ: OrganType) {
  const firestore = getFirebaseStore();

  const [destinations, isLoading] = useCollectionData(
    query(collection(firestore, 'destinationsTmp'), where('organs', 'array-contains', organ)).withConverter(destinationConverter),
  );

  return {
    destinations,
    isLoading,
  };
}

export function useFetchDestination(handle?: string) {
  const firestore = getFirebaseStore();

  const [destination, isLoading] = useDocumentData(handle != null ? doc(firestore, 'destinationsTmp', handle) : null);

  return {
    destination,
    isLoading,
  };
}

export async function setDestinationForSession(sessionId: string, handle: string) {
  const firestore = getFirebaseStore();
  const auth = getFirebaseAuth();

  if (!auth?.currentUser?.uid) {
    return;
  }

  await updateDoc(doc(firestore, 'sessions', sessionId), { destination: handle });
  await updateDoc(doc(firestore, 'users', auth.currentUser.uid), { recentDestinations: arrayUnion(handle) });
}

export async function removeDestinationForSession(sessionId: string) {
  const firestore = getFirebaseStore();

  return updateDoc(doc(firestore, 'sessions', sessionId), { destination: deleteField() });
}

export async function changeOrganizationUserRole(organizationId: string, userId: string, role: string) {
  const firestore = getFirebaseStore();

  return updateDoc(doc(firestore, 'organizations', organizationId, 'users', userId), {
    role,
    updatedAt: Timestamp.now(),
  });
}

export async function changeOrganizationUserTitle(organizationId: string, userId: string, title: string) {
  const firestore = getFirebaseStore();

  return updateDoc(doc(firestore, 'organizations', organizationId, 'users', userId), {
    title,
    updatedAt: Timestamp.now(),
  });
}

export async function changeOrganizationUserAccessRole(organizationId: string, userId: string, accessRole: OrganizationMemberAccessRole) {
  const firestore = getFirebaseStore();

  await updateDoc(doc(firestore, 'organizations', organizationId), {
    adminIds: accessRole === OrganizationMemberAccessRole.ADMIN ? arrayUnion(userId) : arrayRemove(userId),
    updatedAt: Timestamp.now(),
  });

  return updateDoc(doc(firestore, 'organizations', organizationId, 'users', userId), {
    accessRole,
    updatedAt: Timestamp.now(),
  });
}

export async function deleteOrganizationUnregisteredInvite(id: string) {
  const firestore = getFirebaseStore();

  return deleteDoc(doc(firestore, 'organizations_unregistered_invites', id));
}
