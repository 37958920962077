import { Routes } from '@packages/constants';
import { Session } from '@packages/firebase';
import { MdOutlineChevronRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import OrganTypeBadge from '../../Badges/OrganTypeBadge';
import SessionStateBadge from '../../Badges/SessionStateBadge';
import datetimeFormatter from './datetime.formatter';
import donationTypeFormatter from './donationType.formatter';
import sessionTypeFormatter from './sessionType.formatter';

export default function joinKeyFormatter({ value }: { value: Session }) {
  return (
    <>
      <strong className="hidden md:inline">{value.joinKey}</strong>

      <Link
        to={Routes.web.SESSION_DETAIL.replace(':id', value.id)}
        className="flex flex-row items-center justify-between w-full cursor-pointer md:hidden pointer"
      >
        <div>
          <strong className="font-[500] text-xl">{value.joinKey}</strong>
          <div className="mt-1 mb-3">{datetimeFormatter({ value: value.createdAt })}</div>
          <div className="flex flex-row gap-2">
            <div>
              <SessionStateBadge sessionState={value.getState()} />
            </div>
            <div>
              <OrganTypeBadge organ={value.organ} />
            </div>
            <div>{donationTypeFormatter({ value: value.donationType })}</div>
            <div>{sessionTypeFormatter({ value: value.sessionType })}</div>
          </div>
        </div>
        <MdOutlineChevronRight className="w-5 h-5" />
      </Link>
    </>
  );
}
