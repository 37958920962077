import { Assets } from '@packages/assets';
import { Routes } from '@packages/constants';
import { useTranslation } from '@packages/translations';
import { FirebaseError } from 'firebase/app';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import InputField from '../components/Forms/InputField';
import AuthLayout from '../components/Layouts/AuthLayout';
import Button from '../components/Shared/Button';
import { createUser } from '../lib/firebase/auth';
import { FIREBASE_AUTH_ERRORS } from '../lib/firebase/authErrors';

export type RegisterFormValues = {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  organization: string;
  role: string;
};

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const [error, setError] = useState<Error>();

  const initialValues = {
    email: params.get('email') ?? '',
    password: '',
    firstname: '',
    lastname: '',
    organization: '',
    role: '',
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email('Email is invalid').required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(6, t('registration_password_invalid_length'))
      .matches(/.*[A-Z].*/g, t('registration_password_invalid_uppercase'))
      .matches(/.*[a-z].*/g, t('registration_password_invalid_lowercase'))
      .matches(/.*[0-9].*/g, t('registration_password_invalid_number')),
    firstname: yup.string().required('First name is required'),
    lastname: yup.string().required('Last name is required'),
    organization: yup.string().required('Organization is required'),
    role: yup.string().required('Role is required'),
  });

  async function onSubmit(values: RegisterFormValues) {
    createUser(values.email, values.password, values.firstname, values.lastname, values.organization, values.role)
      .then(() => {
        navigate(Routes.web.DASHBOARD);
      })
      .catch(err => {
        const firebaseError = err as FirebaseError;
        setError(FIREBASE_AUTH_ERRORS[firebaseError.code] || err.message);
      });
  }

  return (
    <AuthLayout title={t('meta_title_register')}>
      <div className="mb-md-5 mt-6 mb-10">
        <Link to={Routes.web.REGISTER} className="d-block">
          <img src={Assets.logo.paragonixLogoLightPng} alt="" style={{ height: '18px' }} />
        </Link>
      </div>
      <div>
        <h5 className="text-dark mb-2 text-xl font-medium">{t('register_title')}</h5>
        <p className="text-muted">{t('register_subtitle')}</p>
      </div>

      <div className="mt-8">
        <div className="mt-6">
          {error && <div className="mb-6 rounded border border-[#fcd2d2] bg-[#fde1e1] px-6 py-3 text-[#924040]">{error}</div>}
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={values => onSubmit(values)}>
            {({ errors, touched }) => (
              <Form className="space-y-6">
                <div>
                  <InputField
                    type="email"
                    placeholder={t('email_placeholder')}
                    label={t('email')}
                    name="email"
                    error={errors.email}
                    touched={touched.email}
                  />
                </div>

                <div className="space-y-1">
                  <InputField
                    type="password"
                    placeholder={t('password_placeholder')}
                    label={t('password')}
                    name="password"
                    error={errors.password}
                    touched={touched.password}
                  />
                </div>

                <div className="space-y-1">
                  <InputField
                    type="text"
                    placeholder={t('firstname_placeholder')}
                    label={t('firstname')}
                    name="firstname"
                    error={errors.firstname}
                    touched={touched.firstname}
                  />
                </div>

                <div className="space-y-1">
                  <InputField
                    type="text"
                    placeholder={t('lastname_placeholder')}
                    label={t('lastname')}
                    name="lastname"
                    error={errors.lastname}
                    touched={touched.lastname}
                  />
                </div>

                <div className="space-y-1">
                  <InputField
                    type="text"
                    placeholder={t('organization_placeholder')}
                    label={t('organization')}
                    name="organization"
                    error={errors.organization}
                    touched={touched.organization}
                  />
                </div>

                <div className="space-y-1">
                  <InputField
                    type="text"
                    placeholder={t('role_placeholder')}
                    label={t('role')}
                    name="role"
                    error={errors.role}
                    touched={touched.role}
                  />
                </div>

                <div>
                  <Button block type="submit">
                    {t('register')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <div className="my-14 text-center">
        <p>
          {t('login_text').replace('%s', '')}{' '}
          <Link to={Routes.web.LOGIN} className="text-dark font-medium">
            {t('login')}
          </Link>
        </p>
      </div>

      <p className="text-muted mt-4 text-center">© {new Date().getFullYear()} Paragonix Technologies Inc.</p>
    </AuthLayout>
  );
};

export default Register;
