import classNames from 'classnames';
import { FC, ReactNode } from 'react';

export type CardProps = {
  title?: ReactNode;
  noPadding?: boolean;
  noShadow?: boolean;
  centered?: boolean;
  className?: string;
};

const Card: FC<CardProps> = ({ title, noPadding = false, noShadow = false, centered, className, children }) => {
  return (
    <div
      className={classNames(
        { 'p-0': noPadding, 'px-6 py-6': !noPadding, 'shadow-none': noShadow, 'shadow-sm': !noShadow },
        'h-full w-full rounded bg-white',
        className,
      )}
    >
      {title && (
        <div
          className={classNames(
            { 'px-6 pt-6': noPadding, 'p-0': !noPadding, 'text-center': centered },
            'text-dark mb-4 text-[16px] font-[600]',
          )}
        >
          {title}
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;
