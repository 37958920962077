import { DonationTypeUtil, ProductUtil, Session, SessionTypeUtil } from '@packages/firebase';
import { formatTemperature } from '@packages/utils';
import { format } from 'date-fns';

export function generateSessionsTableCsvData(sessions: Session[], t: (string) => string) {
  return {
    columns: [
      {
        id: 'joinKey',
        displayName: t('key'),
      },
      {
        id: 'createdAt',
        displayName: t('date'),
      },
      {
        id: 'loggerState',
        displayName: t('logger_state'),
      },
      {
        id: 'organ',
        displayName: t('organ'),
      },
      {
        id: 'donationType',
        displayName: t('donation_type'),
      },
      {
        id: 'sessionType',
        displayName: t('session_type'),
      },
      {
        id: 'minTemperature',
        displayName: `${t('min_abbrevation')} ${t('temperature_abbrevation')}`,
      },
      {
        id: 'maxTemperature',
        displayName: `${t('max_abbrevation')} ${t('temperature_abbrevation')}`,
      },
      {
        id: 'avgTemperature',
        displayName: `${t('avg_abbrevation')} ${t('temperature_abbrevation')}`,
      },
      {
        id: 'ischemicTime',
        displayName: t('ischemic_time_title'),
      },
    ],
    data: sessions.map(session => ({
      joinKey: session.joinKey.toString() ?? '-',
      createdAt: session.createdAt != null ? format(session.createdAt, "yyyy-MM-dd'T'HH:mm:ssxxxxx") : '-',
      loggerState: session.getState().toString(),
      organ: session.product ? ProductUtil.toHeading(session.product) : '-',
      donationType: session.donationType ? DonationTypeUtil.toUIString(session.donationType) : '-',
      sessionType: session.sessionType ? SessionTypeUtil.toUIString(session.sessionType) : '-',
      minTemperature: session.reportData?.probeMinimum != null ? formatTemperature(session.reportData?.probeMinimum) : '-',
      maxTemperature: session.reportData?.probeMaximum != null ? formatTemperature(session.reportData?.probeMaximum) : '-',
      avgTemperature: session.reportData?.probeAverage != null ? formatTemperature(session.reportData?.probeAverage) : '-',
      ischemicTime: session.ischemicTime != null ? session.ischemicTime.durationString() : '-',
    })),
  };
}
