import { Session, SessionState } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import { formatPressure, formatTemperature } from '@packages/utils';
import { MdCircle } from 'react-icons/md';
import Card from '../Shared/Card';

type PressureDetailsProps = {
  session: Session;
};

export default function PressureDetails({ session }: PressureDetailsProps) {
  const { t } = useTranslation();

  return (
    <Card title={t('session_pressure_details_title')} centered>
      {session.getState() !== SessionState.FINISHED && session?.loggerData != null ? (
        <PressureDetailsLive session={session} />
      ) : (
        <PressureDetailsReadings session={session} />
      )}
    </Card>
  );
}

function PressureDetailsReadings({ session }: { session: Session }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row pt-6 pb-10 text-center divide-x">
      <div className="flex-1 py-1">
        <p className="text-[13px] text-muted mb-3">{t('minimum')}</p>
        <p className="text-[19px] font-medium text-dark">{formatPressure(session.reportData?.pressureMinimum)}</p>
      </div>
      <div className="flex-1 py-1">
        <p className="text-[13px] text-muted mb-3">{t('average')}</p>
        <p className="text-[19px] font-medium text-dark">{formatPressure(session.reportData?.pressureAverage)}</p>
      </div>
      <div className="flex-1 py-1">
        <p className="text-[13px] text-muted mb-3">{t('maximum')}</p>
        <p className="text-[19px] font-medium text-dark">{formatPressure(session.reportData?.pressureMaximum)}</p>
      </div>
    </div>
  );
}

function PressureDetailsLive({ session }: { session: Session }) {
  return (
    <div className="flex flex-row pt-6 pb-10 text-center divide-x">
      <div className="flex-1 py-1">
        <p className="text-[13px] text-muted mb-3 inline-flex items-baseline">
          <MdCircle className="mr-1 text-product" />
          Pressure
        </p>
        <p className="text-[19px] font-medium text-dark">
          {session.loggerData?.pressure != null ? formatTemperature(session.loggerData.pressure) : '-'}
        </p>
      </div>
    </div>
  );
}
