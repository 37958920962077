function TextContent({ title, children }) {
  return (
    <div className="text-content">
      <h2>{title}</h2>
      <div>{children}</div>
    </div>
  );
}

export default TextContent;
