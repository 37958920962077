import { OrganizationMember, OrganizationPreselectedMember } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import { useEffect, useMemo } from 'react';
import { useRowSelect, useTable } from 'react-table';
import Switch from '../../Shared/Switch';

export type PreselectedMembersTableProps = {
  members: Array<OrganizationMember | OrganizationPreselectedMember>;
  onSelectedMembers?: (members: Array<OrganizationMember | OrganizationPreselectedMember>) => void;
};

const PreselectedMembersTable = ({ members, onSelectedMembers }: PreselectedMembersTableProps) => {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return [
      {
        id: 'name',
        Header: t('name'),
        accessor: row => `${row.firstname} ${row.lastname}`,
        width: 100,
      },
      {
        Header: t('email'),
        accessor: 'email',
        width: 300,
      },
      {
        Header: 'Access Role',
        accessor: 'accessRole',
      },
      {
        Header: 'Approved',
        accessor: 'isConfirmed',
        Cell: Toggle,
        align: 'right',
      },
    ];
  }, []);

  const table = useTable(
    {
      columns: columns as any,
      data: members,
    },

    useRowSelect,
  );

  useEffect(() => {
    const selectedRows = table.selectedFlatRows.map(selectedRow => selectedRow.original);
    onSelectedMembers?.(selectedRows);
  }, [table.selectedFlatRows]);

  return (
    <div>
      <div>
        <div className="overflow-scroll">
          <table className="min-w-full">
            <thead className="mb-1 bg-gray-200">
              {table.headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      scope="col"
                      className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-4"
                      {...column.getHeaderProps({
                        style: { minWidth: column.minWidth || 100, width: column.width },
                      })}
                    >
                      <div className="flex items-center space-x-1">
                        <span className="text-base">{column.render('Header')}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody className="bg-white" {...table.getTableBodyProps()}>
              {table.rows.map(row => {
                table.prepareRow(row);

                return (
                  <tr className="border-b border-gray-100 hover:bg-gray-50" {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td className="whitespace-nowrap px-4 py-4 text-gray-500" {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Toggle = ({ row }) => {
  const { checked } = row.getToggleRowSelectedProps();
  if (row.values.accessRole === 'ADMIN') {
    return <div>Approved</div>;
  }
  return (
    <Switch checked={checked} onChange={row.toggleRowSelected}>
      {' '}
    </Switch>
  );
};

export default PreselectedMembersTable;
