import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAuthUser } from '../contexts/useAuthUser';
import { logout } from '../lib/firebase/auth';
import { appStoreRedirect } from '../utils/appStoreRedirect';

const OrganizationRegister = () => {
  const { organizationId } = useParams();
  const [params] = useSearchParams();

  const navigate = useNavigate();
  const { authUser, isLoading } = useAuthUser();

  useEffect(() => {
    appStoreRedirect();

    if (isLoading) {
      return;
    }

    const email = params.get('email');

    if (email == null) {
      navigate('/');
      return;
    }

    if (authUser != null && email !== authUser.email) {
      // eslint-disable-next-line no-restricted-globals
      const isConfirmed = confirm(
        `You are invited to join an organization, but with a different email address. You are currently signed in with ${authUser.email}. The invite is for ${email}. Please sign out and register with ${email}. Click OK to sign out!`,
      );

      if (isConfirmed) {
        logout();
        return;
      }

      navigate('/');
      return;
    }

    navigate(`/organization/${organizationId}/join?email=${encodeURIComponent(email)}`);
  }, [isLoading]);

  return null;
};

export default OrganizationRegister;
