import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CookieBanner from '../../CookieBanner';
import AllowMarketingMailsModal from '../../Shared/AllowMarketingMailsModal';
import Notifications from '../../Shared/Notifications';
import Header from './Header';
import Sidebar from './Sidebar';
import { useChat } from '../../../contexts/useChat';
import { useAuthUser } from '../../../contexts/useAuthUser';

export type AppLayoutProps = {
  children: ReactNode;
};

export default function AppLayout({ children }: AppLayoutProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { authUser } = useAuthUser();
  const { chatClient, connect } = useChat();

  const toggleSidebar = (forceClose = false) => {
    setIsSidebarOpen(forceClose ? false : !isSidebarOpen);
  };

  useEffect(() => {
    if (!authUser) return undefined;

    connect(authUser.uid);

    return () => {
      chatClient.disconnectUser();
    };
  }, [authUser]);

  return (
    <>
      <Helmet>
        <title>Paragonix</title>
        <body className="bg-[#f8f8fb]" />
      </Helmet>

      <div className="relative">
        <Header toggleSidebar={toggleSidebar} />
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        <main className={classNames('lg:ml-[250px] relative top-[70px] min-h-[calc(100vh-70px)] pb-20')}>
          {children}
          <footer className="absolute bottom-0 inset-x-0 text-[#74788d] bg-[#f2f2f5] px-10 py-5">
            © {new Date().getFullYear()} Paragonix Technologies Inc.
          </footer>
        </main>

        <AllowMarketingMailsModal />
        <Notifications />
        <CookieBanner />
      </div>
    </>
  );
}
