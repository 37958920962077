import { useTranslation } from '@packages/translations';
import { millisecondsToTime } from '@packages/utils';
import { useEffect, useMemo, useState } from 'react';

export function useETACountdown(eta?: Date) {
  const { t } = useTranslation();
  const [countDown, setCountDown] = useState(eta != null ? eta.getTime() - new Date().getTime() : null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (eta == null) {
        setCountDown(null);
        return;
      }

      setCountDown(eta.getTime() - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [eta]);

  const formatted = useMemo(() => {
    if (countDown == null) {
      return t('eta_not_set');
    }

    if (countDown >= 0 && countDown < 60000) {
      return 'in 0m';
    }

    if (countDown < 0 && countDown >= -60000) {
      return 'since 0m';
    }

    if (countDown < 0) {
      return `since ${millisecondsToTime(Math.abs(countDown), { seconds: false })}`;
    }

    return `in ${millisecondsToTime(countDown, { seconds: false })}`;
  }, [countDown, t]);

  return formatted;
}
