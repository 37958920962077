import { Colors } from '@packages/constants';
import { useState } from 'react';
import { MdOutlinePlayCircleOutline } from 'react-icons/md';
import Card from '../Shared/Card';

import VideoModal from './VideoModal';

interface Item {
  name: string;
  videoUrl: string;
}

export default function VideoList({ title, items, options }) {
  const [isVideoModalOpen, setVideoModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<Item | undefined>();

  function onToggleVideoModal(item?: Item) {
    setCurrentItem(item);
    setVideoModal(!isVideoModalOpen);
  }

  return (
    <>
      <Card title={title}>
        <ul>
          {items?.length > 0 &&
            items.map((item, key) => (
              <li className="">
                <button key={key} className="my-10 px-3 flex items-center" type="button" onClick={() => onToggleVideoModal(item)}>
                  <div
                    className="rounded flex items-center justify-center mr-5 flex-none"
                    style={{ backgroundColor: options?.itemBgColor ?? Colors.COLOR_RL_BG_DEFAULT, width: '54px', height: '54px' }}
                  >
                    <div style={{ color: options?.itemIconColor ?? Colors.COLOR_RL_ICON_DEFAULT }}>
                      <MdOutlinePlayCircleOutline className="h-7 w-7" />
                    </div>
                  </div>

                  <div className="text-left">
                    <u className="font-[600] text-[16px] text-dark">{item.name}</u>
                  </div>
                </button>
              </li>
            ))}
        </ul>
      </Card>

      {isVideoModalOpen && currentItem != null && (
        <VideoModal
          isOpen={isVideoModalOpen}
          onToggle={() => onToggleVideoModal()}
          title={currentItem.name}
          videoUrl={currentItem.videoUrl}
        />
      )}
    </>
  );
}
