import { Session, generateCsvDataFromExportables } from '@packages/firebase';
import { chunkArray } from '@packages/utils';
import { saveAs } from 'file-saver';
import { getDoc, getDocs } from 'firebase/firestore';
import JSZip from 'jszip';
import { toCsv } from 'react-csv-downloader';
import { toast } from 'react-toastify';
import { getSessionReadings, getSessionTimeline } from '../../../../lib/firebase/firestore';
import { generateSessionsTableCsvData } from '../../../../utils/generateSessionsTableCsvData';

export async function exportSessionReports(sessions: Session[], t: (string) => string) {
  const chunkedSessions = chunkArray(sessions, 5);

  const csvData: any[] = [];

  for (const chunk of chunkedSessions) {
    // eslint-disable-next-line
    const result = await Promise.all(chunk.map(async session => ({ session, data: await generateCsvData(session, t) })));

    csvData.push(...result);
  }

  const csvFiles = csvData
    .filter(elem => elem.data != null)
    .map(elem => ({
      filename: `paragonix_${elem.session.joinKey}.csv`,
      blob: new Blob([`${elem.data}`], { type: 'text/csv;charset=utf-8' }),
    }));

  if (sessions.length > 1) {
    downloadZip(csvFiles);
  } else {
    download(csvFiles);
  }

  return csvFiles.length;
}

export async function exportSessionsTable(sessions: Session[], t: (string) => string) {
  const csvData = generateSessionsTableCsvData(sessions, t);

  const csvFile = await toCsv({ columns: csvData.columns, datas: csvData.data, separator: ',' });

  download([
    {
      filename: 'paragonix_sessions_table.csv',
      blob: new Blob([`${csvFile}`], { type: 'text/csv;charset=utf-8' }),
    },
  ]);
}

async function generateCsvData(session: Session, t: (string) => string) {
  const [readings, timeline] = await Promise.all([
    getReadings(session.id, session.joinKey, t),
    getTimeline(session.id, session.joinKey, t),
  ]);

  if (readings == null || timeline == null) {
    return null;
  }

  const csvData = generateCsvDataFromExportables(session, readings, timeline);

  return toCsv({ columns: csvData.columns, datas: csvData.data, separator: ',' });
}

async function getReadings(sessionId: string, joinKey: number, t: (string) => string) {
  try {
    const query = getSessionReadings(sessionId);

    const ref = await getDoc(query);

    return ref.data()?.log ?? [];
  } catch (err) {
    toast.warning(<span>{t('export_error_message_alt').replace('%s', joinKey.toString())}</span>, {
      autoClose: false,
      closeOnClick: false,
    });
    return null;
  }
}

async function getTimeline(sessionId: string, joinKey: number, t: (string) => string) {
  try {
    const query = getSessionTimeline(sessionId);
    const ref = await getDocs(query);

    return (ref.docs ?? []).map(doc => doc.data());
  } catch (err) {
    toast.warning(<span>{t('export_error_message_alt').replace('%s', joinKey.toString())}</span>, {
      autoClose: false,
      closeOnClick: false,
    });
    return null;
  }
}

function download(csvFiles: Array<{ filename: string; blob: Blob }>) {
  csvFiles.forEach(file => saveAs(file.blob, file.filename));
}

function downloadZip(csvFiles: Array<{ filename: string; blob: Blob }>) {
  const zip = new JSZip();

  csvFiles.forEach(file => zip.file(file.filename, file.blob));

  zip.generateAsync({ type: 'blob' }).then(blob => {
    saveAs(blob, 'paragonix_session_reports');
  });
}
