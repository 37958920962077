import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

export type EmptyLayoutProps = {
  title: string;
  children: ReactNode;
};

export default function EmptyLayout({ title, children }: EmptyLayoutProps) {
  return (
    <>
      <Helmet>
        <title>{title} | Paragonix</title>
        {/* eslint-disable jsx-a11y/html-has-lang */}
        <html className="empty-layout" />
      </Helmet>

      <main>{children}</main>
    </>
  );
}
