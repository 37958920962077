function PrivacyPolicyText() {
  return (
    <>
      <p>Last Updated: 20JAN21</p>
      <p>
        At Paragonix, we take your privacy seriously. Please read this Privacy Policy to learn how we treat your personal data within the
        Paragonix App.{' '}
        <strong>
          By using or accessing our Services in any manner, you acknowledge that you accept the practices and policies outlined below, and
          you hereby consent that we will collect, use and share your information as described in this Privacy Policy.
        </strong>
      </p>
      <p>
        Remember that your use of Paragonix&rsquo;s Services is at all times subject to our Terms of Use, which incorporates this Privacy
        Policy. Any terms we use in this Policy without defining them have the definitions given to them in the Terms of Use.
      </p>
      <p>
        You may print a copy of this Privacy Policy by clicking here{' '}
        <a href="https://paragonixtechnologies.com/legal/" target="_blank" rel="noreferrer">
          https://paragonixtechnologies.com/legal/
        </a>
        . If you have a disability, you may access this Privacy Policy in an alternative format by contacting{' '}
        <a href="mailto:support@paragonixtechnologies.com">support@paragonixtechnologies.com</a>.
      </p>
      <h2>
        <u>What this Privacy Policy Covers</u>
      </h2>
      <p>
        This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Services via the Paragonix App.
        &ldquo;Personal Data&rdquo; means any information that identifies or relates to a particular individual and also includes
        information referred to as &ldquo;personally identifiable information&rdquo; or &ldquo;personal information&rdquo; under applicable
        data privacy laws, rules or regulations. This Privacy Policy does not cover the practices of companies we don&rsquo;t own or control
        or people we don&rsquo;t manage.
      </p>
      <h2>
        <u>Personal Data</u>
      </h2>
      <p>
        <em>
          <u>Categories of Personal Data We Collect</u>
        </em>
      </p>
      <p>This chart details the categories of Personal Data that we collect and have collected over the past 12 months:</p>
      <div className="table-responsive">
        <table className="table table-bordered list-unstyled">
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>
                    <u>Category of Personal Data </u>
                  </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>
                    <u>Examples of Personal Data We Collect</u>
                  </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>
                    <u>Categories of Third Parties With Whom We Share this Personal Data:</u>
                  </strong>
                  <u> </u>
                  <strong>
                    <u> </u>
                  </strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Profile or Contact Data</strong>
                </p>
              </td>
              <td>
                <ul className="ms-3">
                  <li>First and last name</li>
                  <li>Email</li>
                  <li>Phone number</li>
                  <li>Unique identifiers such as passwords</li>
                </ul>
              </td>
              <td>
                <ul className="ms-3">
                  <li>Service Providers</li>
                  <li>Analytics Partners</li>
                  <li>Business Partners</li>
                  <li>Parties You Authorize, Access or Authenticate</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Payment Data</strong>
                </p>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Commercial Data</strong>
                </p>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Device/IP Data</strong>
                </p>
              </td>
              <td>
                <ul className="ms-3">
                  <li>Device ID</li>
                  <li>Domain server</li>
                  <li>Type of device/operating system/browser used to access the Services</li>
                </ul>
              </td>
              <td>
                <ul className="ms-3">
                  <li>Service Providers</li>
                  <li>Analytics Partners</li>
                  <li>Business Partners</li>
                  <li>Parties You Authorize, Access or Authenticate</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Web Analytics</strong>
                </p>
              </td>
              <td>
                <ul className="ms-3">
                  <li>Web page interactions</li>
                  <li>Referring webpage/source through which you accessed the Services</li>
                  <li>Non-identifiable request IDs</li>
                  <li>Statistics associated with the interaction between device or browser and the Services</li>
                </ul>
              </td>
              <td>
                <ul className="ms-3">
                  <li>Service Providers</li>
                  <li>Analytics Partners</li>
                  <li>Business Partners</li>
                  <li>Parties You Authorize, Access or Authenticate</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Social Network Data</strong>
                </p>
              </td>
              <td>
                <ul className="ms-3">
                  <li>Email</li>
                  <li>Phone number</li>
                  <li>User name</li>
                </ul>
              </td>
              <td>
                <ul className="ms-3">
                  <li>Business Partners</li>
                  <li>Parties You Authorize, Access or Authenticate</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Consumer Demographic Data</strong>
                </p>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Professional or Employment-Related Data</strong>
                </p>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Non-Public Education Data</strong>
                </p>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Geolocation Data</strong>
                </p>
              </td>
              <td>
                <ul className="ms-3">
                  <li>IP-address-based location information</li>
                  <li>GPS data</li>
                </ul>
              </td>
              <td>
                <ul className="ms-3">
                  <li>Service Providers</li>
                  <li>Analytics Partners</li>
                  <li>Business Partners</li>
                  <li>Parties You Authorize, Access or Authenticate</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Biometric Data</strong>
                </p>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Sensory Data</strong>
                </p>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Health Data</strong>
                </p>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Inferences Drawn From Other Personal Data Collected</strong>
                </p>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Other Identifying Information that You Voluntarily Choose to Provide</strong>
                </p>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  [<strong>Other</strong>]
                </p>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
              <td>
                <ul className="ms-3">
                  <li>N/A</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <em>
          <u>Categories of Sources of Personal Data</u>
        </em>
      </p>
      <p>We collect Personal Data about you from the following categories of sources:</p>
      <ul>
        <li>
          <strong>You</strong>
        </li>
        <ul>
          <li>
            <u>When you provide such information directly to us</u>.
          </li>
          <ul>
            <li>When you create an account or use our interactive tools and Services.</li>
            <li>
              When you voluntarily provide information in free-form text boxes through the Services or through responses to surveys or
              questionnaires.
            </li>
            <li>When you send us an email or otherwise contact us.</li>
          </ul>
          <li>
            <u>When you use the Services and such information is collected automatically</u>.
          </li>
          <ul>
            <li>Through Cookies (defined in the &ldquo;Tracking Tools and Opt-Out&rdquo; section below).</li>
            <li>
              If you download our mobile application or use a location-enabled browser, we may receive information about your location and
              mobile device, as applicable.
            </li>
            <li>
              If you download and install certain applications and software we make available, we may receive and collect information
              transmitted from your computing device for the purpose of providing you the relevant Services, such as information regarding
              when you are logged on and available to receive updates or alert notices.
            </li>
          </ul>
        </ul>
        <li>
          <strong>Third Parties</strong>
        </li>
      </ul>
      <p>
        <em>
          <u>Our Commercial or Business Purposes for Collecting Personal Data</u>
        </em>
      </p>
      <ul>
        <li>
          <strong>Providing, Customizing and Improving the Services</strong>
        </li>
        <ul>
          <li>Creating and managing your account or other user profiles.</li>
          <li>Providing you with the products, services or information you request.</li>
          <li>Meeting or fulfilling the reason you provided the information to us.</li>
          <li>Providing support and assistance for the Services.</li>
          <li>Improving the Services, including testing, research, internal analytics and product development.</li>
          <li>Personalizing the Services, website content and communications based on your preferences.</li>
          <li>Doing fraud protection, security and debugging.</li>
          <li>
            Carrying out other business purposes stated when collecting your Personal Data or as otherwise set forth in applicable data
            privacy laws, such as the California Consumer Privacy Act (the &ldquo;CCPA&rdquo;).
          </li>
        </ul>
        <li>
          <strong>Corresponding with You</strong>
        </li>
        <ul>
          <li>
            Responding to correspondence that we receive from you, contacting you when necessary or requested, and sending you information
            about Paragonix or the Services.
          </li>
          <li>
            Sending emails and other communications according to your preferences or that display content that we think will interest you.
          </li>
        </ul>
        <li>
          <strong>Meeting Legal Requirements and Enforcing Legal Terms</strong>
        </li>
        <ul>
          <li>
            Fulfilling our legal obligations under applicable law, regulation, court order or other legal process, such as preventing,
            detecting and investigating security incidents and potentially illegal or prohibited activities.
          </li>
          <li>Protecting the rights, property or safety of you, Paragonix or another party.</li>
          <li>Enforcing any agreements with you.</li>
          <li>Responding to claims that any posting or other content violates third-party rights.</li>
          <li>Resolving disputes.</li>
        </ul>
      </ul>
      <p>
        We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated
        or incompatible purposes without providing you notice.
      </p>
      <h2>
        <u>How We Share Your Personal Data</u>
      </h2>
      <p>
        We disclose your Personal Data to the categories of service providers and other parties listed in this section. Depending on state
        laws that may be applicable to you, some of these disclosures may constitute a &ldquo;sale&rdquo; of your Personal Data. For more
        information, please refer to the state-specific sections below.
      </p>
      <ul>
        <li>
          <strong>Analytics Partners. </strong>These parties provide analytics on web traffic or usage of the Services. They include:
        </li>
        <ul>
          <li>Companies that track how users found or were referred to the Services.</li>
          <li>Companies that track how users interact with the Services.</li>
        </ul>
      </ul>
      <p>
        <em>
          <u>Legal Obligations</u>
        </em>
      </p>
      <p>
        We may share any Personal Data that we collect with third parties in conjunction with any of the activities set forth under
        &ldquo;Meeting Legal Requirements and Enforcing Legal Terms&rdquo; in the &ldquo;Our Commercial or Business Purposes for Collecting
        Personal Data&rdquo; section above.
      </p>
      <p>
        <em>
          <u>Business Transfers</u>
        </em>
      </p>
      <p>
        All of your Personal Data that we collect may be transferred to a third party if we undergo a merger, acquisition, bankruptcy or
        other transaction in which that third party assumes control of our business (in whole or in part). Should one of these events occur,
        we will make reasonable efforts to notify you before your information becomes subject to different privacy and security policies and
        practices.
      </p>
      <p>
        <em>
          <u>Data that is Not Personal Data</u>
        </em>
      </p>
      <p>
        We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by removing information that
        makes the data personally identifiable to a particular user. We may use such aggregated, de-identified or anonymized data and share
        it with third parties for our lawful business purposes, including to analyze, build and improve the Services and promote our
        business, provided that we will not share such data in a manner that could identify you.
      </p>
      <h2>
        <u>Tracking Tools and Opt-Out</u>
      </h2>
      <p>
        The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs and JavaScript (collectively,
        &ldquo;Cookies&rdquo;) to enable our servers to recognize your web browser, tell us how and when you visit and use our Services,
        analyze trends, learn about our user base and operate and improve our Services. Cookies are small pieces of data– usually text files
        – placed on your computer, tablet, phone or similar device when you use that device to access our Services. We may also supplement
        the information we collect from you with information received from third parties, including third parties that have placed their own
        Cookies on your device(s). Please note that because of our use of Cookies, the Services do not support &ldquo;Do Not Track&rdquo;
        requests sent from a browser at this time.
        <br />
        We use the following types of Cookies:
      </p>
      <ul>
        <li>
          <u>Essential Cookies</u>. Essential Cookies are required for providing you with features or services that you have requested. For
          example, certain Cookies enable you to log into secure areas of our Services. Disabling these Cookies may make certain features
          and services unavailable.
        </li>
        <li>
          <u>Functional Cookies</u>. Functional Cookies are used to record your choices and settings regarding our Services, maintain your
          preferences over time and recognize you when you return to our Services. These Cookies help us to personalize our content for you,
          greet you by name and remember your preferences (for example, your choice of language or region).
        </li>
        <li>
          <u>Performance/Analytical Cookies</u>. Performance/Analytical Cookies allow us to understand how visitors use our Services. They
          do this by collecting information about the number of visitors to the Services, what pages visitors view on our Services and how
          long visitors are viewing pages on the Services. Performance/Analytical Cookies also help us measure the performance of our
          advertising campaigns in order to help us improve our campaigns and the Services&rsquo; content for those who engage with our
          advertising. For example, Google Inc. (&ldquo;Google&rdquo;) uses cookies in connection with its Google Analytics services.
          Google&rsquo;s ability to use and share information collected by Google Analytics about your visits to the Services is subject to
          the Google Analytics Terms of Use and the Google Privacy Policy. You have the option to opt-out of Google&rsquo;s use of Cookies
          by visiting the Google advertising opt-out page at www.google.com/privacy_ads.html or the Google Analytics Opt-out Browser Add-on
          at https://tools.google.com/dlpage/gaoptout/.
        </li>
      </ul>
      <p>
        You can decide whether or not to accept Cookies through your internet browser&rsquo;s settings. Most browsers have an option for
        turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as well as (depending on the
        sophistication of your browser software) allow you to decide on acceptance of each new Cookie in a variety of ways. You can also
        delete all Cookies that are already on your device. If you do this, however, you may have to manually adjust some preferences every
        time you visit our website and some of the Services and functionalities may not work.
      </p>
      <p>
        To explore what Cookie settings are available to you, look in the &ldquo;preferences&rdquo; or &ldquo;options&rdquo; section of your
        browser&rsquo;s menu. To find out more information about Cookies, including information about how to manage and delete Cookies,
        please visit
        <a href="http://www.allaboutcookies.org/" target="_blank" rel="noreferrer">
          http://www.allaboutcookies.org/
        </a>
        or
        <a href="https://ico.org.uk/for-the-public/online/cookies/" target="_blank" rel="noreferrer">
          https://ico.org.uk/for-the-public/online/cookies/
        </a>
        if you are located in the European Union.
      </p>
      <h2>
        <u>Data Security and Retention</u>
      </h2>
      <p>
        We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical, technical,
        organizational and administrative security measures based on the type of Personal Data and how we are processing that data. You
        should also help protect your data by appropriately selecting and protecting your password and/or other sign-on mechanism; limiting
        access to your computer or device and browser; and signing off after you have finished accessing your account. Although we work to
        protect the security of your account and other data that we hold in our records, please be aware that no method of transmitting data
        over the internet or storing data is completely secure.
      </p>
      <p>
        We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to provide you with our
        Services. In some cases we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve
        disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. We may further retain
        information in an anonymous or aggregated form where that information would not identify you personally.
      </p>
      <h2>
        <u>Personal Data of Children</u>
      </h2>
      <p>
        As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data about children under 16 years of age; if you are
        a child under the age of 16, please do not attempt to register for or otherwise use the Services or send us any Personal Data. If we
        learn we have collected Personal Data from a child under 16 years of age, we will delete that information as quickly as possible. If
        you believe that a child under 16 years of age may have provided Personal Data to us, please contact us at{' '}
        <a href="mailto:support@paragonixtechnologies.com">support@paragonixtechnologies.com</a>
      </p>
      <h2>
        <u>California Resident Rights</u>
      </h2>
      <p>
        If you are a California resident, you have the rights set forth in this section. Please see the &ldquo;Exercising Your Rights&rdquo;
        section below for instructions regarding how to exercise these rights. Please note that we may process Personal Data of our
        customers&rsquo; end users or employees in connection with our provision of certain services to our customers. If we are processing
        your Personal Data as a service provider, you should contact the entity that collected your Personal Data in the first instance to
        address your rights with respect to such data.
      </p>
      <p>
        If there are any conflicts between this section and any other provision of this Privacy Policy and you are a California resident,
        the portion that is more protective of Personal Data shall control to the extent of such conflict. If you have any questions about
        this section or whether any of the following rights apply to you, please contact us at{' '}
        <a href="mailto:support@paragonixtechnologies.com">support@paragonixtechnologies.com</a>.
      </p>
      <p>
        <em>
          <u>Access</u>
        </em>
        . You have the right to request certain information about our collection and use of your Personal Data over the past 12 months. In
        response, we will provide you with the following information:
      </p>
      <ul>
        <li>The categories of Personal Data that we have collected about you.</li>
        <li>The categories of sources from which that Personal Data was collected.</li>
        <li>The business or commercial purpose for collecting or selling your Personal Data.</li>
        <li>The categories of third parties with whom we have shared your Personal Data.</li>
        <li>The specific pieces of Personal Data that we have collected about you.</li>
      </ul>
      <p>
        If we have disclosed your Personal Data to any third parties for a business purpose over the past 12 months, we will identify the
        categories of Personal Data shared with each category of third party recipient. If we have sold your Personal Data over the past 12
        months, we will identify the categories of Personal Data sold to each category of third party recipient.
      </p>
      <p>
        <em>
          <u>Deletion</u>
        </em>
        . You have the right to request that we delete the Personal Data that we have collected about you. Under the CCPA, this right is
        subject to certain exceptions: for example, we may need to retain your Personal Data to provide you with the Services or complete a
        transaction or other action you have requested. If your deletion request is subject to one of these exceptions, we may deny your
        deletion request.
      </p>
      <p>
        <em>
          <u>Exercising Your Rights</u>
        </em>
        . To exercise the rights described above, you or your Authorized Agent (defined below) must send us a request that (1) provides
        sufficient information to allow us to verify that you are the person about whom we have collected Personal Data, and (2) describes
        your request in sufficient detail to allow us to understand, evaluate and respond to it. Each request that meets both of these
        criteria will be considered a &ldquo;Valid Request.&rdquo; We may not respond to requests that do not meet these criteria. We will
        only use Personal Data provided in a Valid Request to verify your identity and complete your request. You do not need an account to
        submit a Valid Request.
      </p>
      <p>
        We will work to respond to your Valid Request within 45 days of receipt. We will not charge you a fee for making a Valid Request
        unless your Valid Request(s) is excessive, repetitive or manifestly unfounded. If we determine that your Valid Request warrants a
        fee, we will notify you of the fee and explain that decision before completing your request.
      </p>
      <p>You may submit a Valid Request using the following methods:</p>
      <ul>
        <li>
          <strong>Call us at</strong>: +1.781.428.4828
        </li>
        <li>
          <strong>Email us at</strong>: support@paragonixtechnologies.com
        </li>
      </ul>
      <p>
        You may also authorize an agent (an &ldquo;Authorized Agent&rdquo;) to exercise your rights on your behalf. To do this, you must
        provide your Authorized Agent with written permission to exercise your rights on your behalf, and we may request a copy of this
        written permission from your Authorized Agent when they make a request on your behalf.
      </p>
      <h2>
        <u>Personal Data Sales Opt-Out and Opt-In</u>
      </h2>
      <p>
        We will not sell your Personal Data, and have not done so over the last 12 months. To our knowledge, we do not sell the Personal
        Data of minors under 16 years of age.
      </p>
      <p>
        <em>
          <u>We Will Not Discriminate Against You for Exercising Your Rights Under the CCPA</u>
        </em>
        . We will not discriminate against you for exercising your rights under the CCPA. We will not deny you our goods or services, charge
        you different prices or rates, or provide you a lower quality of goods and services if you exercise your rights under the CCPA.
        However, we may offer different tiers of our Services as allowed by applicable data privacy laws (including the CCPA) with varying
        prices, rates or levels of quality of the goods or services you receive related to the value of Personal Data that we receive from
        you.
      </p>
      <h2>
        <u>Other State Law Privacy Rights</u>
      </h2>
      <p>
        <em>
          <u>California Resident Rights</u>
        </em>
        . Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of
        Personal Data to third parties for such third parties&rsquo; direct marketing purposes; in order to submit such a request, please
        contact us at support@paragonixtechnologies.com.
      </p>
      <p>
        <em>
          <u>Nevada Resident Rights</u>
        </em>
        . If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third parties who intend to
        license or sell that Personal Data. You can exercise this right by contacting us at support@paragonixtechnologies.com with the
        subject line &ldquo;Nevada Do Not Sell Request&rdquo; and providing us with your name and the email address associated with your
        account.
      </p>
      <h2>
        <u>European Union Data Subject Rights</u>
      </h2>
      <p>
        <em>
          <u>EU Residents</u>
        </em>
        . If you are a resident of the European Union (&ldquo;EU&rdquo;), United Kingdom, Lichtenstein, Norway or Iceland, you may have
        additional rights under the EU General Data Protection Regulation (the &ldquo;GDPR&rdquo;) with respect to your Personal Data, as
        outlined below.
      </p>
      <p>
        For this section, we use the terms &ldquo;Personal Data&rdquo; and &ldquo;processing&rdquo; as they are defined in the GDPR, but
        &ldquo;Personal Data&rdquo; generally means information that can be used to individually identify a person, and
        &ldquo;processing&rdquo; generally covers actions that can be performed in connection with data such as collection, use, storage and
        disclosure. Paragonix will be the controller of your Personal Data processed in connection with the Services.
      </p>
      <p>
        If there are any conflicts between this this section and any other provision of this Privacy Policy, the policy or portion that is
        more protective of Personal Data shall control to the extent of such conflict. If you have any questions about this section or
        whether any of the following applies to you, please contact us at support@paragonixtechnologies.com. <br />
      </p>
      <p>
        <em>
          <u>Personal Data We Collect</u>
        </em>
        . The &ldquo;Categories of Personal Data We Collect&rdquo; section above details the Personal Data that we collect from you.
      </p>
      <p>
        <em>
          <u>Personal Data Use and Processing Grounds</u>
        </em>
        . The &ldquo;Our Commercial or Business Purposes for Collecting Personal Data&rdquo; section above explains how we use your Personal
        Data.
      </p>
      <p>
        We will only process your Personal Data if we have a lawful basis for doing so. Lawful bases for processing include consent,
        contractual necessity and our &ldquo;legitimate interests&rdquo; or the legitimate interest of others, as further described below.
      </p>
      <ul>
        <li>
          <u>Contractual Necessity</u>: We process the following categories of Personal Data as a matter of &ldquo;contractual
          necessity&rdquo;, meaning that we need to process the data to perform under our Terms of Use with you, which enables us to provide
          you with the Services. When we process data due to contractual necessity, failure to provide such Personal Data will result in
          your inability to use some or all portions of the Services that require such data.
        </li>
        <ul>
          <li>Profile or Contact Data</li>
          <li>Device/IP Data</li>
          <li>Geolocation Data</li>
        </ul>
        <li>
          <u>Legitimate Interest</u>: We process the following categories of Personal Data when we believe it furthers the legitimate
          interest of us or third parties:
        </li>
        <ul>
          <li>Profile or Contact Data</li>
          <li>Device/IP Data</li>
          <li>Geolocation Data</li>
          <li>We may also de-identify or anonymize Personal Data to further our legitimate interests.</li>
        </ul>
      </ul>
      <p>Examples of these legitimate interests include (as described in more detail above):</p>
      <ul>
        <ul>
          <li>Providing, customizing and improving the Services.</li>
          <li>Meeting legal requirements and enforcing legal terms.</li>
        </ul>
        <li>
          <u>Consent</u>: In some cases, we process Personal Data based on the consent you expressly grant to us at the time we collect such
          data. When we process Personal Data based on your consent, it will be expressly indicated to you at the point and time of
          collection.
        </li>
        <li>
          <u>Other Processing Grounds</u>: From time to time we may also need to process Personal Data to comply with a legal obligation, if
          it is necessary to protect the vital interests of you or other data subjects, or if it is necessary for a task carried out in the
          public interest.
        </li>
      </ul>
      <p>
        <em>
          <u>Sharing Personal Data</u>
        </em>
        . The &ldquo;How We Share Your Personal Data&rdquo; section above details how we share your Personal Data with third parties. <br />
      </p>
      <p>
        <em>
          <u>EU Data Subject Rights</u>
        </em>
        . You have certain rights with respect to your Personal Data, including those set forth below. For more information about these
        rights, or to submit a request, please email us at support@paragonixtechnologies.com. Please note that in some circumstances, we may
        not be able to fully comply with your request, such as if it is frivolous or extremely impractical, if it jeopardizes the rights of
        others, or if it is not required by law, but in those circumstances, we will still respond to notify you of such a decision. In some
        cases, we may also need you to provide us with additional information, which may include Personal Data, if necessary to verify your
        identity and the nature of your request.
      </p>
      <ul>
        <li>
          <strong>Access</strong>: You can request more information about the Personal Data we hold about you and request a copy of such
          Personal Data. You can also access certain of your Personal Data by logging on to your account or by sending us an email at
          support@paragonixtechnologies.com.
        </li>
        <li>
          <strong>Rectification</strong>: If you believe that any Personal Data we are holding about you is incorrect or incomplete, you can
          request that we correct or supplement such data. You can also correct some of this information directly by logging on to your
          account or by sending us an email at support@paragonixtechnologies.com.
        </li>
        <li>
          <strong>Erasure</strong>: You can request that we erase some or all of your Personal Data from our systems.
        </li>
        <li>
          <strong>Withdrawal of Consent</strong>: If we are processing your Personal Data based on your consent (as indicated at the time of
          collection of such data), you have the right to withdraw your consent at any time. Please note, however, that if you exercise this
          right, you may have to then provide express consent on a case-by-case basis for the use or disclosure of certain of your Personal
          Data, if such use or disclosure is necessary to enable you to utilize some or all of our Services.
        </li>
        <li>
          <strong>Portability</strong>: You can ask for a copy of your Personal Data in a machine-readable format. You can also request that
          we transmit the data to another controller where technically feasible.
        </li>
        <li>
          <strong>Objection</strong>: You can contact us to let us know that you object to the further use or disclosure of your Personal
          Data for certain purposes, such as for direct marketing purposes.
        </li>
        <li>
          <strong>Restriction of Processing</strong>: You can ask us to restrict further processing of your Personal Data.
        </li>
        <li>
          <strong>Right to File Complaint</strong>: You have the right to lodge a complaint about Paragonix&rsquo;s practices with respect
          to your Personal Data with the supervisory authority of your country or EU Member State. A list of Supervisory Authorities is
          available here:
          <a href="https://edpb.europa.eu/about-edpb/board/members_en" target="_blank" rel="noreferrer">
            https://edpb.europa.eu/about-edpb/board/members_en
          </a>
          .
        </li>
      </ul>
      <h2>
        <u>Transfers of Personal Data</u>
      </h2>
      <p>
        The Services are hosted and operated in the United States (&ldquo;U.S.&rdquo;) through Paragonix and its service providers, and if
        you do not reside in the U.S., laws in the U.S. may differ from the laws where you reside. By using the Services, you acknowledge
        that any Personal Data about you, regardless of whether provided by you or obtained from a third party, is being provided to
        Paragonix in the U.S. and will be hosted on U.S. servers, and you authorize Paragonix to transfer, store and process your
        information to and in the U.S., and possibly other countries. You hereby consent to the transfer of your data to the U.S. pursuant
        to: (i) a data processing agreement incorporating standard data protection clauses promulgated by the European Commission, a copy of
        which can be obtained at{' '}
        <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32010D0087" target="_blank" rel="noreferrer">
          https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32010D0087
        </a>
        , (ii) binding corporate rules for data protection that align with the GDPR&rsquo;s requirements, or (iii) adherence to an industry-
        or technology-specific approved code of conduct blessed by the European Commission.
      </p>
      <h2>
        <u>Changes to this Privacy Policy</u>
      </h2>
      <p>
        We&rsquo;re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time, but we will
        alert you to any such changes by placing a notice on the Paragonix website, by sending you an email and/or by some other means.
        Please note that if you&rsquo;ve opted not to receive legal notice emails from us (or you haven&rsquo;t provided us with your email
        address), those legal notices will still govern your use of the Services, and you are still responsible for reading and
        understanding them. If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of
        the changes. Use of information we collect is subject to the Privacy Policy in effect at the time such information is collected.
      </p>
      <h2>
        <u>Contact Information:</u>
      </h2>
      <p>
        If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data or your
        choices and rights regarding such collection and use, please do not hesitate to contact us at:
      </p>
      <ul>
        <li>+1.781.428.4828</li>
        <li>http://www.paragonixtechnologies.com</li>
        <li>support@paragonixtechnologies.com</li>
        <li>639 Granite Street, # 408, Braintree, Massachusetts 02184</li>
      </ul>
    </>
  );
}

export default PrivacyPolicyText;
