import { createContext, useContext, useMemo, useState } from 'react';
import { getStreamToken } from '../lib/firebase/functions';
import { chatClient } from '../lib/stream';

export type ChatContextProps = {
  chatClient: any;
  connect: (userId: string) => void;
  unreadChannelsCount: any;
};

const ChatContext = createContext<ChatContextProps | undefined>(undefined);

export const ChatProvider = ({ children }) => {
  const [unreadChannelsCount, setUnreadChannelsCount] = useState<any>([]);

  const connect = (userId: string) => {
    getStreamToken()
      .then(
        token =>
          new Promise(resolve => {
            setTimeout(() => resolve(token), 1000);
          }),
      )
      .then((token: any) => {
        return chatClient.connectUser(
          {
            id: userId,
          },
          token.data as string,
        );
      })
      .then(() => {
        const filter = { type: 'messaging', members: { $in: [userId] }, joined: true, disabled: false, frozen: false };

        chatClient
          .queryChannels(
            filter,
            {},
            {
              watch: true,
              state: true,
            },
          )
          .then(channels => {
            const unread = channels.reduce((acc, channel) => {
              if (channel?.id) acc[channel.id] = channel.countUnread();
              return acc;
            }, {});

            setUnreadChannelsCount(unread);

            chatClient.on(event => {
              if (event.type === 'message.new' && event.user?.id !== userId) {
                setUnreadChannelsCount(prev => {
                  if (!event.channel_id) return prev;
                  return {
                    ...prev,
                    [event.channel_id]: prev[event.channel_id] + 1,
                  };
                });
              }

              if (event.type === 'notification.mark_read') {
                setUnreadChannelsCount(prev => {
                  if (!event.channel_id) return prev;
                  return {
                    ...prev,
                    [event.channel_id]: 0,
                  };
                });
              }
            });
          });
      })
      .catch(err => {
        console.log('error getting token', err);
      });
  };

  const value = useMemo(() => {
    return {
      chatClient,
      connect,
      unreadChannelsCount,
    };
  }, [chatClient, unreadChannelsCount]);

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export function useChat() {
  const context = useContext(ChatContext);

  if (context == null) {
    throw Error('useChat must be used inside session context');
  }

  return context;
}
