import classNames from 'classnames';
import { Field } from 'formik';
import { ReactNode } from 'react';

type SelectFieldProps = {
  label?: string;
  name: string;
  error?: string;
  touched?: boolean;
  disabled?: boolean;
  children: ReactNode;
};

export default function SelectField({ label, name, error, touched, disabled, children }: SelectFieldProps) {
  return (
    <div>
      {label && (
        <div className="flex justify-between">
          <label htmlFor={name} className={classNames('text-dark block font-medium', { 'text-red-500': error && touched })}>
            {label}
          </label>
        </div>
      )}
      <div>
        <Field
          name={name}
          as="select"
          className={classNames(
            'mt-1 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 pr-10 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 disabled:cursor-not-allowed disabled:opacity-50',
            { 'border-red-500': error && touched },
          )}
          disabled={disabled}
        >
          {children}
        </Field>
      </div>
    </div>
  );
}
