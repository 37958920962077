import { Colors } from '@packages/constants';
import { ProductType, ProductUtil } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import { Helmet } from 'react-helmet-async';
import AppContent from '../components/Layouts/AppLayout/Content';
import SupportTabs from '../components/Support/SupportTabs';
import { getAppTrainingTutorials, getProductDocuments, getProductTutorials } from '../lib/firebase/firestore';

const Support = () => {
  const { t } = useTranslation();

  // KTS

  const [sherpapakDocuments] = getProductDocuments(ProductUtil.toId(ProductType.SHERPAPAK));
  const [sherpapakTutorials] = getProductTutorials(ProductUtil.toId(ProductType.SHERPAPAK));

  const [lungguardDocuments] = getProductDocuments(ProductUtil.toId(ProductType.LUNGGUARD));
  const [lungguardTutorials] = getProductTutorials(ProductUtil.toId(ProductType.LUNGGUARD));

  const [baroguardDocuments] = getProductDocuments(ProductUtil.toId(ProductType.BAROGUARD));
  const [baroguardTutorials] = getProductTutorials(ProductUtil.toId(ProductType.BAROGUARD));

  const [liverguardDocuments] = getProductDocuments(ProductUtil.toId(ProductType.LIVERGUARD));
  const [liverguardTutorials] = getProductTutorials(ProductUtil.toId(ProductType.LIVERGUARD));

  const [ktsDocuments] = getProductDocuments(ProductUtil.toId(ProductType.KTS));
  const [ktsTutorials] = getProductTutorials(ProductUtil.toId(ProductType.KTS));

  const [vantagepointDocuments] = getProductDocuments(ProductUtil.toId(ProductType.VANTAGEPOINT));
  const [vantagepointTutorials] = getProductTutorials(ProductUtil.toId(ProductType.VANTAGEPOINT));

  const [appTrainingTutorials] = getAppTrainingTutorials();

  return (
    <AppContent>
      <Helmet>
        <title>{t('meta_title_support')} | Paragonix</title>
      </Helmet>
      <SupportTabs
        documents={[
          {
            title: t('lungguard_product_heading'),
            itemBgColor: Colors.COLOR_LUNGGUARD_PRIMARY_LIGHT,
            itemIconColor: Colors.COLOR_LUNGGUARD_PRIMARY,
            items: lungguardDocuments ?? [],
          },
          {
            title: t('baroguard_product_heading'),
            itemBgColor: Colors.COLOR_LUNGGUARD_PRIMARY_LIGHT,
            itemIconColor: Colors.COLOR_LUNGGUARD_PRIMARY,
            items: baroguardDocuments ?? [],
          },
          {
            title: t('sherpapak_product_heading'),
            itemBgColor: Colors.COLOR_SHERPAPAK_PRIMARY_LIGHT,
            itemIconColor: Colors.COLOR_SHERPAPAK_PRIMARY,
            items: sherpapakDocuments ?? [],
          },
          {
            title: t('liverguard_product_heading'),
            itemBgColor: Colors.COLOR_LIVERGUARD_PRIMARY_LIGHT,
            itemIconColor: Colors.COLOR_LIVERGUARD_PRIMARY,
            items: liverguardDocuments ?? [],
          },
          {
            title: t('kts_product_heading'),
            itemBgColor: Colors.COLOR_KTS_PRIMARY_LIGHT,
            itemIconColor: Colors.COLOR_KTS_PRIMARY,
            items: ktsDocuments ?? [],
          },
          {
            title: t('vantage_point_product_heading'),
            itemBgColor: Colors.COLOR_VANTAGEPOINT_PRIMARY_LIGHT,
            itemIconColor: Colors.COLOR_VANTAGEPOINT_PRIMARY,
            items: vantagepointDocuments ?? [],
          },
        ]}
        tutorials={[
          {
            title: t('lungguard_product_heading'),
            itemBgColor: Colors.COLOR_LUNGGUARD_PRIMARY_LIGHT,
            itemIconColor: Colors.COLOR_LUNGGUARD_PRIMARY,
            items: lungguardTutorials ?? [],
          },
          {
            title: t('baroguard_product_heading'),
            itemBgColor: Colors.COLOR_LUNGGUARD_PRIMARY_LIGHT,
            itemIconColor: Colors.COLOR_LUNGGUARD_PRIMARY,
            items: baroguardTutorials ?? [],
          },
          {
            title: t('sherpapak_product_heading'),
            itemBgColor: Colors.COLOR_SHERPAPAK_PRIMARY_LIGHT,
            itemIconColor: Colors.COLOR_SHERPAPAK_PRIMARY,
            items: sherpapakTutorials ?? [],
          },
          {
            title: t('liverguard_product_heading'),
            itemBgColor: Colors.COLOR_LIVERGUARD_PRIMARY_LIGHT,
            itemIconColor: Colors.COLOR_LIVERGUARD_PRIMARY,
            items: liverguardTutorials ?? [],
          },
          {
            title: t('kts_product_heading'),
            itemBgColor: Colors.COLOR_KTS_PRIMARY_LIGHT,
            itemIconColor: Colors.COLOR_KTS_PRIMARY,
            items: ktsTutorials ?? [],
          },
          {
            title: t('vantage_point_product_heading'),
            itemBgColor: Colors.COLOR_VANTAGEPOINT_PRIMARY_LIGHT,
            itemIconColor: Colors.COLOR_VANTAGEPOINT_PRIMARY,
            items: vantagepointTutorials ?? [],
          },
          {
            title: t('app_training'),
            itemBgColor: '#D3D3D3',
            itemIconColor: '#000000',
            items: appTrainingTutorials ?? [],
          },
        ]}
      />
    </AppContent>
  );
};

export default Support;
