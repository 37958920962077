import { Assets } from '@packages/assets';
import { Routes } from '@packages/constants';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import EmptyLayout from '../components/Layouts/EmptyLayout';
import Button from '../components/Shared/Button';
import Card from '../components/Shared/Card';
import { getFirebaseAuth } from '../lib/firebase/auth';
import { detectEnvironment } from '../utils/detectEnvironment';
import { getMobileAppRedirectUrlScheme } from '../utils/getMobileAppRedirectUrlScheme';

const ResetPassword = () => {
  const environment = detectEnvironment();
  const urlScheme = getMobileAppRedirectUrlScheme();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isCheckingCode, setIsCheckingCode] = useState(false);
  const [isCodeError, setIsCodeError] = useState(false);

  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [isPasswordResetError, setIsPasswordResetError] = useState(false);
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false);

  useEffect(() => {
    setIsCheckingCode(true);

    const code = searchParams.get('code');

    if (code == null) {
      setIsCodeError(true);
      setIsCheckingCode(false);
      return;
    }

    verifyPasswordResetCode(getFirebaseAuth(), code)
      .then(() => {
        setIsCodeError(false);
      })
      .catch(() => {
        setIsCodeError(true);
      })
      .finally(() => {
        setIsCheckingCode(false);
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      newPassword: '',
    },
    validationSchema: yup.object().shape({
      newPassword: yup.string().min(6).required(),
    }),
    onSubmit: async values => {
      setIsResettingPassword(true);

      const code = searchParams.get('code');

      try {
        await confirmPasswordReset(getFirebaseAuth(), code as string, values.newPassword);
        setIsPasswordResetSuccess(true);
      } catch (err) {
        setIsPasswordResetError(true);
      } finally {
        setIsResettingPassword(false);
      }
    },
  });

  if (isCheckingCode) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <div className="spinner-border inline-block h-7 w-7 animate-spin rounded-full border-2" role="status" />
      </div>
    );
  }

  return (
    <EmptyLayout title="Reset Password | Paragonix">
      <div className="mx-auto max-w-xl px-6 py-12">
        <div className="flex justify-center pb-8">
          <img src={Assets.logo.paragonixLogoLightPng} style={{ height: '18px' }} alt="" />
        </div>

        {isCodeError && (
          <div className="mb-6 space-y-6">
            <div className="rounded border border-[#fcd2d2] bg-[#fde1e1] px-6 py-3 text-center text-[#924040]">
              Invalid or expired password reset code. Please try again.
            </div>

            {environment !== 'web' ? (
              <a
                href={urlScheme}
                className="inline-flex w-full items-center justify-center gap-2 rounded border border-[#343a40] bg-[#343a40] px-3 py-2 text-base text-[#eff2f7] outline-none hover:border-[#2c3136] hover:bg-[#2c3136]"
              >
                Back to App
              </a>
            ) : (
              <a href={Routes.web.LOGIN} className="block text-center underline">
                Back to Login
              </a>
            )}
          </div>
        )}

        {!isCodeError && !isPasswordResetError && isPasswordResetSuccess && (
          <div className="mb-6 space-y-6">
            <div className="rounded border border-[#d2fcd5] bg-[#e1fdea] px-6 py-3 text-center text-[#40925d]">
              Your password has been reset successfully. Please login with your new password.
            </div>

            {environment !== 'web' ? (
              <a
                href={urlScheme}
                className="inline-flex w-full items-center justify-center gap-2 rounded border border-[#343a40] bg-[#343a40] px-3 py-2 text-base text-[#eff2f7] outline-none hover:border-[#2c3136] hover:bg-[#2c3136]"
              >
                Back to App
              </a>
            ) : (
              <a href={Routes.web.LOGIN} className="block text-center underline">
                Back to Login
              </a>
            )}
          </div>
        )}

        {!isCodeError && !isPasswordResetSuccess && (
          <Card title="Reset Password">
            <form onSubmit={formik.handleSubmit} className="space-y-6">
              <div className="space-y-2.5">
                <p className="text-muted">Please enter your new password below. Your password must be at least 6 characters long.</p>

                <input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  placeholder="Enter a new password"
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 pr-10 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                  onChange={formik.handleChange}
                  value={formik.values.newPassword}
                />
              </div>

              {isPasswordResetError && (
                <div className="mb-6 rounded border border-[#fcd2d2] bg-[#fde1e1] px-6 py-3 text-center text-[#924040]">
                  An error occurred while resetting your password. Please try again.
                </div>
              )}

              <div className="flex justify-end space-x-2">
                <Button onClick={() => navigate(Routes.web.LOGIN)}>Cancel</Button>

                <Button
                  variant="success"
                  type="submit"
                  isLoading={isResettingPassword}
                  disabled={!formik.dirty || !formik.isValid || isResettingPassword}
                >
                  Confirm
                </Button>
              </div>
            </form>
          </Card>
        )}
      </div>
    </EmptyLayout>
  );
};

export default ResetPassword;
