import { Routes } from '@packages/constants';
import { useTranslation } from '@packages/translations';
import { Link } from 'react-router-dom';
import Button from '../../Button';

export default function viewDetailsFormatter({ value }: { value: string }) {
  const { t } = useTranslation();

  return (
    <Link to={Routes.web.SESSION_DETAIL.replace(':id', value)}>
      <Button size="sm">{t('view_details')}</Button>
    </Link>
  );
}
