import { Session } from '@packages/firebase';
import { useEffect, useState } from 'react';
import { Channel, Chat, MessageInput, VirtualizedMessageList as MessageList, Thread, Window } from 'stream-chat-react';
import { useAuthUser } from '../../contexts/useAuthUser';
import { useChat } from '../../contexts/useChat';
import useTimeout from '../../hooks/useTimeout';
import Card from '../Shared/Card';

export default function ChatTab({ session }: { session: Session }) {
  const [channel, setChannel] = useState<any>();
  const [showChatErrorMessage, setShowChatErrorMessage] = useState(false);
  const { authUser } = useAuthUser();

  const { chatClient } = useChat();

  useEffect(() => {
    if (!authUser || !session) return;

    if (session.finished) return;

    if (chatClient.user) {
      const channel = chatClient.channel('messaging', session.id);

      setChannel(channel);
    }
  }, [authUser, session, chatClient.user]);

  useTimeout(() => {
    if (!chatClient.user || !channel) {
      setShowChatErrorMessage(true);
    }
  }, 5000);

  if (session?.finished) return null;

  return (
    <div>
      {!chatClient?.user && (
        <Card centered className="text-center py-20">
          {!showChatErrorMessage && (
            <>
              <div className="flex justify-center mb-5">
                <div className="text-muted spinner-border animate-spin inline-block w-10 h-10 border-2 rounded-full" role="status" />
              </div>
              <h3 className="text-xl text-muted">Loading Chat...</h3>
            </>
          )}
          {showChatErrorMessage && (
            <p className="text-lg text-muted">Something went wrong when loading Chat. Please reload the page and try again!</p>
          )}
        </Card>
      )}

      {channel && (
        <Card>
          <Chat client={chatClient}>
            <Channel channel={channel} EmojiIcon={() => null}>
              <Window>
                <MessageList messageActions={[]} />
                <MessageInput focus />
              </Window>
              <Thread />
            </Channel>
          </Chat>
        </Card>
      )}
    </div>
  );
}
