import { TimelineEntry } from '@packages/firebase';
import { useTranslation } from '@packages/translations';

import { useEffect, useState } from 'react';
import TimelineItem from '../Shared/Timeline/TimelineItem';

function IschemicTimePanel({ ischemicTime, product }) {
  const { t } = useTranslation();

  const [duration, setDuratation] = useState<string>('00:00:00');
  const [startEvent, setStartEvent] = useState<TimelineEntry | undefined>();
  const [endEvent, setEndEvent] = useState<TimelineEntry | undefined>();

  useEffect(() => {
    if (ischemicTime == null) {
      return;
    }

    setDuratation(ischemicTime?.durationString());
  }, [ischemicTime]);

  useEffect(() => {
    if (ischemicTime?.startType == null || ischemicTime.startedAt == null) {
      return;
    }

    setStartEvent(new TimelineEntry({ type: ischemicTime.startType, timestamp: ischemicTime.startedAt }));
  }, [ischemicTime?.startType, ischemicTime?.startedAt]);

  useEffect(() => {
    if (ischemicTime?.endType == null || ischemicTime.endedAt == null) {
      return;
    }

    setEndEvent(new TimelineEntry({ type: ischemicTime.endType, timestamp: ischemicTime.endedAt }));
  }, [ischemicTime?.endType, ischemicTime?.endedAt]);

  return (
    <div>
      <div className="mt-2 mb-6 text-center">
        <div className="text-dark text-[19px] font-[500]">{duration}</div>
        <div className="text-muted">hh:mm:ss</div>
      </div>

      <div className="flex flex-col items-center">
        <div className="space-y-3">
          {startEvent == null && endEvent == null && <div className="text-dark text-[16px] font-[500]">{t('timeline_no_event')}</div>}
          {startEvent != null && <TimelineItem event={startEvent} product={product} />}
          {endEvent != null && <TimelineItem event={endEvent} product={product} />}
        </div>
      </div>
    </div>
  );
}

export default IschemicTimePanel;
