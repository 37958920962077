import { useTranslation } from '@packages/translations';
import EmptyLayout from '../components/Layouts/EmptyLayout';
import PrivacyPolicyText from '../components/PrivacyPolicyText';
import TextContent from '../components/Shared/TextContent';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <EmptyLayout title={t('privacy_policy')}>
      <TextContent title={t('privacy_policy')}>
        <PrivacyPolicyText />
      </TextContent>
    </EmptyLayout>
  );
};

export default PrivacyPolicy;
