import { Reading } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import { calculateChartTicks, millisecondsToTime } from '@packages/utils';
import { useEffect, useState } from 'react';

import Card from '../../Shared/Card';
// import { useColorContext } from '../../ColorContext';
import Chart from './Chart';
import ChartPlaceholder from './ChartPlaceholder';

interface DataItem {
  milliseconds: number;
  time: string;
  probe?: number;
}

const MIN_CHART_DATA_LENGTH = 5;

function PressureTimelineChart({ title, readings }) {
  const { t } = useTranslation();

  const [startedEvent, setStartedEvent] = useState<Reading | undefined>();
  const [latestEvent, setLatestEvent] = useState<Reading | undefined>();

  const [ticks, setTicks] = useState<number[]>([]);
  const [data, setData] = useState<DataItem[]>([]);

  useEffect(() => {
    if (readings == null || readings.length === 0) {
      return;
    }

    setStartedEvent(readings.find(reading => reading.isStartedEvent()));
    setLatestEvent(readings.find(reading => reading.isStoppedEvent()) ?? readings[readings.length - 1]);
  }, [readings]);

  useEffect(() => {
    if (readings == null || readings.length === 0) {
      return;
    }

    if (startedEvent == null) {
      return;
    }

    const validReadings = readings.filter(reading => reading.isReading());

    setData(
      validReadings.map(reading => ({
        milliseconds: reading.timestamp.getTime() - startedEvent.timestamp.getTime(),
        currentTime: reading.timestamp.toLocaleTimeString(navigator.language, { timeStyle: 'short' }),
        time: millisecondsToTime(reading.timestamp.getTime() - startedEvent.timestamp.getTime(), { seconds: false }),
        pressure: reading.pressure,
      })),
    );
  }, [readings, startedEvent, latestEvent, t]);

  useEffect(() => {
    if (startedEvent == null || latestEvent == null) {
      return;
    }

    setTicks(calculateChartTicks(startedEvent.timestamp.getTime(), latestEvent.timestamp.getTime()));
  }, [latestEvent, startedEvent]);

  if (startedEvent != null && data != null && data.length >= MIN_CHART_DATA_LENGTH) {
    return (
      <>
        <div className="hidden md:block" style={{ width: '100%', height: '500px', marginLeft: '-10px' }}>
          <Chart data={data} ticks={ticks} />
        </div>
        <div className="block md:hidden" style={{ width: '100%', height: '300px', marginLeft: '-15px' }}>
          <Chart data={data} ticks={ticks} />
        </div>
      </>
    );
  }

  if (data != null && data.length >= 0 && data.length < MIN_CHART_DATA_LENGTH) {
    return <ChartPlaceholder />;
  }

  return (
    <Card title={title}>
      <div className="w-100" />
    </Card>
  );
}

export default PressureTimelineChart;
