import { OrganizationMember, OrganizationMemberAccessRole, OrganizationPreselectedMember, OrganizationStatus } from '@packages/firebase';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppContent from '../components/Layouts/AppLayout/Content';
import OrganizationDetailsCard from '../components/Organization/OrganizationDetailsCard';
import PreselectedMembersTable from '../components/Organization/PreselectedMembers';
import Button from '../components/Shared/Button';
import Card from '../components/Shared/Card';
import { useFetchOrganizationForValidation } from '../lib/firebase/firestore';
import { confirmOrganizationValidation } from '../lib/firebase/functions';

const OrganizationSetup = () => {
  const { organizationId } = useParams();
  const navigate = useNavigate();

  const [confirmCheck, setConfirmCheck] = useState(false);
  const [isActivationLoading, setIsActivationLoading] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState<Array<OrganizationMember | OrganizationPreselectedMember>>([]);

  const { organization, members, preselectedMembers, isLoading } = useFetchOrganizationForValidation(organizationId as string);

  const onConfirmOrganization = () => {
    if (!organization || !confirmCheck) {
      return;
    }

    setIsActivationLoading(true);

    confirmOrganizationValidation(
      organization.id,
      selectedMembers.map(member => member.id),
    )
      .then(() => {
        toast.success(`Organization has been activated`, { autoClose: 5000 });
        navigate(`/organization/${organization.id}`);
      })
      .catch(error => {
        toast.error(`There was an Error: ${error.message}`, { autoClose: 5000 });
      })
      .finally(() => {
        setIsActivationLoading(false);
      });
  };

  const all = useMemo(() => {
    return {
      admins: [...(members ?? []), ...(preselectedMembers ?? [])].filter(
        member => member.accessRole === OrganizationMemberAccessRole.ADMIN,
      ),
      members: [...(members ?? []), ...(preselectedMembers ?? [])].filter(
        member => member.accessRole !== OrganizationMemberAccessRole.ADMIN,
      ),
    };
  }, [members, preselectedMembers]);

  if (isLoading) {
    return null;
  }

  if (!organization) {
    return null;
  }

  if (organization.status === OrganizationStatus.ACTIVE) {
    navigate(`/organization/${organization.id}`);
    return null;
  }

  return (
    <AppContent>
      <Helmet>
        <title>Organization setup | Paragonix</title>
      </Helmet>

      <div className="space-y-5">
        <div>
          <OrganizationDetailsCard organization={organization} />
        </div>

        <div>
          <Card title="Admins" className="3xl:col-span-12">
            <PreselectedMembersTable members={all.admins} />
          </Card>
        </div>

        {all.members != null && all.members.length > 0 && (
          <div>
            <Card title="Confirm members" className="3xl:col-span-12">
              <PreselectedMembersTable members={all.members} onSelectedMembers={members => setSelectedMembers(members)} />
            </Card>
          </div>
        )}

        <div>
          <Card className="3xl:col-span-12">
            <div className="flex items-center justify-between">
              <div>
                <div className="flex items-center">
                  <input
                    id="confirmData"
                    name="confirmData"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 focus:ring-blue-500"
                    checked={confirmCheck}
                    onChange={() => setConfirmCheck(!confirmCheck)}
                  />
                  <label htmlFor="confirmData" className="text-dark ml-2 block font-medium">
                    I confirm that all data is correct and I want to activate the organization.
                  </label>
                </div>
              </div>
              <div>
                <Button onClick={onConfirmOrganization} isLoading={isActivationLoading} disabled={!confirmCheck}>
                  Activate organization
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </AppContent>
  );
};

export default OrganizationSetup;
