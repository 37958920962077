import { Assets } from '@packages/assets';
import { Routes } from '@packages/constants';
import { ProductUtil } from '@packages/firebase';
import { MdMenu } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useSession } from '../../../contexts/useSession';
import { HeaderDropdown } from './HeaderDropdown';

type HeaderProps = {
  toggleSidebar: () => void;
};

export default function Header({ toggleSidebar }: HeaderProps) {
  const { currentSession, color } = useSession();

  return (
    <div className="fixed left-0 right-0 top-0 z-20 flex h-[70px] items-center justify-between bg-white pr-5  shadow-sm lg:left-[250px] lg:px-10">
      <div className="flex items-center">
        <div className="flex h-[70px] w-[70px] items-center justify-center bg-[#12182c] lg:hidden">
          <Link to={Routes.web.DASHBOARD}>
            <img className="h-[50px] w-auto" src={Assets.logo.paragonixLogoSquareDarkPng} alt="Workflow" />
          </Link>
        </div>

        <button type="button" onClick={() => toggleSidebar()} className="mx-4 lg:hidden">
          <MdMenu className="h-8 w-8" />
        </button>

        {currentSession?.product && (
          <div className="text-3xl" style={{ color: color?.primary }}>
            {ProductUtil.toHeading(currentSession?.product)}
          </div>
        )}
      </div>

      <div>
        <HeaderDropdown />
      </div>
    </div>
  );
}
