interface AvatarProps {
  firstname?: string;
  lastname?: string;
}

export default function Avatar({ firstname, lastname }: AvatarProps) {
  return (
    <span className="inline-flex w-[32px] h-[32px] items-center justify-center rounded-full bg-[lightgray]">
      <span className="font-[500] leading-none text-black">
        {firstname?.charAt(0)}
        {lastname?.charAt(0)}
      </span>
    </span>
  );
}
