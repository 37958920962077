import { Assets } from '@packages/assets';
import { useState } from 'react';
import withUser from '../components/HOC/withUser';
import EmptyLayout from '../components/Layouts/EmptyLayout';
import Button from '../components/Shared/Button';
import Card from '../components/Shared/Card';
import { useAuthUser } from '../contexts/useAuthUser';
import { logout } from '../lib/firebase/auth';
import { sendEmailVerification } from '../lib/firebase/functions';

const VerifyEmailNotice = () => {
  const { authUser } = useAuthUser();

  const [isSending, setIsSending] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  async function onSendAgain() {
    setIsSending(true);
    setIsDisabled(true);

    try {
      await sendEmailVerification();
    } finally {
      setIsSending(false);

      setTimeout(() => {
        setIsDisabled(false);
      }, 30000);
    }
  }

  return (
    <EmptyLayout title="Verify Email | Paragonix">
      <div className="mx-auto max-w-2xl px-6 py-12">
        <div className="flex justify-center pb-8">
          <img src={Assets.logo.paragonixLogoLightPng} style={{ height: '18px' }} alt="" />
        </div>

        <Card title="Please Confirm Your Email">
          <div className="mb-6 space-y-2.5">
            <p className="text-muted">
              We just sent a verification email to <strong>{authUser?.email ?? 'your inbox'}</strong>.
            </p>

            <p className="text-muted">Please check your inbox and click on the link in the email to confirm your email address.</p>
          </div>

          <div className="flex justify-between space-x-2">
            <Button variant="outline" onClick={() => logout()}>
              Logout
            </Button>

            <Button variant="primary" onClick={() => onSendAgain()} isLoading={isSending} disabled={isDisabled}>
              Send again
            </Button>
          </div>
        </Card>
      </div>
    </EmptyLayout>
  );
};

export default withUser(VerifyEmailNotice);
