import { OrganType } from '@packages/firebase';
import OrganTypeBadge from '../../Badges/OrganTypeBadge';

function organFormatter({ value }: { value: OrganType }) {
  return (
    <div className="pr-3 whitespace-nowrap">
      <div className="flex items-center">
        <OrganTypeBadge organ={value} />
      </div>
    </div>
  );
}

export default organFormatter;
