import { useTranslation } from '@packages/translations';
import Card from '../Shared/Card';
import Timeline from '../Shared/Timeline/Timeline';

export default function TimelineTab({ session, timeline }) {
  const { t } = useTranslation();

  return (
    <Card title={t('session_timeline_title')}>
      <Timeline timeline={timeline} session={session} />
    </Card>
  );
}
