import { Session, SessionState } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import { formatTemperature } from '@packages/utils';
import { MdCircle } from 'react-icons/md';
import Card from '../Shared/Card';

type TemperatureDetailsProps = {
  session: Session;
};

export default function TemperatureDetails({ session }: TemperatureDetailsProps) {
  const { t } = useTranslation();

  return (
    <Card title={t('session_temperature_details_title')} centered>
      {session.getState() !== SessionState.FINISHED && session?.loggerData != null ? (
        <TemperatureDetailsLive session={session} />
      ) : (
        <TemperatureDetailsReadings session={session} />
      )}
    </Card>
  );
}

function TemperatureDetailsReadings({ session }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row divide-x text-center pt-6 pb-10">
      <div className="flex-1 py-1">
        <p className="text-[13px] text-muted mb-3">{t('minimum')}</p>
        <p className="text-[19px] font-medium text-dark">
          {session.reportData?.probeMinimum != null ? formatTemperature(session.reportData.probeMinimum) : '-'}
        </p>
      </div>
      <div className="flex-1 py-1">
        <p className="text-[13px] text-muted mb-3">{t('average')}</p>
        <p className="text-[19px] font-medium text-dark">
          {session.reportData?.probeAverage != null ? formatTemperature(session.reportData.probeAverage) : '-'}
        </p>
      </div>
      <div className="flex-1 py-1">
        <p className="text-[13px] text-muted mb-3">{t('maximum')}</p>
        <p className="text-[19px] font-medium text-dark">
          {session.reportData?.probeMaximum != null ? formatTemperature(session.reportData.probeMaximum) : '-'}
        </p>
      </div>
    </div>
  );
}

function TemperatureDetailsLive({ session }: { session: Session }) {
  return (
    <div className="flex flex-row divide-x text-center pt-6 pb-10">
      <div className="flex-1 py-1">
        <p className="text-[13px] text-muted mb-3 inline-flex items-baseline">
          <MdCircle className="text-product mr-1" />
          Probe
        </p>
        <p className="text-[19px] font-medium text-dark">
          {session.loggerData?.probe != null ? formatTemperature(session.loggerData.probe) : '-'}
        </p>
      </div>
      <div className="flex-1 py-1">
        <p className="text-[13px] text-muted mb-3 inline-flex items-baseline">
          <MdCircle className="text-grey-700 mr-1" />
          Ambient
        </p>
        <p className="text-[19px] font-medium text-dark">
          {session.loggerData?.ambient != null ? formatTemperature(session.loggerData.ambient) : '-'}
        </p>
      </div>
    </div>
  );
}
