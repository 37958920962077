import { Flight, FlightState, FlightStateUtil, Session, SessionMember } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import { FormatDateMode, formatDate } from '@packages/utils';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MdAccessTime, MdOutlineAirplanemodeActive, MdOutlineNorthEast, MdOutlineSouthEast } from 'react-icons/md';
import { useAuthUser } from '../../contexts/useAuthUser';
import Badge from '../Shared/Badges/Badge';
import Card from '../Shared/Card';
import { useETACountdown } from './useETACountdown';

type ETAPanelProps = {
  session: Session;
  members: SessionMember[];
};

function ETAPanel({ session, members }: ETAPanelProps) {
  const { t } = useTranslation();

  const flight = useMemo(() => {
    if (session.flightPosition == null) {
      return null;
    }

    if (session.flights == null || session.flights.length === 0) {
      return null;
    }

    const currentFlight = session.flights.find(
      elem => elem.state === FlightState.IN_AIR && elem.flightId === session.flightPosition?.flightId,
    );

    if (currentFlight == null) {
      return null;
    }

    return {
      start: currentFlight.actualDeparture ?? currentFlight.estimatedDeparture ?? currentFlight.plannedDeparture,
      end: currentFlight.actualArrival ?? currentFlight.estimatedArrival ?? currentFlight.plannedArrival,
    };
  }, [session.flightPosition, session.flights]);

  return (
    <div className="w-100">
      <Card title={t('eta_title')}>
        <ETA session={session} members={members} />

        {session.flights != null &&
          session.flights.length > 0 &&
          session.flights.map(elem => (
            <div key={elem.flightId} className="w-100 position-relative border-t pt-3">
              <FlightTracker flight={elem} />
            </div>
          ))}

        {session.eta != null && (
          <div>
            <ProgressBar start={session.createdAt} end={session.eta} flight={flight} />
          </div>
        )}
      </Card>
    </div>
  );
}

function ETA({ session, members }: { session: Session; members: SessionMember[] }) {
  const eta = useETACountdown(session.eta);

  const etaSetBy = useMemo(() => {
    const etaSetByMember = session.etaSetBy != null ? members?.find(member => member.id === session.etaSetBy) : undefined;

    return session.eta != null && etaSetByMember != null ? `by ${etaSetByMember.firstname} ${etaSetByMember.lastname}` : '-';
  }, [members, session.eta, session.etaSetBy]);

  return (
    <div>
      <ul>
        <li className="flex items-center py-4">
          <div className="bg-product-light text-product white flex h-[54px] w-[54px] items-center justify-center rounded">
            <MdAccessTime className="h-7 w-7" />
          </div>

          <div className="ml-4">
            <p className="text-dark text-[16px] font-[500]"> {eta}</p>
            <p className="text-muted text-sm">{etaSetBy}</p>
          </div>
        </li>
      </ul>
    </div>
  );
}

function FlightTracker({ flight }: { flight: Flight }) {
  const title = useMemo(() => {
    if (flight.origin?.name == null && flight.destination?.name == null) {
      return '-';
    }

    return [flight.origin?.name, flight.destination?.name].filter(elem => elem != null).join(' to ');
  }, [flight.destination?.name, flight.origin?.name]);

  return (
    <div>
      <div className="mb-2 flex-grow-0">
        <span className="text-dark p-0 text-[16px] font-[600]">Flight {flight.ident}</span>{' '}
        <Badge>{FlightStateUtil.toUIString(flight.state as FlightState)}</Badge>
      </div>

      <ul>
        <li className="flex items-center py-4">
          <div className="bg-product-light text-product white flex h-[54px] w-[54px] items-center justify-center rounded">
            <MdOutlineAirplanemodeActive className="h-7 w-7 rotate-90" />
          </div>

          <div className="ml-4">
            <p className="text-dark text-[16px] font-[500]"> {title != null && title.length > 0 ? title : '-'}</p>
            <div className="flex flex-row gap-2">
              <FlightTrackerTime
                code={flight.origin?.code}
                planned={flight.plannedDeparture}
                estimated={flight.estimatedDeparture}
                isDeparture
                isPadded
              />

              <FlightTrackerTime
                code={flight.destination?.code}
                planned={flight.plannedArrival}
                estimated={flight.estimatedArrival}
                isDeparture={false}
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

function FlightTrackerTime({ code, planned, estimated, isDeparture = true, isPadded = false }: any) {
  const { user } = useAuthUser();

  const COLORS = {
    isOnTime: '#34c38f',
    isDelayed: '#f46a6a',
  };

  const plannedText = useMemo(
    () =>
      planned != null
        ? formatDate(planned, {
            mode: FormatDateMode.TIME,
            hasTimezone: user.settings.includeTimezone,
            has24Hours: user.settings.force24HoursFormat,
          })
        : null,
    [planned, user?.settings],
  );

  const estimatedText = useMemo(
    () =>
      estimated != null && planned != null && planned.getTime() !== estimated.getTime()
        ? formatDate(estimated, {
            mode: FormatDateMode.TIME,
            hasTimezone: user.settings.includeTimezone,
            has24Hours: user.settings.force24HoursFormat,
          })
        : null,
    [estimated, planned, user?.settings],
  );

  const isDelayed = useMemo(() => estimatedText != null, [estimatedText]);

  if (plannedText == null && estimatedText == null) {
    return null;
  }

  return (
    <span className={classNames(isPadded ? 'mx-2' : '', 'flex flex-row items-center')}>
      <span
        className="mr-1 flex h-[22px] w-[22px] items-center justify-center rounded-full text-white"
        style={{ backgroundColor: isDelayed ? COLORS.isDelayed : COLORS.isOnTime, padding: '1px 3px' }}
      >
        {isDeparture && <MdOutlineNorthEast className="h-4 w-4" />}
        {!isDeparture && <MdOutlineSouthEast className="h-4 w-4" />}
      </span>
      <span>
        <span className="text-muted">{code} </span>
        <span style={{ color: !isDelayed ? COLORS.isOnTime : '#000000', textDecoration: isDelayed ? 'line-through' : 'none' }}>
          {plannedText}
        </span>{' '}
        {isDelayed && <span style={{ color: COLORS.isDelayed }}>{estimatedText}</span>}
      </span>
    </span>
  );
}

function ProgressBar({ start, end, flight }: any) {
  const { user } = useAuthUser();
  const progressPerUnit = useMemo(() => (end.getTime() - start.getTime()) / 100, [end, start]);

  const calcProgress = useCallback(() => (new Date().getTime() - start.getTime()) / progressPerUnit, [start, progressPerUnit]);

  const flightStart = useMemo(
    () =>
      flight?.start != null && flight.start.getTime() > start.getTime() && flight.start.getTime() < end.getTime()
        ? (flight.start.getTime() - start.getTime()) / progressPerUnit
        : null,
    [end, flight, progressPerUnit, start],
  );

  const flightEnd = useMemo(
    () =>
      flight?.end != null && flight.end.getTime() < end.getTime() && flight.end.getTime() > start.getTime()
        ? (flight.end.getTime() - start.getTime()) / progressPerUnit
        : null,
    [end, flight, progressPerUnit, start],
  );

  const [progress, setProgress] = useState(calcProgress());

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(calcProgress());
    }, 1000);

    return () => clearInterval(interval);
  }, [calcProgress]);

  return (
    <div className="z-4 relative w-full border-t pt-6">
      <div className="w-100 bg-product flex justify-start overflow-hidden rounded-sm" style={{ height: '5px' }}>
        <div style={{ width: `${progress}%` }} className="bg-product flex rounded-sm" />

        <div className="bg-product absolute h-[25px] w-[25px] self-center rounded-full" style={{ left: '0%', border: '2px solid white' }} />

        {flight != null && (flightStart != null || flightEnd != null) && (
          <>
            {flightStart != null && (
              <div
                className="bg-product absolute self-center rounded-full"
                style={{
                  height: '15px',
                  width: '15px',
                  right: `${100 - flightStart}%`,
                  border: '2px solid white',
                }}
              />
            )}

            <div
              className="align-center bg-product absolute flex justify-center self-center rounded-full"
              style={{
                height: '25px',
                width: '25px',
                right: `${100 - progress}%`,
                border: '2px solid white',
                zIndex: 1,
              }}
            >
              <div>
                <i className="mdi mdi-airplane mdi-rotate-90 text-white" />
              </div>
            </div>

            {flightEnd != null && (
              <div
                className="align-self-center position-absolute rounded-circle bg-product"
                style={{
                  height: '15px',
                  width: '15px',
                  right: `${100 - flightEnd}%`,
                  border: '2px solid white',
                }}
              />
            )}
          </>
        )}

        <div
          className="bg-product absolute h-[25px] w-[25px] self-center rounded-full"
          style={{ right: '0%', border: '2px solid white' }}
        />
      </div>

      <div className="mt-4 flex w-full justify-between">
        <span className="font-[500]">
          {formatDate(start, {
            mode: FormatDateMode.TIME,
            hasTimezone: user.settings.includeTimezone,
            has24Hours: user.settings.force24HoursFormat,
          })}
        </span>
        <span className="font-[500]">
          {formatDate(end, {
            mode: FormatDateMode.TIME,
            hasTimezone: user.settings.includeTimezone,
            has24Hours: user.settings.force24HoursFormat,
          })}
        </span>
      </div>
    </div>
  );
}

export default ETAPanel;
