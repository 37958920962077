import { Routes } from '@packages/constants';
import { OrganizationMemberStatus, OrganizationStatus } from '@packages/firebase';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { HiUserAdd } from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppContent from '../components/Layouts/AppLayout/Content';
import OrganizationMembersTable from '../components/Organization/Members';
import OrganizationDetailsCard from '../components/Organization/OrganizationDetailsCard';
import Button from '../components/Shared/Button';
import Card from '../components/Shared/Card';
import { useAuthUser } from '../contexts/useAuthUser';
import { useFetchOrganization } from '../lib/firebase/firestore';
import { inviteToOrganization } from '../lib/firebase/functions';
import { isEmail } from '../utils/isEmail';

const OrganizationDetail = () => {
  const navigate = useNavigate();
  const { authUser } = useAuthUser();
  const { organizationId } = useParams();

  const { organization, members, unregisteredInvites, isAdmin, isLoading } = useFetchOrganization(organizationId as string);
  const [isInviting, setIsInviting] = useState(false);

  async function handleInviteMember() {
    const email = prompt('Enter email address of the member you want to invite');

    if (email == null || email.length === 0) {
      return;
    }

    if (isEmail(email) === false) {
      alert('Invalid email address');
      return;
    }

    if (members?.some(member => member.email === email)) {
      alert('Member already exists');
      return;
    }

    setIsInviting(true);

    try {
      await inviteToOrganization(organizationId as string, email);

      toast.success(`Invited ${email} to organization.`, {
        autoClose: 3000,
      });
    } catch (err: any) {
      toast.error(`Unable to invite ${email} to organization: ${err.message}`);
    } finally {
      setIsInviting(false);
    }
  }

  if (organization == null || isLoading) {
    return null;
  }

  if (organization.status !== OrganizationStatus.ACTIVE) {
    navigate(Routes.web.DASHBOARD);
    return null;
  }

  if (!members?.some(member => member.id === authUser?.uid && member.status === OrganizationMemberStatus.ACTIVE)) {
    navigate(Routes.web.DASHBOARD);
    return null;
  }

  return (
    <AppContent>
      <Helmet>
        <title>Organization | Paragonix</title>
      </Helmet>

      <div className="space-y-5">
        <div>
          <OrganizationDetailsCard organization={organization} />
        </div>

        <div>
          <Card className="3xl:col-span-12">
            <div className="space-y-5">
              <div className="flex justify-between">
                <p className="text-dark mb-4 text-[16px] font-[600]">Members</p>

                {isAdmin && (
                  <Button onClick={() => handleInviteMember()} isLoading={isInviting} Icon={HiUserAdd}>
                    Invite Member
                  </Button>
                )}
              </div>

              <OrganizationMembersTable
                organization={organization}
                members={members ?? []}
                unregisteredInvites={unregisteredInvites ?? []}
                isAdmin={isAdmin}
              />
            </div>
          </Card>
        </div>
      </div>
    </AppContent>
  );
};

export default OrganizationDetail;
