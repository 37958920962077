import avatarFormatter from './avatar.formatter';
import booleanFormatter from './boolean.formatter';
import datetimeFormatter from './datetime.formatter';
import donationTypeFormatter from './donationType.formatter';
import joinKeyFormatter from './joinKey.formatter';
import organFormatter from './organ.formatter';
import sessionTypeFormatter from './sessionType.formatter';
import stateFormatter from './state.formatter';
import temperatureFormatter from './temperature.formatter';
import viewDetailsFormatter from './viewDetails.formatter';

export default {
  organ: organFormatter,
  donationType: donationTypeFormatter,
  sessionType: sessionTypeFormatter,
  datetime: datetimeFormatter,
  boolean: booleanFormatter,
  sessionState: stateFormatter,
  avatar: avatarFormatter,
  temperature: temperatureFormatter,
  viewDetails: viewDetailsFormatter,
  joinKey: joinKeyFormatter,
};
