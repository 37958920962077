import { Menu, Transition } from '@headlessui/react';
import { Session } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import { useState } from 'react';
import { MdDownload } from 'react-icons/md';
import { Row } from 'react-table';
import { toast } from 'react-toastify';
import Badge from '../../Badges/Badge';
import Button from '../../Button';
import { exportSessionReports, exportSessionsTable } from './exportHandlers';

export default function ExportSelect({ selectedRows }: { selectedRows: Row[] }) {
  const { t } = useTranslation();
  const [isExporting, setIsExporting] = useState(false);

  async function handleDataExport() {
    const sessions = selectedRows.map(r => new Session(r.original as Session));
    if (sessions.length < 1) return;
    setIsExporting(true);

    try {
      const downloaded = await exportSessionReports(sessions, t);

      toast.success(<span>{t('export_success_message').replace('%s', downloaded.toString())}</span>);
    } finally {
      setIsExporting(false);
    }
  }

  async function handleTableExport() {
    const sessions = selectedRows.map(r => new Session(r.original as Session));
    if (sessions.length < 1) return;

    setIsExporting(true);

    try {
      await exportSessionsTable(sessions, t);

      toast.success(<span>Exported sessions table successfully with {sessions.length} sessions!</span>);
    } finally {
      setIsExporting(false);
    }
  }

  return (
    <div className="relative">
      <Menu>
        <Menu.Button as="div" aria-disabled={selectedRows?.length === 0}>
          <Button variant="outline" Icon={MdDownload} isLoading={isExporting} disabled={selectedRows?.length === 0}>
            Downloads
            {selectedRows?.length > 0 && (
              <span className="ml-2">
                <Badge>{selectedRows.length}</Badge>
              </span>
            )}
          </Button>
        </Menu.Button>
        <Transition leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Menu.Items className="absolute z-10 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 min-w-max ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              <button
                onClick={() => handleDataExport()}
                type="button"
                className="block w-full relative cursor-pointer select-none py-2 px-4 hover:bg-[#eff2f7] hover:text-black"
              >
                {t('export')}
              </button>
            </Menu.Item>
            <Menu.Item>
              <button
                onClick={() => handleTableExport()}
                type="button"
                className="block w-full relative cursor-pointer select-none py-2 px-4 hover:bg-[#eff2f7] hover:text-black"
              >
                {t('export_sessions_table')}
              </button>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
