import { Tab } from '@headlessui/react';
import { generateSessionMetrics, ProductType, ProductUtil } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import { formatDistance, formatPressure, formatTemperature } from '@packages/utils';
import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { MdAccessTime, MdArrowForward, MdOutlineChevronRight, MdOutlineFormatListBulleted, MdThermostat } from 'react-icons/md';
import { WiBarometer } from 'react-icons/wi';
import { useAuthUser } from '../contexts/useAuthUser';
import { useFetchSessions } from '../lib/firebase/firestore';

export default function ProductDetails() {
  const { t } = useTranslation();
  const { organization } = useAuthUser();

  const [sessions, areSessionsLoading] = useFetchSessions({ organizationId: organization?.id, finished: true });

  const [metrics, setMetrics] = useState<ReturnType<typeof generateSessionMetrics> | undefined>();

  useEffect(() => {
    if (areSessionsLoading) {
      return;
    }

    if (sessions == null || sessions.length === 0) {
      return;
    }

    setMetrics(generateSessionMetrics(sessions));
  }, [sessions]);

  if (areSessionsLoading) {
    return null;
  }

  return (
    <Tab.Group>
      <div className="border-t md:flex md:flex-row md:divide-x">
        <Tab.List className="md:w-1/3">
          <div className="flex w-full flex-row items-center overflow-scroll border-b py-4 md:flex-col md:justify-center md:divide-y md:py-0">
            <SessionTabsPill>
              <div className="hidden h-[80px] w-[93px] items-center justify-center px-2 md:flex">
                <MdOutlineFormatListBulleted className="h-12 w-12" />
              </div>
              <span>{t('product_metrics_all_title')}</span>
            </SessionTabsPill>
            <SessionTabsPill className="lungguard-theme">
              <div className="hidden px-2 md:block">
                <img src={ProductUtil.imagePath(ProductType.LUNGGUARD)} alt="" style={{ width: '80px' }} />
              </div>
              <span className="h5 m-0 p-0">{ProductUtil.toHeading(ProductType.LUNGGUARD)}</span>
            </SessionTabsPill>
            <SessionTabsPill className="lungguard-theme">
              <div className="hidden px-2 md:block">
                <img src={ProductUtil.imagePath(ProductType.BAROGUARD)} alt="" style={{ width: '80px' }} />
              </div>
              <span className="h5 m-0 p-0">{ProductUtil.toHeading(ProductType.BAROGUARD)}</span>
            </SessionTabsPill>
            <SessionTabsPill className="sherpapak-theme">
              <div className="hidden px-2 md:block">
                <img src={ProductUtil.imagePath(ProductType.SHERPAPAK)} alt="" style={{ width: '80px' }} />
              </div>
              <span className="h5 m-0 p-0">{ProductUtil.toHeading(ProductType.SHERPAPAK)}</span>
            </SessionTabsPill>
            <SessionTabsPill className="liverguard-theme">
              <div className="hidden px-2 md:block">
                <img src={ProductUtil.imagePath(ProductType.LIVERGUARD)} alt="" style={{ width: '80px' }} />
              </div>
              <span className="h5 m-0 p-0">{ProductUtil.toHeading(ProductType.LIVERGUARD)}</span>
            </SessionTabsPill>
            {/* <SessionTabsPill className="kts-theme">
              <div className="hidden px-2 md:block">
                <img src={ProductUtil.imagePath(ProductType.KTS)} alt="" style={{ width: '80px' }} />
              </div>
              <span className="p-0 m-0 h5">{ProductUtil.toHeading(ProductType.KTS)}</span>
            </SessionTabsPill> */}
            <SessionTabsPill className="vantagepoint-theme">
              <div className="hidden px-2 md:block">
                <img src={ProductUtil.imagePath(ProductType.VANTAGEPOINT)} alt="" style={{ width: '80px' }} />
              </div>
              <span className="h5 m-0 p-0">{ProductUtil.toHeading(ProductType.VANTAGEPOINT)}</span>
            </SessionTabsPill>
          </div>
        </Tab.List>

        <Tab.Panels className="md:w-2/3">
          {metrics?.all && (
            <div className="">
              <Tab.Panel className="divide-y">
                <MetricRow
                  name={t('product_metrics_temperature')}
                  Icon={MdThermostat}
                  min={formatTemperature(metrics.all.temperature.min)}
                  avg={formatTemperature(metrics.all.temperature.avg)}
                  max={formatTemperature(metrics.all.temperature.max)}
                />
                <MetricRow
                  name={t('product_metrics_distance')}
                  Icon={MdArrowForward}
                  min={formatDistance(metrics.all.distance.min)}
                  avg={formatDistance(metrics.all.distance.avg)}
                  max={formatDistance(metrics.all.distance.max)}
                />
                <MetricRow
                  name={t('product_metrics_ischemic_time')}
                  Icon={MdAccessTime}
                  min={metrics.all.ischemicTime.min}
                  avg={metrics.all.ischemicTime.avg}
                  max={metrics.all.ischemicTime.max}
                />
              </Tab.Panel>

              <Tab.Panel className="lungguard-theme divide-y">
                <MetricRow
                  name={t('product_metrics_temperature')}
                  Icon={MdThermostat}
                  min={formatTemperature(metrics.lungguard.temperature.min)}
                  avg={formatTemperature(metrics.lungguard.temperature.avg)}
                  max={formatTemperature(metrics.lungguard.temperature.max)}
                />
                <MetricRow
                  name={t('product_metrics_distance')}
                  Icon={MdArrowForward}
                  min={formatDistance(metrics.lungguard.distance.min)}
                  avg={formatDistance(metrics.lungguard.distance.avg)}
                  max={formatDistance(metrics.lungguard.distance.max)}
                />
                <MetricRow
                  name={t('product_metrics_ischemic_time')}
                  Icon={MdAccessTime}
                  min={metrics.lungguard.ischemicTime.min}
                  avg={metrics.lungguard.ischemicTime.avg}
                  max={metrics.lungguard.ischemicTime.max}
                />
              </Tab.Panel>

              <Tab.Panel className="lungguard-theme divide-y">
                <MetricRow
                  name={t('product_metrics_temperature')}
                  Icon={MdThermostat}
                  min={formatTemperature(metrics.baroguard.temperature.min)}
                  avg={formatTemperature(metrics.baroguard.temperature.avg)}
                  max={formatTemperature(metrics.baroguard.temperature.max)}
                />
                <MetricRow
                  name="Pressure"
                  Icon={WiBarometer}
                  min={formatPressure(metrics.baroguard.pressure.min)}
                  avg={formatPressure(metrics.baroguard.pressure.avg)}
                  max={formatPressure(metrics.baroguard.pressure.max)}
                />
                <MetricRow
                  name={t('product_metrics_distance')}
                  Icon={MdArrowForward}
                  min={formatDistance(metrics.baroguard.distance.min)}
                  avg={formatDistance(metrics.baroguard.distance.avg)}
                  max={formatDistance(metrics.baroguard.distance.max)}
                />
                <MetricRow
                  name={t('product_metrics_ischemic_time')}
                  Icon={MdAccessTime}
                  min={metrics.baroguard.ischemicTime.min}
                  avg={metrics.baroguard.ischemicTime.avg}
                  max={metrics.baroguard.ischemicTime.max}
                />
              </Tab.Panel>

              <Tab.Panel className="sherpapak-theme divide-y">
                <MetricRow
                  name={t('product_metrics_temperature')}
                  Icon={MdThermostat}
                  min={formatTemperature(metrics.sherpapak.temperature.min)}
                  avg={formatTemperature(metrics.sherpapak.temperature.avg)}
                  max={formatTemperature(metrics.sherpapak.temperature.max)}
                />
                <MetricRow
                  name={t('product_metrics_distance')}
                  Icon={MdArrowForward}
                  min={formatDistance(metrics.sherpapak.distance.min)}
                  avg={formatDistance(metrics.sherpapak.distance.avg)}
                  max={formatDistance(metrics.sherpapak.distance.max)}
                />
                <MetricRow
                  name={t('product_metrics_ischemic_time')}
                  Icon={MdAccessTime}
                  min={metrics.sherpapak.ischemicTime.min}
                  avg={metrics.sherpapak.ischemicTime.avg}
                  max={metrics.sherpapak.ischemicTime.max}
                />
              </Tab.Panel>

              <Tab.Panel className="liverguard-theme divide-y">
                <MetricRow
                  name={t('product_metrics_temperature')}
                  Icon={MdThermostat}
                  min={formatTemperature(metrics.liverguard.temperature.min)}
                  avg={formatTemperature(metrics.liverguard.temperature.avg)}
                  max={formatTemperature(metrics.liverguard.temperature.max)}
                />
                <MetricRow
                  name={t('product_metrics_distance')}
                  Icon={MdArrowForward}
                  min={formatDistance(metrics.liverguard.distance.min)}
                  avg={formatDistance(metrics.liverguard.distance.avg)}
                  max={formatDistance(metrics.liverguard.distance.max)}
                />
                <MetricRow
                  name={t('product_metrics_ischemic_time')}
                  Icon={MdAccessTime}
                  min={metrics.liverguard.ischemicTime.min}
                  avg={metrics.liverguard.ischemicTime.avg}
                  max={metrics.liverguard.ischemicTime.max}
                />
              </Tab.Panel>

              {/* <Tab.Panel className="divide-y kts-theme">
                <MetricRow
                  name={t('product_metrics_temperature')}
                  Icon={MdThermostat}
                  min={formatTemperature(metrics.kts.temperature.min)}
                  avg={formatTemperature(metrics.kts.temperature.avg)}
                  max={formatTemperature(metrics.kts.temperature.max)}
                />
                <MetricRow
                  name={t('product_metrics_distance')}
                  Icon={MdArrowForward}
                  min={formatDistance(metrics.kts.distance.min)}
                  avg={formatDistance(metrics.kts.distance.avg)}
                  max={formatDistance(metrics.kts.distance.max)}
                />
                <MetricRow
                  name={t('product_metrics_ischemic_time')}
                  Icon={MdAccessTime}
                  min={metrics.kts.ischemicTime.min}
                  avg={metrics.kts.ischemicTime.avg}
                  max={metrics.kts.ischemicTime.max}
                />
              </Tab.Panel> */}

              <Tab.Panel className="vantagepoint-theme divide-y">
                <MetricRow
                  name={t('product_metrics_temperature')}
                  Icon={MdThermostat}
                  min={formatTemperature(metrics.vantagepoint.temperature.min)}
                  avg={formatTemperature(metrics.vantagepoint.temperature.avg)}
                  max={formatTemperature(metrics.vantagepoint.temperature.max)}
                />
                <MetricRow
                  name={t('product_metrics_distance')}
                  Icon={MdArrowForward}
                  min={formatDistance(metrics.vantagepoint.distance.min)}
                  avg={formatDistance(metrics.vantagepoint.distance.avg)}
                  max={formatDistance(metrics.vantagepoint.distance.max)}
                />
                <MetricRow
                  name={t('product_metrics_ischemic_time')}
                  Icon={MdAccessTime}
                  min={metrics.vantagepoint.ischemicTime.min}
                  avg={metrics.vantagepoint.ischemicTime.avg}
                  max={metrics.vantagepoint.ischemicTime.max}
                />
              </Tab.Panel>
            </div>
          )}
        </Tab.Panels>
      </div>
    </Tab.Group>
  );
}

function SessionTabsPill({ className = '', children }) {
  return (
    <Tab as={Fragment}>
      {({ selected }) => (
        <button
          type="button"
          className={classNames(
            className,
            { 'md:bg-product-light md:border-l-product bg-black text-white md:border-l-4 md:text-black': selected },
            { 'bg-transparent text-black md:border-l-4 md:border-l-transparent': !selected },
            'test-base mx-3 flex shrink-0 items-center rounded p-2 font-[500] outline-none md:w-full md:rounded-none md:text-xl',
          )}
        >
          <div className="flex w-full items-center justify-between px-4">
            <div className="flex flex-row items-center">{children}</div>

            <div className="hidden xl:inline">
              <MdOutlineChevronRight className="h-5 w-5" />
            </div>
          </div>
        </button>
      )}
    </Tab>
  );
}

function MetricRow({ name, Icon, min, max, avg }) {
  const { t } = useTranslation();

  return (
    <div className="py-5 xl:flex xl:flex-row xl:justify-between">
      <div className="flex flex-col items-center xl:w-1/4">
        <div className="bg-grey-100 bg-product-light rounded-full p-6">
          <Icon className="text-product h-8 w-8" />
        </div>

        <span className="mt-3 text-[14px] font-[500]">{name}</span>
      </div>

      <div className="flex flex-row pt-6 pb-10 text-center xl:flex-1">
        <div className="flex-1 py-1">
          <p className="text-muted mb-3 text-[13px]">{t('minimum')}</p>
          <p className="text-dark text-[19px] font-medium">{min}</p>
        </div>
        <div className="flex-1 py-1">
          <p className="text-muted mb-3 text-[13px]">{t('average')}</p>
          <p className="text-dark text-[19px] font-medium">{avg}</p>
        </div>
        <div className="flex-1 py-1">
          <p className="text-muted mb-3 text-[13px]">{t('maximum')}</p>
          <p className="text-dark text-[19px] font-medium">{max}</p>
        </div>
      </div>
    </div>
  );
}
