import { Dialog, Transition } from '@headlessui/react';
import { FC, Fragment } from 'react';
import { BaseSizes } from '../../types';

export type ModalSizes = BaseSizes | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl';

export type ModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  size?: ModalSizes;
};

const sizeClassBySize = {
  xs: 'max-w-xs',
  sm: 'max-w-sm',
  md: 'max-w-md',
  lg: 'max-w-lg',
  xl: 'max-w-xl',
  '2xl': 'max-w-2xl',
  '3xl': 'max-w-3xl',
  '4xl': 'max-w-4xl',
  '5xl': 'max-w-5xl',
  '6xl': 'max-w-6xl',
  '7xl': 'max-w-7xl',
};

const Modal: FC<ModalProps> = ({ isOpen, setIsOpen, size = 'md', children }) => {
  function closeModal() {
    setIsOpen(false);
  }

  const sizeClasses = sizeClassBySize[size];

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => closeModal()}>
        <div
          className="fixed inset-0 bg-black/30"
          aria-hidden="true"
          onClick={() => {
            closeModal();
          }}
        />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className={`${sizeClasses} w-full overflow-hidden transition-all transform bg-white rounded-lg shadow-xl`}>
                <Dialog.Panel>{children}</Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
