import { useLayoutEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import withUser from '../components/HOC/withUser';
import AppLayout from '../components/Layouts/AppLayout';
import { useAuthUser } from '../contexts/useAuthUser';

const AuthOutlet = () => {
  const { user, authUser } = useAuthUser();
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return <AppLayout>{user && authUser && <Outlet />}</AppLayout>;
};

export default withUser(AuthOutlet);
