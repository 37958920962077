import { detectEnvironment } from './detectEnvironment';

export function appStoreRedirect() {
  const environment = detectEnvironment();

  if (environment === 'ios') {
    const confirm = window.confirm(
      'You are about to be redirected to the App Store. Click OK to continue or Cancel to stay on the current page.',
    );

    if (confirm) {
      window.location.href = 'https://apps.apple.com/app/paragonix/id1444033711';
    }
  }

  if (environment === 'android') {
    const confirm = window.confirm(
      'You are about to be redirected to the Play Store. Click OK to continue or Cancel to stay on the current page.',
    );

    if (confirm) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.paragonixtechnologies.paragonix';
    }
  }
}
