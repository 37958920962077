import { ProductUtil } from '@packages/firebase';
import { getProductDocuments, getProductTutorials } from '../../lib/firebase/firestore';
import SupportTabs from '../Support/SupportTabs';

export default function SupportTab({ session }) {
  const [documents] = getProductDocuments(ProductUtil.toId(session.product));
  const [tutorials] = getProductTutorials(ProductUtil.toId(session.product));

  const title = ProductUtil.toHeading(session.product);
  const colors = ProductUtil.colors(session.product);

  return (
    <SupportTabs
      documents={[
        {
          title,
          itemBgColor: colors.primaryLight,
          itemIconColor: colors.primary,
          items: documents ?? [],
        },
      ]}
      tutorials={[
        {
          title,
          itemBgColor: colors.primaryLight,
          itemIconColor: colors.primary,
          items: tutorials ?? [],
        },
      ]}
    />
  );
}
