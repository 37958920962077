import { Tab as HeadlessTab } from '@headlessui/react';
import { useTranslation } from '@packages/translations';
import classNames from 'classnames';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { MdOutlineDescription, MdHelpOutline, MdOutlinePlayCircleOutline } from 'react-icons/md';
import { getDownloadUrl } from '../../lib/firebase/storage';
import ActionPanel from './ActionPanel';
import DocumentList from './DocumentList';
import VideoList from './VideoList';

interface ItemList {
  title: string;
  itemBgColor: string;
  itemIconColor: string;
  items: DocumentItem[] | TutorialItem[];
}

interface DocumentItem {
  id: string;
  name: string;
  downloadUrl: string;
}

interface TutorialItem {
  id: string;
  name: string;
  videoUrl: string;
}

export default function SupportTabs({ documents, tutorials }) {
  const { t } = useTranslation();

  const [documentList, setDocumentList] = useState<ItemList[]>([]);
  const [tutorialList, setTutorialList] = useState<ItemList[]>([]);

  useEffect(() => {
    async function set() {
      const result: ItemList[] = [];

      for (const document of documents ?? []) {
        result.push({
          ...document,
          /* eslint-disable no-await-in-loop */
          items: await Promise.all(
            (document?.items ?? [])
              .sort((a, b) => a.sort - b.sort)
              .map(async item => ({
                id: item.id,
                name: item.name,
                downloadUrl: await getDownloadUrl(item.storageRef),
              })),
          ),
        });
      }

      setDocumentList(result);
    }

    set();
  }, [documents]);

  useEffect(() => {
    async function set() {
      const result: ItemList[] = [];

      for (const tutorial of tutorials ?? []) {
        result.push({
          ...tutorial,
          /* eslint-disable no-await-in-loop */
          items: await Promise.all(
            (tutorial?.items ?? [])
              .sort((a, b) => a.sort - b.sort)
              .map(async item => ({
                id: item.id,
                name: item.title,
                videoUrl: await getDownloadUrl(item.videoRefName),
              })),
          ),
        });
      }

      setTutorialList(result);
    }

    set();
  }, [tutorials]);

  return (
    <HeadlessTab.Group vertical>
      <div className="grid grid-cols-3 xl:grid-cols-12 gap-6">
        <div className="col-span-3 xl:col-span-2">
          <HeadlessTab.List className="grid grid-cols-3 gap-6 xl:grid-cols-none xl:grid-rows-3">
            <Tab>
              <div className="flex items-center justify-center flex-col py-3 font-[500]">
                <MdHelpOutline className="mb-3 h-10 w-10" />
                {t('support')}
              </div>
            </Tab>

            {documentList.length > 0 && documentList.some(list => list.items.length > 0) && (
              <Tab>
                <div className="flex items-center justify-center flex-col py-3 font-[500]">
                  <MdOutlineDescription className="mb-3 h-10 w-10" />
                  {t('documents')}
                </div>
              </Tab>
            )}
            {tutorialList.length > 0 && tutorialList.some(list => list.items.length > 0) && (
              <Tab>
                <div className="flex items-center justify-center flex-col py-3 font-[500]">
                  <MdOutlinePlayCircleOutline className="mb-3 h-10 w-10" />
                  {t('tutorials')}
                </div>
              </Tab>
            )}
          </HeadlessTab.List>
        </div>
        <div className="col-span-3 xl:col-span-10 grid ">
          <HeadlessTab.Panels>
            <HeadlessTab.Panel className="grid gap-5">
              <ActionPanel
                title={t('email_support')}
                text={t('support_mail_info')}
                actions={{ email: true }}
                actionData={{ email: { email: t('support_clinical_us_email') } }}
              />
              <ActionPanel
                title={t('call_support')}
                text={t('support_hotline_info')}
                actions={{ callSupport: true }}
                actionData={{ callSupport: { phonenumber: t('support_clinical_us_number') } }}
              />
            </HeadlessTab.Panel>
            {documentList.length > 0 && (
              <HeadlessTab.Panel className="flex flex-col gap-5">
                {documentList.map((list, index) => {
                  return list.items.length > 0 ? (
                    <DocumentList
                      key={`document-${index}`}
                      title={list.title}
                      items={list.items as DocumentItem[]}
                      options={{
                        itemBgColor: list.itemBgColor,
                        itemIconColor: list.itemIconColor,
                      }}
                    />
                  ) : null;
                })}
              </HeadlessTab.Panel>
            )}
            {tutorialList.length > 0 && (
              <HeadlessTab.Panel className="flex flex-col gap-5">
                {tutorialList.map((list, index) => {
                  return list.items.length > 0 ? (
                    <VideoList
                      key={`tutorial-${index}`}
                      title={list.title}
                      items={list.items as TutorialItem[]}
                      options={{ itemBgColor: list.itemBgColor, itemIconColor: list.itemIconColor }}
                    />
                  ) : null;
                })}
              </HeadlessTab.Panel>
            )}
          </HeadlessTab.Panels>
        </div>
      </div>
    </HeadlessTab.Group>
  );
}

function Tab({ children }: { children: ReactNode }) {
  return (
    <HeadlessTab as={Fragment}>
      {({ selected }) => (
        <div
          className={classNames(
            { 'bg-white text-black': !selected, 'bg-black text-white': selected },
            'w-full rounded px-3 py-6 text-center outline-none cursor-pointer',
          )}
        >
          {children}
        </div>
      )}
    </HeadlessTab>
  );
}
