import { Routes } from '@packages/constants';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import withUser from '../components/HOC/withUser';
import EmptyLayout from '../components/Layouts/EmptyLayout';
import Button from '../components/Shared/Button';
import Card from '../components/Shared/Card';
import { useAuthUser } from '../contexts/useAuthUser';

const DeleteAccount = () => {
  const navigate = useNavigate();
  const { authUser, isLoading: isUserLoading } = useAuthUser();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const formik = useFormik({
    initialValues: {
      validation: '',
    },
    validationSchema: yup.object().shape({
      validation: yup.string().required(),
    }),
    onSubmit: async values => {
      setError(false);
      setIsLoading(true);

      if (values.validation !== 'DELETE') {
        setIsLoading(false);
        return;
      }

      try {
        await authUser.delete();
      } catch (err) {
        setError(true);
        setIsLoading(false);
      }
    },
  });

  if (authUser == null || isUserLoading) {
    return null;
  }

  return (
    <EmptyLayout title="Delete Account | Paragonix">
      <div className="mx-auto max-w-xl px-6 py-12">
        <Card title="Delete Account">
          <form onSubmit={formik.handleSubmit} className="space-y-6">
            <div className="space-y-2.5">
              <p className="text-muted">
                Are you sure you want to delete the account for <strong>{authUser.email}</strong>? Once you delete your account it
                can&apos;t be restored. You&apos;ll lose access to past sessions and all gathered data.
              </p>

              <p className="text-muted">
                If you&apos;re sure you want to delete your account, please enter <strong>DELETE</strong> below and click the
                &quot;Confirm&quot; button.
              </p>

              <input
                id="validation"
                name="validation"
                type="text"
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 pr-10 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                placeholder="DELETE"
                onChange={formik.handleChange}
                value={formik.values.validation}
              />

              {error && (
                <p className="text-danger-500 text-sm">
                  An error occurred while deleting your account. For security reasons, we require a recent sign in before you can delete
                  your account. Please sign out and sign in again, then try again.
                </p>
              )}
            </div>

            <div className="flex justify-end space-x-2">
              <Button onClick={() => navigate(Routes.web.DASHBOARD)}>Cancel</Button>
              <Button variant="danger" type="submit" isLoading={isLoading} disabled={formik.values.validation !== 'DELETE'}>
                Confirm
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </EmptyLayout>
  );
};

export default withUser(DeleteAccount);
