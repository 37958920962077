import { Colors } from '@packages/constants';
import { MdOutlineDescription } from 'react-icons/md';
import Card from '../Shared/Card';

export default function DocumentList({ title, items, options }) {
  return (
    <Card title={title}>
      <ul>
        {items?.length > 0 &&
          items.map(item => (
            <li className="">
              <a key={item.id} href={item.downloadUrl} className="block my-10 px-3 flex items-center" target="_blank" rel="noreferrer">
                <div
                  className="rounded flex items-center justify-center mr-5"
                  style={{ backgroundColor: options?.itemBgColor ?? Colors.COLOR_RL_BG_DEFAULT, width: '54px', height: '54px' }}
                >
                  <div style={{ color: options?.itemIconColor ?? Colors.COLOR_RL_ICON_DEFAULT }}>
                    <MdOutlineDescription className="h-7 w-7" />
                  </div>
                </div>

                <div>
                  <u className="font-[600] text-[16px] text-dark">{item.name}</u>
                </div>
              </a>
            </li>
          ))}
      </ul>
    </Card>
  );
}
