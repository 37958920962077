import { ProductType, TimelineEntry, TimelineEntryTypeUtil } from '@packages/firebase';
import { timelineEntryIcon } from '@packages/ui';
import { formatDate } from '@packages/utils';
import classNames from 'classnames';
import { MdCircle } from 'react-icons/md';

interface TimelineItemProps {
  event: TimelineEntry;
  product: ProductType;
  withCircle?: boolean;
}

export default function TimelineItem({ event, product, withCircle }: TimelineItemProps) {
  return (
    <div className={classNames({ '-ml-[8px]': withCircle }, 'flex items-center')}>
      {withCircle && <MdCircle className="text-[#495057] mr-5" />}
      <EventIcon type={event.type} />

      <div className="ml-5">
        <p className="font-[500] text-dark text-[16px]">{TimelineEntryTypeUtil.toUIString(event.type, product)}</p>
        <p className="text-muted ">{formatDate(event.timestamp)}</p>
      </div>
    </div>
  );
}

const EventIcon = ({ type }) => {
  const Icon = timelineEntryIcon(type);

  return (
    <div className="rounded h-[54px] w-[54px] bg-product-light text-product flex items-center white justify-center">
      <Icon />
    </div>
  );
};
