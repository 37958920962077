import { SessionState } from '@packages/firebase';

import SessionStateBadge from '../../Badges/SessionStateBadge';
// import ProductBadge from '../../ProductBadge';

function stateFormatter({ value }: { value: SessionState }) {
  return (
    <div className="pr-3 whitespace-nowrap">
      <div className="flex items-center">
        <SessionStateBadge sessionState={value} />
      </div>
    </div>
  );
}

export default stateFormatter;
