// import { NotificationUtil } from '@packages/firebase';
import { NotificationUtil } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import { useEffect, useState } from 'react';
import { MdOutlineNotifications } from 'react-icons/md';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthUser } from '../../../contexts/useAuthUser';
import { getMessagingToken, onFirebaseMessage } from '../../../lib/firebase/messaging';
import Button from '../Button';
import Modal from '../Modal';
// import Button from '../Button';
import NotificationContent from './NotificationContent';

function Notifications() {
  const { authUser } = useAuthUser();
  const { t } = useTranslation();

  const [notify, setNotify] = useState(false);
  const [isNotificationsModalOpen, setNotificationsModal] = useState(false);

  function onAcceptNotifications() {
    localStorage.setItem('allowSessionNotifications', 'TRUE');
    setNotificationsModal(false);

    handleNotifications();
  }

  function onDeclineNotifications() {
    localStorage.setItem('allowSessionNotifications', 'FALSE');
    setNotificationsModal(false);
  }

  async function setToken() {
    let token;
    try {
      token = await getMessagingToken(authUser.uid);
    } catch (err) {
      setNotify(false);
    }

    setNotify(token != null);
  }

  function handleNotifications() {
    const allowSessionNotifications = localStorage.getItem('allowSessionNotifications');

    if (allowSessionNotifications == null) {
      setNotificationsModal(true);
      return;
    }

    if (allowSessionNotifications === 'FALSE') {
      return;
    }

    try {
      setToken();
      initMessageListener();
    } catch (err: any) {
      console.log(err.message);
    }
  }

  function initMessageListener() {
    onFirebaseMessage(payload => {
      if (!notify) {
        return;
      }
      let notification;
      try {
        notification = NotificationUtil.payloadToNotification(payload);
      } catch (err: any) {
        return;
      }
      toast(<NotificationContent notification={notification} />, {
        hideProgressBar: true,
        closeOnClick: false,
        autoClose: 5000,
      });
    });
  }

  useEffect(() => {
    handleNotifications();
  });

  return (
    <>
      <ToastContainer theme="light" />

      <Modal
        size="xl"
        isOpen={isNotificationsModalOpen}
        setIsOpen={value => {
          setNotificationsModal(value);
        }}
      >
        <div className="px-20 pt-5 pb-10 text-center">
          <div className="inline-block mb-4">
            <div className="p-6 bg-gray-100 rounded-full text-dark">
              <MdOutlineNotifications className="w-10 h-10" />
            </div>
          </div>

          <div className="">
            <h4 className="text-black text-[19px] font-[500] mb-4">{t('permission_notifications_title')}</h4>
            <p className="text-muted font-[14px] mb-12">{t('permission_notifications_text')}</p>

            <div>
              <Button
                type="button"
                variant="success"
                className="mx-1"
                onClick={() => {
                  onAcceptNotifications();
                }}
              >
                {t('yes')}
              </Button>
              <Button
                type="button"
                variant="primary"
                className="mx-1"
                onClick={() => {
                  onDeclineNotifications();
                }}
              >
                {t('no')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Notifications;
