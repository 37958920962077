import { useMemo } from 'react';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import { TableInstance } from 'react-table';

export default function SessionTablePagination({ table }: { table: TableInstance }) {
  const from = useMemo(() => table.state.pageSize * table.state.pageIndex + 1, [table.state.pageIndex, table.state.pageSize]);

  const to = useMemo(
    () =>
      table.state.pageSize * table.state.pageIndex + table.state.pageSize < table.rows.length
        ? table.state.pageSize * table.state.pageIndex + table.state.pageSize
        : table.rows.length,
    [table.rows.length, table.state.pageIndex, table.state.pageSize],
  );

  const total = useMemo(() => table.rows.length, [table.rows.length]);
  return (
    <nav className="flex items-center justify-between w-full py-3 bg-transparent">
      <div className="hidden sm:block">
        <p className="text-sm text-gray-400">
          Showing results {from} to {to} of {total}
        </p>
      </div>

      <div className="flex justify-between flex-1 space-x-6 sm:justify-end">
        <div className="invisible space-x-3">
          <span className="text-sm text-gray-400">Number of results</span>
          <select
            className="py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            value={table.pageSize}
            onChange={event => {
              table.setPageSize(Number(event.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>

        <div className="flex items-center space-x-4">
          <button
            type="button"
            className="relative inline-flex items-center text-gray-500 disabled:text-gray-300"
            disabled={!table.canPreviousPage}
            onClick={() => table.previousPage()}
          >
            <HiOutlineChevronLeft className="h-6" />
          </button>
          <button
            type="button"
            className="relative inline-flex items-center text-gray-500 disabled:text-gray-300"
            disabled={!table.canNextPage}
            onClick={() => table.nextPage()}
          >
            <HiOutlineChevronRight className="h-6" />
          </button>
        </div>
      </div>
    </nav>
  );
}
