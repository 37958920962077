import { Menu } from '@headlessui/react';
import {
  Organization,
  OrganizationMember,
  OrganizationMemberAccessRole,
  OrganizationMemberAccessRoleUtil,
  OrganizationMemberStatus,
  OrganizationMemberStatusUtil,
  OrganizationStatus,
  OrganizationUnregisteredInvite,
} from '@packages/firebase';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import { useRowSelect, useTable } from 'react-table';
import { toast } from 'react-toastify';
import { useAuthUser } from '../../../contexts/useAuthUser';
import { changeOrganizationUserAccessRole, deleteOrganizationUnregisteredInvite } from '../../../lib/firebase/firestore';
import {
  activateOrganizationMember,
  deactivateOrganizationMember,
  grantOrganizationAccess,
  sendOrganizationMemberInvite,
} from '../../../lib/firebase/functions';

type OrganizationMembersTableProps = {
  organization: Organization;
  members: OrganizationMember[];
  unregisteredInvites: OrganizationUnregisteredInvite[];
  isAdmin?: boolean;
  onSelectedMembers?: (members: any) => void;
};

type UserMenuProps = {
  organizationId: string;
  member?: OrganizationMember;
  unregisterInvite?: OrganizationUnregisteredInvite;
};

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    width: 100,
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: 300,
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Access Role',
    accessor: 'accessRole',
  },
  {
    accessor: 'actions',
  },
];

const OrganizationMembersTable = ({
  organization,
  members,
  unregisteredInvites,
  isAdmin,
  onSelectedMembers,
}: OrganizationMembersTableProps) => {
  const user = useAuthUser();

  const data = useMemo(() => {
    return [
      ...members.map(member => {
        const isYou = user.authUser?.uid === member.id;

        return {
          name: (
            <span>
              {member.fullname} {isYou ? <i>(You)</i> : null}
            </span>
          ),
          email: member.email,
          status: OrganizationMemberStatusUtil.toUIString(member.status),
          accessRole: OrganizationMemberAccessRoleUtil.toUIString(member.accessRole),
          actions:
            organization.status === OrganizationStatus.ACTIVE && !isYou && isAdmin ? (
              <div className="flex justify-end pr-4">
                <UserMenu organizationId={organization.id} member={member} />
              </div>
            ) : null,
        };
      }),
      ...unregisteredInvites.map(invite => {
        return {
          name: <i>Wait for user sign-up</i>,
          email: invite.email,
          status: OrganizationMemberStatusUtil.toUIString(OrganizationMemberStatus.INVITED),
          accessRole: OrganizationMemberAccessRoleUtil.toUIString(OrganizationMemberAccessRole.VIEWER),
          actions:
            organization.status === OrganizationStatus.ACTIVE && isAdmin ? (
              <div className="flex justify-end pr-4">
                <UserMenu organizationId={organization.id} unregisterInvite={invite} />
              </div>
            ) : null,
        };
      }),
    ];
  }, [members, unregisteredInvites, organization, user.authUser?.uid, isAdmin]);

  const table = useTable(
    {
      columns: columns as any,
      data,
    },

    useRowSelect,
  );

  useEffect(() => {
    const selectedRows = table.selectedFlatRows.map(selectedRow => selectedRow.original);

    onSelectedMembers?.(selectedRows);
  }, [table.selectedFlatRows]);

  return (
    <div>
      <div>
        <div>
          <table className="min-w-full">
            <thead className="mb-1 bg-gray-200">
              {table.headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      scope="col"
                      className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-4"
                      {...column.getHeaderProps({
                        style: { minWidth: column.minWidth || 100, width: column.width },
                      })}
                    >
                      <div className="flex items-center space-x-1">
                        <span className="text-base">{column.render('Header')}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody className="bg-white" {...table.getTableBodyProps()}>
              {table.rows.map(row => {
                table.prepareRow(row);

                return (
                  <tr className="border-b border-gray-100 hover:bg-gray-50" {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td className="whitespace-nowrap px-4 py-4 text-gray-500" {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const UserMenu = ({ organizationId, member, unregisterInvite }: UserMenuProps) => {
  const user = useAuthUser();

  const [isLoading, setIsLoading] = useState(false);

  async function handleOnChangeAccessRole(accessRole: OrganizationMemberAccessRole) {
    if (member == null) {
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    const isConfirmed = confirm(
      `Are you sure you want to change this user's access role to ${OrganizationMemberAccessRoleUtil.toUIString(accessRole)}?`,
    );

    if (isConfirmed) {
      setIsLoading(true);

      try {
        await changeOrganizationUserAccessRole(organizationId, member.id, accessRole);

        toast.success(`Changed ${member.fullname}'s access role to ${OrganizationMemberAccessRoleUtil.toUIString(accessRole)}`);
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function handleOnGrantAccess() {
    if (member == null) {
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    const isConfirmed = confirm(
      'Are you sure you want to grant access to this user? This means that the user will be able to start using the organization.',
    );

    if (isConfirmed) {
      setIsLoading(true);

      try {
        await grantOrganizationAccess(organizationId, member.id);

        toast.success(`Granted access to ${member.fullname}`);
      } catch (err: any) {
        toast.error(err.message);
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function handleOnDeactivate() {
    if (member == null) {
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    const isConfirmed = confirm(
      'Are you sure you want to deactivate this user? This means that the user will no longer be able to use the organization.',
    );

    if (isConfirmed) {
      setIsLoading(true);

      try {
        await deactivateOrganizationMember(organizationId, member.id);

        toast.success(`Deactivated ${member.fullname}`);
      } catch (err: any) {
        toast.error(err.message);
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function handleOnActivate() {
    if (member == null) {
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    const isConfirmed = confirm(
      'Are you sure you want to re-activate this user? This means that the user will be able to start using the organization again.',
    );

    if (isConfirmed) {
      setIsLoading(true);

      try {
        await activateOrganizationMember(organizationId, member.id);

        toast.success(`Activated ${member.fullname}`);
      } catch (err: any) {
        toast.error(err.message);
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function handleOnSendInvite() {
    // eslint-disable-next-line no-restricted-globals
    const isConfirmed = confirm(
      'Are you sure you want to send an invite to this user? This means that the user will be able to start using the organization.',
    );

    const id = member?.id ?? unregisterInvite?.id;

    if (id == null) {
      return;
    }

    if (isConfirmed) {
      setIsLoading(true);

      try {
        await sendOrganizationMemberInvite(organizationId, [id]);

        toast.success(`Invite sent`);
      } catch (err: any) {
        toast.error(err.message);
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function handleOnDeleteUnregisteredInvite() {
    if (unregisterInvite == null) {
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    const isConfirmed = confirm('Are you sure you want to delete this invite?');

    if (isConfirmed) {
      setIsLoading(true);

      try {
        await deleteOrganizationUnregisteredInvite(unregisterInvite.id);

        toast.success('Invite deleted');
      } finally {
        setIsLoading(false);
      }
    }
  }

  const show = useMemo(() => {
    return {
      changeAccessRole: member?.status !== OrganizationMemberStatus.INACTIVE,
      grantAccess: member?.status === OrganizationMemberStatus.ACCESS_REQUESTED,
      deactivate: member?.status === OrganizationMemberStatus.ACTIVE && member?.id !== user.authUser?.uid,
      activate: member?.status === OrganizationMemberStatus.INACTIVE,
      sendInvite: member?.status === OrganizationMemberStatus.INVITED || unregisterInvite != null,
      deleteUnregisteredInvite: unregisterInvite != null,
    };
  }, [member]);

  if (Object.values(show).every(value => value === false)) {
    return null;
  }

  return (
    <>
      {isLoading && (
        <div className="absolute inset-0 z-[9999] bg-gray-100 opacity-50">
          <div className="mt-12 flex h-full w-full justify-center">
            <div className="spinner-border inline-block h-7 w-7 animate-spin rounded-full border-2" role="status" />
          </div>
        </div>
      )}

      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 focus:ring-offset-gray-100">
            <HiDotsVertical className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Menu.Items className="absolute right-0 z-[9999] mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {show.grantAccess && (
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-5 py-2 text-left text-sm',
                    )}
                    onClick={handleOnGrantAccess}
                  >
                    Grant access
                  </button>
                )}
              </Menu.Item>
            </div>
          )}

          {show.sendInvite && (
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-5 py-2 text-left text-sm',
                    )}
                    onClick={handleOnSendInvite}
                  >
                    Send invite
                  </button>
                )}
              </Menu.Item>
            </div>
          )}

          {show.deleteUnregisteredInvite && (
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-5 py-2 text-left text-sm',
                    )}
                    onClick={handleOnDeleteUnregisteredInvite}
                  >
                    Delete invite
                  </button>
                )}
              </Menu.Item>
            </div>
          )}

          {show.changeAccessRole && (
            <div className="py-1">
              {member?.accessRole === OrganizationMemberAccessRole.VIEWER && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full px-5 py-2 text-left text-sm',
                      )}
                      onClick={() => handleOnChangeAccessRole(OrganizationMemberAccessRole.ADMIN)}
                    >
                      Promote to admin
                    </button>
                  )}
                </Menu.Item>
              )}

              {member?.accessRole === OrganizationMemberAccessRole.ADMIN && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full px-5 py-2 text-left text-sm',
                      )}
                      onClick={() => handleOnChangeAccessRole(OrganizationMemberAccessRole.VIEWER)}
                    >
                      Demote to viewer
                    </button>
                  )}
                </Menu.Item>
              )}
            </div>
          )}

          {show.activate && (
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-5 py-2 text-left text-sm',
                    )}
                    onClick={handleOnActivate}
                  >
                    Re-activate
                  </button>
                )}
              </Menu.Item>
            </div>
          )}

          {show.deactivate && (
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-5 py-2 text-left text-sm',
                    )}
                    onClick={handleOnDeactivate}
                  >
                    Deactivate
                  </button>
                )}
              </Menu.Item>
            </div>
          )}
        </Menu.Items>
      </Menu>
    </>
  );
};

export default OrganizationMembersTable;
