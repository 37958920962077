function TermsOfUseText() {
  return (
    <>
      <p>Last Updated: 16DEC20</p>
      <p>
        Welcome to the Paragonix App. Please read on to learn the rules and restrictions that govern your use of the Paragonix App,
        including any related websites (the &ldquo;Services&rdquo;). The Paragonix App may be used in connection with other Paragonix
        products including the Paragonix SherpaPak, SherpaCool, LUNGguard, BAROguard, LIVERguard, and/or cardiOvault. You acknowledge and
        agree that all Paragonix products must be purchased separately and the use thereof will be governed by a separate written agreement
        between you and Paragonix. If you have any questions, comments, or concerns regarding these terms or the Services, please contact us
        at:
      </p>
      <p>
        <strong>Email</strong>: support@paragonixtechnologies.com
        <br />
        <strong>Address</strong>: 639 Granite Street, # 408, Braintree, Massachusetts 02184
      </p>
      <p>
        These Terms of Use (the &ldquo;Terms&rdquo;) are a binding contract between you and <strong>PARAGONIX TECHNOLOGIES INC.</strong>{' '}
        (&ldquo;Paragonix,&rdquo; &ldquo;we&rdquo; and &ldquo;us&rdquo;). Your use of the Services in any way means that you agree to all of
        these Terms, and these Terms will remain in effect while you use the Services. These Terms include the provisions in this document
        as well as those in the Paragonix App Privacy Policy (
        <a href="https://paragonixtechnologies.com/legal/" target="_blank" rel="noreferrer">
          https://paragonixtechnologies.com/legal/
        </a>
        ).
      </p>
      <p>
        <strong>Please read these Terms carefully.</strong> They cover important information about Services provided to you.{' '}
        <strong>
          These Terms include information about <a href="#terms-changes">future changes to these Terms</a>,{' '}
          <a href="#limitation-of-liability">limitations of liability</a>,{' '}
          <a href="#arbitration-agreement">a class action waiver and resolution of disputes by arbitration instead of in court</a>. PLEASE
          NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING,
          YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.
        </strong>
      </p>
      <p>
        <strong>ARBITRATION NOTICE AND CLASS ACTION WAIVER:</strong> EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE{' '}
        <a href="#arbitration-agreement">ARBITRATION AGREEMENT SECTION BELOW</a>, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE
        RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE
        ARBITRATION.
      </p>
      <h2 id="terms-changes">
        <u>Will these Terms ever change?</u>
      </h2>
      <p>
        We are constantly trying to improve our Services, so these Terms may need to change along with our Services. We reserve the right to
        change the Terms at any time, but if we do, we will place a notice on our site located at{' '}
        <a href="https://paragonixtechnologies.com" target="_blank" rel="noreferrer">
          https://paragonixtechnologies.com
        </a>
        , send you an email, and/or notify you by some other means.
      </p>
      <p>
        If you don&rsquo;t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able to
        use the Services. If you use the Services in any way after a change to the Terms is effective, that means you agree to all of the
        changes. <br />
      </p>
      <p>
        Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing
        and signed by both you and us.
      </p>
      <h2>
        <u>What about my privacy?</u>
      </h2>
      <p>
        Paragonix takes the privacy of its users very seriously. For the current Paragonix App Privacy Policy, please click here{' '}
        <a href="https://paragonixtechnologies.com/legal/" target="_blank" rel="noreferrer">
          https://paragonixtechnologies.com/legal/
        </a>
        .
      </p>
      <h5>
        <u>Children&rsquo;s Online Privacy Protection Act</u>
      </h5>
      <p>
        The Children&rsquo;s Online Privacy Protection Act (&ldquo;COPPA&rdquo;) requires that online service providers obtain parental
        consent before they knowingly collect personally identifiable information online from children who are under 13 years of age. We do
        not knowingly collect or solicit personally identifiable information from children under 16 years of age; if you are a child under
        16 years of age, please do not attempt to register for or otherwise use the Services or send us any personal information. If we
        learn we have collected personal information from a child under 16 years of age, we will delete that information as quickly as
        possible. If you believe that a child under 16 years of age may have provided us personal information, please contact us at
        support@paragonixtechnologies.com.
      </p>
      <h2>
        <u>What are the basics of using the Paragonix App?</u>
      </h2>
      <p>
        You may be required to sign up for an account, select a password and user name (&ldquo;Paragonix User ID&rdquo;), and provide us
        with certain information or data, such as your contact information. You promise to provide us with accurate, complete, and updated
        registration information about yourself. You may not select as your Paragonix User ID a name that you do not have the right to use,
        or another person&rsquo;s name with the intent to impersonate that person. You may not transfer your account to anyone else without
        our prior written permission.
      </p>
      <p>
        You represent and warrant that you are an individual of legal age to form a binding contract (or if not, you&rsquo;ve received your
        parent&rsquo;s or guardian&rsquo;s permission to use the Services and have gotten your parent or guardian to agree to these Terms on
        your behalf). If you&rsquo;re agreeing to these Terms on behalf of an organization or entity, you represent and warrant that you are
        authorized to agree to these Terms on that organization&rsquo;s or entity&rsquo;s behalf and bind them to these Terms (in which
        case, the references to &ldquo;you&rdquo; and &ldquo;your&rdquo; in these Terms, except for in this sentence, refer to that
        organization or entity).
      </p>
      <p>
        You agree that you will only use the Services in a manner that complies with applicable laws, rules and regulations including,
        without limitation, the Health Insurance Portability &amp; Accountability Act, California Consumer Privacy Act of 2018 (as amended
        and supplemented), and the General Data Protection Regulation (Regulation (EU) 2016/679). <br />
        You will not share your Paragonix User ID, account or password with anyone, and you must protect the security of your Paragonix User
        ID, account, password and any other access tools or credentials. You&rsquo;re responsible for any activity associated with your
        Paragonix User ID and account.
      </p>
      <h5>
        <u>No Medical Advice; Not for Emergencies</u>
      </h5>
      <p>
        Paragonix does not offer medical advice or diagnoses, or engage in the practice of medicine. Our Services are not intended to be a
        substitute for professional medical advice, diagnosis, or treatment and are offered for informational and communicative purposes
        only. The Services are not intended to be, and must not be taken to be, the practice of medicine, nursing, pharmacy or other
        healthcare advice by Paragonix.
      </p>
      <p>
        If you are a Primary Care Provider, physician or other healthcare provider (collectively, &ldquo;PCP&rdquo;), you acknowledge and
        agree that we are not a healthcare provider and that the opinions provided by the Services are not medical diagnoses, treatments,
        prescriptions, or supervision of any kind. Information provided through the Services is not a substitute for treatment by a
        physician or healthcare provider, and you agree that you will not rely on the information provided by the Services in directing
        patient care.&nbsp;You take full responsibility for all treatment decisions relating to your patients and you are solely responsible
        for their care. Reliance on any information provided by Paragonix or in connection with the Services is solely at your own risk. The
        decisions made following receipt of input through the Services are at the risk of the PCP, who should determine whether or not to
        use this input exercising his/her own independent medical judgment, and how to use this input to make his or her own clinical
        decisions.
      </p>
      <p>
        You acknowledge that although some Content (defined below) may be provided by individuals in the medical profession, the provision
        of such Content does not create a medical professional/patient relationship between you and Paragonix or between you and any other
        individual or entity, and does not constitute an opinion, medical advice, or diagnosis or treatment. Healthcare providers and
        patients should always obtain applicable diagnostic information from appropriate trusted sources. Healthcare providers should never
        withhold professional medical advice or delay in providing it because of something they have read in connection with our Services.
      </p>
      <h2>
        <u>What about messaging?</u>
      </h2>
      <p>
        As part of the Services, you may be able to send or receive communications through the Services, including messages to or from other
        users (for example, via SMS). You agree that any messaging functionality provided through the Service shall only be used in
        connection with coordinating logistics and transport in connection with a Paragonix product, and shall not be used in the practice
        of medicine, nursing, pharmacy or other healthcare activity or for any other purpose not authorized hereunder. You agree that you
        will not use the messaging functionality to exchange any sensitive personal information or protected health information as those
        terms are defined under applicable privacy and health care laws. You agree to indemnify and hold Paragonix harmless from and against
        any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys&rsquo; fees) arising
        from or in any way related to your breach of the foregoing.
      </p>
      <h2>
        <u>Are there restrictions in how I can use the Services?</u>
      </h2>
      <p>
        You represent, warrant, and agree that you will not provide or contribute anything, including any Content (as that term is defined
        below), to the Services, or otherwise use or interact with the Services, in a manner that:
      </p>
      <ol>
        <li>infringes or violates the intellectual property rights or any other rights of anyone else (including Paragonix);</li>
        <li>
          violates any law or regulation, including, without limitation, any applicable export control laws, privacy laws or any other
          purpose not reasonably intended by Paragonix;
        </li>
        <li>is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;</li>
        <li>
          jeopardizes the security of your Paragonix User ID, account or anyone else&rsquo;s (such as allowing someone else to log in to the
          Services as you);
        </li>
        <li>attempts, in any manner, to obtain the password, account, or other security information from any other user;</li>
        <li>violates the security of any computer network, or cracks any passwords or security encryption codes;</li>
        <li>
          runs Mail list, Listserv, any form of auto-responder or &ldquo;spam&rdquo; on the Services, or any processes that run or are
          activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services
          (including by placing an unreasonable load on the Services&rsquo; infrastructure);
        </li>
        <li>
          &ldquo;crawls,&rdquo; &ldquo;scrapes,&rdquo; or &ldquo;spiders&rdquo; any page, data, or portion of or relating to the Services or
          Content (through use of manual or automated means);
        </li>
        <li>copies or stores any significant portion of the Content; or</li>
        <li>
          decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating
          to the Services.
        </li>
      </ol>
      <p>A violation of any of the foregoing is grounds for termination of your right to use or access the Services.</p>
      <h2>
        <u>What are my rights in the Services?</u>
      </h2>
      <p>
        The materials displayed or performed or available on or through the Services, including, but not limited to, text, graphics, data,
        articles, photos, images, illustrations and so forth (all of the foregoing, the &ldquo;Content&rdquo;) are protected by copyright
        and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and
        restrictions contained in any Content you access through the Services, and you won&rsquo;t use, copy, reproduce, modify, translate,
        publish, broadcast, transmit, distribute, perform, upload, display, license, sell, commercialize or otherwise exploit for any
        purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates
        someone else&rsquo;s (including Paragonix&rsquo;s) rights.
      </p>
      <p>
        Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable and non-transferable
        license to use (i.e., to download and display locally) Content solely for purposes of using the Services. Use, reproduction,
        modification, distribution or storage of any Content for any purpose other than using the Services is expressly prohibited without
        prior written permission from us. You understand that Paragonix owns the Services and all Content. You won&rsquo;t modify, publish,
        transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works
        based on, or otherwise exploit any of the Services. The Services may allow you to copy or download certain Content, but please
        remember that even where these functionalities exist, all the restrictions in this section still apply.
      </p>
      <h2>
        <u>Who is responsible for what I see and do on the Services?</u>
      </h2>
      <p>
        Any information or Content publicly posted or privately transmitted through the Services is the sole responsibility of the person
        from whom such Content originated, and you access all such information and Content at your own risk, and we aren&rsquo;t liable for
        any errors or omissions in that information or Content or for any damages or loss you might suffer in connection with it. We cannot
        control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a
        result of having been exposed to the Content, and you hereby release us from all liability for you having acquired or not acquired
        Content through the Services. We can&rsquo;t guarantee the identity of any users with whom you interact in using the Services and
        are not responsible for which users gain access to the Services. <br />
        You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you have all
        rights necessary to do so, in the manner in which you contribute it.
      </p>
      <h2>
        <u>Termination</u>
      </h2>
      <p>
        Paragonix is free to terminate (or suspend access to) your use of the Services or your account for any reason in our discretion,
        including your breach of these Terms. Paragonix has the sole right to decide whether you are in violation of any of the restrictions
        set forth in these Terms.
      </p>
      <p>
        Account termination may result in destruction of any Content associated with your account. <br />
        Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example, all of the
        following will survive termination: any obligation you have to indemnify us, any limitations on our liability, any terms regarding
        ownership or intellectual property rights, and terms regarding disputes between us, including without limitation the arbitration
        agreement.
      </p>
      <h2>
        <u>What about Mobile Applications?</u>
      </h2>
      <p>
        You acknowledge and agree that the availability of our mobile application is dependent on the third party stores from which you
        download the application, e.g., the App Store from Apple or the Android app market from Google (each an &ldquo;App Store&rdquo;).
        Each App Store may have its own terms and conditions to which you must agree before downloading mobile applications from such store,
        including the specific terms relating to Apple App Store set forth below. You agree to comply with, and your license to use our
        application is conditioned upon your compliance with, such App Store terms and conditions. To the extent such other terms and
        conditions from such App Store are less restrictive than, or otherwise conflict with, the terms and conditions of these Terms of
        Use, the more restrictive or conflicting terms and conditions in these Terms of Use apply.
      </p>
      <h2>
        <u>I use the Paragonix App available via the Apple App Store – should I know anything about that?</u>
      </h2>
      <p>
        These Terms apply to your use of all the Services, including our iOS applications (the &ldquo;Application&rdquo;) available via the
        Apple, Inc. (&ldquo;Apple&rdquo;) App Store, but the following additional terms also apply to the Application:
      </p>
      <ol>
        <li>
          Both you and Paragonix acknowledge that the Terms are concluded between you and Paragonix only, and not with Apple, and that Apple
          is not responsible for the Application or the Content;
        </li>
        <li>
          The Application is licensed to you on a limited, non-exclusive, non-transferrable, non-sublicensable basis, solely to be used in
          connection with the Services for your private, personal, non-commercial use, subject to all the terms and conditions of these
          Terms as they are applicable to the Services;
        </li>
        <li>You will only use the Application in connection with an Apple device that you own or control;</li>
        <li>
          You acknowledge and agree that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to
          the Application;
        </li>
        <li>
          In the event of any failure of the Application to conform to any applicable warranty, including those implied by law, you may
          notify Apple of such failure; upon notification, Apple&rsquo;s sole warranty obligation to you will be to refund to you the
          purchase price, if any, of the Application;
        </li>
        <li>
          You acknowledge and agree that Paragonix, and not Apple, is responsible for addressing any claims you or any third party may have
          in relation to the Application;
        </li>
        <li>
          You acknowledge and agree that, in the event of any third-party claim that the Application or your possession and use of the
          Application infringes that third party&rsquo;s intellectual property rights, Paragonix, and not Apple, will be responsible for the
          investigation, defense, settlement and discharge of any such infringement claim;
        </li>
        <li>
          You represent and warrant that you are not located in a country subject to a U.S. Government embargo, or that has been designated
          by the U.S. Government as a &ldquo;terrorist supporting&rdquo; country, and that you are not listed on any U.S. Government list of
          prohibited or restricted parties;
        </li>
        <li>
          Both you and Paragonix acknowledge and agree that, in your use of the Application, you will comply with any applicable third-party
          terms of agreement which may affect or be affected by such use; and
        </li>
        <li>
          Both you and Paragonix acknowledge and agree that Apple and Apple&rsquo;s subsidiaries are third-party beneficiaries of these
          Terms, and that upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to
          enforce these Terms against you as the third-party beneficiary hereof.
        </li>
        <li>
          In the event you use the Application to provide you with real-time route guidance, YOUR USE OF THIS REAL-TIME ROUTE GUIDANCE
          APPLICATION IS AT YOUR SOLE RISK. LOCATION DATA MAY NOT BE ACCURATE.
        </li>
      </ol>
      <h2>
        <u>What else do I need to know?</u>
      </h2>
      <p>
        Warranty Disclaimer. Paragonix and its licensors, suppliers, partners, parent, subsidiaries or affiliated entities, and each of
        their respective officers, directors, members, employees, consultants, contract employees, representatives and agents, and each of
        their respective successors and assigns (Paragonix and all such parties together, the &ldquo;Paragonix Parties&rdquo;) make no
        representations or warranties concerning the Services, including without limitation regarding any Content contained in or accessed
        through the Services, and the Paragonix Parties will not be responsible or liable for the accuracy, copyright compliance, legality,
        or decency of material contained in or accessed through the Services or any claims, actions, suits procedures, costs, expenses,
        damages or liabilities arising out of use of, or in any way related to your participation in, the Services. The Paragonix Parties
        make no representations or warranties regarding suggestions or recommendations of services or products offered or purchased through
        or in connection with the Services. THE SERVICES AND CONTENT ARE PROVIDED BY PARAGONIX (AND ITS LICENSORS AND SUPPLIERS) ON AN
        &ldquo;AS-IS&rdquo; BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED
        OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO
        YOU.
      </p>
      <p id="#limitation-of-liability">
        <a href="#limitation-of-liability">Limitation of Liability</a>. TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO
        CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF
        THE PARAGONIX PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL
        DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE,
        ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE
        AGGREGATE, IN EXCESS OF ONE-HUNDRED ($100) DOLLARS OR (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE
        EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT
        APPLY TO YOU.
      </p>
      <p>
        Indemnity. You agree to indemnify and hold the Paragonix Parties harmless from and against any and all claims, liabilities, damages
        (actual and consequential), losses and expenses (including attorneys&rsquo; fees) arising from or in any way related to any claims
        relating to (a) your use of the Services (including any actions taken by a third party using your account), and (b) your violation
        of these Terms.
      </p>
      <p>
        Assignment. You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account,
        in any way (by operation of law or otherwise) without Paragonix&rsquo;s prior written consent. We may transfer, assign, or delegate
        these Terms and our rights and obligations without consent.
      </p>
      <p>
        Choice of Law. These Terms are governed by and will be construed under the Federal Arbitration Act, applicable federal law, and the
        laws of the Commonwealth of Massachusetts, without regard to the conflicts of laws provisions thereof.
      </p>
      <p id="arbitration-agreement">
        <a href="#arbitration-agreement">Arbitration Agreement</a>. Please read the following ARBITRATION AGREEMENT carefully because it
        requires you to arbitrate certain disputes and claims with Paragonix and limits the manner in which you can seek relief from
        Paragonix. Both you and Paragonix acknowledge and agree that for the purposes of any dispute arising out of or relating to the
        subject matter of these Terms, Paragonix&rsquo;s officers, directors, employees and independent contractors
        (&ldquo;Personnel&rdquo;) are third-party beneficiaries of these Terms, and that upon your acceptance of these Terms, Personnel will
        have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third-party beneficiary
        hereof.
      </p>
      <p>
        <em>(a) Arbitration Rules; Applicability of Arbitration Agreement</em>. The parties shall use their best efforts to settle any
        dispute, claim, question, or disagreement arising out of or relating to the subject matter of these Terms directly through
        good-faith negotiations, which shall be a precondition to either party initiating arbitration. If such negotiations do not resolve
        the dispute, it shall be finally settled by binding arbitration in Suffolk County, Massachusetts. The arbitration will proceed in
        the English language, in accordance with the JAMS Streamlined Arbitration Rules and Procedures (the &ldquo;Rules&rdquo;) then in
        effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract
        disputes. The arbitrator shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment
        upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction.
      </p>
      <p>
        (b) <em>Costs of Arbitration</em>. The Rules will govern payment of all arbitration fees. Paragonix will pay all arbitration fees
        for claims less than seventy-five thousand ($75,000) dollars. Paragonix will not seek its attorneys&rsquo; fees and costs in
        arbitration unless the arbitrator determines that your claim is frivolous.
      </p>
      <p>
        (c) <em>Small Claims Court; Infringement</em>. Either you or Paragonix may assert claims, if they qualify, in small claims court in
        Suffolk County, Massachusetts or any United States county where you live or work. Furthermore, notwithstanding the foregoing
        obligation to arbitrate disputes, each party shall have the right to pursue injunctive or other equitable relief at any time, from
        any court of competent jurisdiction, to prevent the actual or threatened infringement, misappropriation or violation of a
        party&rsquo;s copyrights, trademarks, trade secrets, patents or other intellectual property rights.
      </p>
      <p>
        (d) <em>Waiver of Jury Trial</em>. YOU AND PARAGONIX WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL
        IN FRONT OF A JUDGE OR JURY. You and Paragonix are instead choosing to have claims and disputes resolved by arbitration. Arbitration
        procedures are typically more limited, more efficient, and less costly than rules applicable in court and are subject to very
        limited review by a court. In any litigation between you and Paragonix over whether to vacate or enforce an arbitration award, YOU
        AND PARAGONIX WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be resolved by a judge.
      </p>
      <p>
        (e) <em>Waiver of Class or Consolidated Actions</em>. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE
        ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE
        ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If however, this waiver of class or
        consolidated actions is deemed invalid or unenforceable, neither you nor Paragonix is entitled to arbitration; instead all claims
        and disputes will be resolved in a court as set forth in (g) below.<strong> </strong>
      </p>
      <p>
        (f) <em>Opt-out</em>. You have the right to opt out of the provisions of this Section by sending written notice of your decision to
        opt out to the following address: 639 Granite Street, # 408, Braintree, Massachusetts 02184 postmarked within thirty (30) days of
        first accepting these Terms. You must include (i) your name and residence address, (ii) the email address and/or telephone number
        associated with your account, and (iii) a clear statement that you want to opt out of these Terms&rsquo; arbitration agreement.
      </p>
      <p>
        (g) <em>Exclusive Venue</em>. If you send the opt-out notice in (f), and/or in any circumstances where the foregoing arbitration
        agreement permits either you or Paragonix to litigate any dispute arising out of or relating to the subject matter of these Terms in
        court, then the foregoing arbitration agreement will not apply to either party, and both you and Paragonix agree that any judicial
        proceeding (other than small claims actions) will be brought in the state or federal courts located in, respectively, Suffolk
        County, Massachusetts, or the federal district in which that county falls.
      </p>
      <p>
        (h) <em>Severability</em>. If the prohibition against class actions and other claims brought on behalf of third parties contained
        above is found to be unenforceable, then all of the preceding language in this Arbitration Agreement section will be null and void.
        This arbitration agreement will survive the termination of your relationship with Paragonix.
      </p>
      <p>
        Miscellaneous. You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other governmental
        assessments associated with your activity in connection with the Services, provided that the Paragonix may, in its sole discretion,
        do any of the foregoing on your behalf or for itself as it sees fit. The failure of either you or us to exercise, in any way, any
        right herein shall not be deemed a waiver of any further rights hereunder. If any provision of these Terms are found to be
        unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall
        otherwise remain in full force and effect and enforceable. You and Paragonix agree that these Terms are the complete and exclusive
        statement of the mutual understanding between you and Paragonix, and that these Terms supersede and cancel all previous written and
        oral agreements, communications and other understandings relating to the subject matter of these Terms. You hereby acknowledge and
        agree that you are not an employee, agent, partner, or joint venture of Paragonix, and you do not have any authority of any kind to
        bind Paragonix in any respect whatsoever.
      </p>
      <p>
        Except as expressly set forth in the sections above regarding the Apple Application and the arbitration agreement, you and Paragonix
        agree there are no third-party beneficiaries intended under these Terms.
      </p>
    </>
  );
}

export default TermsOfUseText;
