import { formatPressure, millisecondsToTime } from '@packages/utils';
import { useCallback } from 'react';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useSession } from '../../../contexts/useSession';
import ChartTooltip from './ChartTooltip';

function Chart({ data, ticks }) {
  const { color } = useSession();

  const xAxisTickFormatterWrapper = useCallback(value => xAxisTickFormatter(value), []);
  const yAxisTickFormatterWrapper = useCallback(value => yAxisTickFormatter(value), []);

  function xAxisTickFormatter(value: number) {
    return millisecondsToTime(value);
  }

  function yAxisTickFormatter(value: number) {
    return formatPressure(value, 1, false);
  }

  return (
    <ResponsiveContainer>
      <LineChart data={data}>
        <XAxis
          type="number"
          dataKey="milliseconds"
          ticks={ticks}
          domain={[0, ticks[ticks.length - 1]]}
          stroke="#D3D3D3"
          tickFormatter={xAxisTickFormatterWrapper}
        />

        <YAxis type="number" domain={[0, 27]} tickCount={7} stroke="#D3D3D3" tickFormatter={yAxisTickFormatterWrapper} />

        <Legend iconType="circle" />

        <Line name="Pressure" dataKey="pressure" stroke={color?.primary} dot={false} strokeWidth={3} />

        <Tooltip content={<ChartTooltip color={color?.primary} />} />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default Chart;
