import { LoadGoogle } from '@packages/ui';
import { HelmetProvider } from 'react-helmet-async';
import { ChatProvider } from './contexts/useChat';
import { SessionProvider } from './contexts/useSession';
import Routes from './routes';

function App() {
  return (
    <LoadGoogle>
      <HelmetProvider>
        <SessionProvider>
          <ChatProvider>
            <Routes />
          </ChatProvider>
        </SessionProvider>
      </HelmetProvider>
    </LoadGoogle>
  );
}

export default App;
