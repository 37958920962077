import { Routes } from '@packages/constants';
import { DonationType, OrganType, OrganTypeUtil, SessionState, SessionType } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import { MdOutlineAddCircle } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { TableInstance } from 'react-table';
import Button from '../../Shared/Button';
import ExportSelect from '../../Shared/Table/export/ExportSelect';
import SearchFilter from '../../Shared/Table/filters/SearchFilter';
import SelectFilter from '../../Shared/Table/filters/SelectFilter';

export default function SessionTableFilters({ table }: { table: TableInstance }) {
  const { t } = useTranslation();

  return (
    <div className="my-5 flex flex-col justify-between sm:flex-row">
      <div className="mb-5 flex flex-1 flex-row flex-wrap gap-3 sm:mb-0">
        <SearchFilter
          onChange={event => {
            table.setFilter('joinKey', event.target.value);
          }}
        />

        <SelectFilter
          label={t('session_state')}
          values={[
            { value: null, title: '-' },
            { value: SessionState.CREATED, title: t('session_state_created'), badge: 'info' },
            { value: SessionState.LOGGER_STARTED, title: t('session_state_logger_started'), badge: 'success' },
            { value: SessionState.LOGGER_STOPPED, title: t('session_state_logger_stopped'), badge: 'warning' },
            { value: SessionState.FINISHED, title: t('session_state_finished'), badge: 'danger' },
          ]}
          onChange={state => {
            table.setFilter('sessionState', state);
          }}
        />

        <SelectFilter
          label={t('organ')}
          values={[
            { value: null, title: '-' },
            { value: OrganType.LUNG, title: OrganTypeUtil.organString(OrganType.LUNG), badge: 'info' },
            { value: OrganType.HEART, title: OrganTypeUtil.organString(OrganType.HEART), badge: 'danger' },
            { value: OrganType.LIVER, title: OrganTypeUtil.organString(OrganType.LIVER), badge: 'warning' },
            // { value: OrganType.KIDNEY, title: OrganTypeUtil.organString(OrganType.KIDNEY), badge: 'success' },
          ]}
          onChange={state => {
            table.setFilter('organ', state);
          }}
        />

        <SelectFilter
          label={t('donation_type')}
          values={[
            { value: null, title: '-' },
            { value: DonationType.DBD, title: t('dbd'), badge: 'success' },
            { value: DonationType.DCD, title: t('dcd'), badge: 'warning' },
          ]}
          onChange={state => {
            table.setFilter('donationType', state);
          }}
        />

        <SelectFilter
          label={t('session_type')}
          values={[
            { value: null, title: '-' },
            { value: SessionType.CLINICAL, title: t('session_type_clinical'), badge: 'success' },
            { value: SessionType.TRAINING, title: t('session_type_training'), badge: 'warning' },
          ]}
          onChange={state => {
            table.setFilter('sessionType', state);
          }}
        />

        <ExportSelect selectedRows={table.selectedFlatRows} />
      </div>

      <div>
        <Link to={Routes.web.JOIN_NO_KEY}>
          <Button Icon={MdOutlineAddCircle}>{t('join_session')}</Button>
        </Link>
      </div>
    </div>
  );
}
