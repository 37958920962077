import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

import app from './config';

export function getFirebaseAuth() {
  return getAuth(app);
}

export async function login(email: string, password: string, isRememberMeSet = false) {
  const auth = getFirebaseAuth();

  await setPersistence(auth, isRememberMeSet ? browserLocalPersistence : browserSessionPersistence);

  const { user } = await signInWithEmailAndPassword(auth, email, password);

  return user;
}

export function logout() {
  const auth = getFirebaseAuth();

  signOut(auth);
}

export async function createUser(email: string, password: string, firstname: string, lastname: string, organization: string, role: string) {
  const auth = getFirebaseAuth();
  const firestore = getFirestore();

  return createUserWithEmailAndPassword(auth, email, password).then(authUser => {
    setDoc(
      doc(firestore, 'users', authUser?.user.uid),
      {
        firstname,
        lastname,
        organization,
        role,
        needsOrganizationProfile: true,
        needsEmailVerification: true,
      },
      { merge: true },
    );
  });
}

export function getAuthUser() {
  const auth = getFirebaseAuth();

  return auth.currentUser;
}
