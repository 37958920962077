import { VFC } from 'react';
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi';

export type SortToggleProps = {
  isSorted: boolean;
  isSortedDesc?: boolean;
};

const SortToggle: VFC<SortToggleProps> = ({ isSorted, isSortedDesc }) => {
  if (!isSorted) {
    return null;
  }

  if (isSortedDesc) {
    return <HiOutlineChevronDown className="h-4" />;
  }

  return <HiOutlineChevronUp className="h-4" />;
};

export default SortToggle;
