import { useTranslation } from '@packages/translations';
import { MdSearch } from 'react-icons/md';

export default function SearchFilter({ onChange, placeholder }: { onChange: any; placeholder?: string }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="relative rounded-md">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MdSearch className="h-5 w-5" />
        </div>
        <input
          type="text"
          name="search"
          className="block w-full rounded-md border-[#eff2f7] pl-10 text-[#212529]"
          placeholder={placeholder ?? t('search_keys')}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
