import CsvDownloader from 'react-csv-downloader';
import { MdMailOutline } from 'react-icons/md';
import { useTranslation } from '@packages/translations';
import Card from '../Shared/Card';

function ActionPanel({ title, text, actions, actionData }) {
  const { t } = useTranslation();

  return (
    <Card title={title}>
      <div>
        {text != null && text.length > 0 && (
          <div>
            <p className="text-muted">{text}</p>
          </div>
        )}

        {actions.export && actionData?.export != null && (
          <div className="my-2">
            <CsvDownloader filename={actionData.export.filename} columns={actionData.export.columns} datas={actionData.export.data}>
              <div className="btn btn-dark">
                <div className="d-flex align-items-center">
                  <i className="mdi mdi-download font-size-18" />
                  <span className="ms-2">{t('export')}</span>
                </div>
              </div>
            </CsvDownloader>
          </div>
        )}

        {actions.email && actionData?.email?.email != null && (
          <div className="my-4">
            <a
              href={`mailto:${actionData?.email?.email}`}
              className="bg-[#343a40] border-[#343a40] text-[#eff2f7] hover:border-[#2c3136] hover:bg-[#2c3136] inline-flex items-center justify-center rounded border px-4 py-3 text-base gap-2"
            >
              <MdMailOutline className="h-5 w-5" />
              <span className="ms-2">{t('email_support')}</span>
            </a>
          </div>
        )}

        {actions.callSupport && actionData?.callSupport?.phonenumber != null && (
          <div className="my-4">
            <div className="my-2">
              <p className="text-[16px] text-dark">{actionData.callSupport.phonenumber}</p>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}

export default ActionPanel;
