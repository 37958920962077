import { SessionState } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import Badge from './Badge';

function SessionStateBadge({ sessionState }: { sessionState: SessionState }) {
  const { t } = useTranslation();
  const state = parseSessionState();

  function parseSessionState() {
    if (sessionState === SessionState.CREATED) {
      return { label: t('session_state_created'), className: 'info' };
    }

    if (sessionState === SessionState.LOGGER_STARTED) {
      return { label: t('session_state_logger_started'), className: 'success' };
    }

    if (sessionState === SessionState.LOGGER_STOPPED) {
      return { label: t('session_state_logger_stopped'), className: 'warning' };
    }

    if (sessionState === SessionState.FINISHED) {
      return { label: t('session_state_finished'), className: 'danger' };
    }

    return { label: t('session_state_unknown'), className: 'neutral' };
  }

  return <Badge variant={state.className}>{state.label}</Badge>;
}

export default SessionStateBadge;
