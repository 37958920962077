import { OrganTypeUtil, Organization } from '@packages/firebase';
import { formatCountry } from '@packages/ui';
import { formatDate } from '@packages/utils';
import { DateTime } from 'luxon';
import { useFetchOrganizationTypesConfig } from '../../lib/firebase/firestore';
import Card from '../Shared/Card';

export type OrganizationDetailsCardProps = {
  organization: Organization;
};

const OrganizationDetailsCard = ({ organization }: OrganizationDetailsCardProps) => {
  const { organizationTypes, isLoading: isConfigLoading } = useFetchOrganizationTypesConfig();

  if (isConfigLoading) {
    return null;
  }

  return (
    <Card>
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <div className="relative">
            <div>
              {organization.logoUrl != null ? (
                <img className="inline-block h-16 w-16 rounded-md" src={organization.logoUrl} alt="" />
              ) : (
                <div className="inline-block h-16 w-16 rounded-md border bg-gray-100" />
              )}
            </div>
          </div>
        </div>

        <div className="space-y-1">
          <h1 className="flex items-center space-x-2 text-2xl font-bold text-gray-900">
            <span>{organization.name}</span>
          </h1>

          <p className="text-sm font-medium text-gray-500">
            Created{' '}
            {DateTime.fromJSDate(organization.createdAt).toRelative({
              padding: 1000,
            })}{' '}
            ({formatDate(organization.createdAt)})
          </p>
        </div>
      </div>

      <div className="mt-8">
        <div className="space-y-6 lg:col-span-2 lg:col-start-1">
          <section>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Handle</dt>
                <dd className="mt-1 text-gray-900">{organization.handle ?? <i>No handle</i>}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Organs</dt>
                <dd className="mt-2 text-gray-900">
                  <div className="flex">
                    {organization.organTypes != null && organization.organTypes.length > 0 ? (
                      organization.organTypes.map(organType => (
                        <div key={`organ-type-${organType}`} className="mb-2 mr-2">
                          <span>{OrganTypeUtil.organString(organType)}</span>
                        </div>
                      ))
                    ) : (
                      <i>No organs</i>
                    )}
                  </div>
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Types</dt>
                <dd className="mt-2 text-gray-900">
                  <div className="flex">
                    {organization.types != null &&
                      organization.types.length > 0 &&
                      organization.types.map(type => (
                        <div key={`organization-type-${type}`} className="mb-2 mr-2">
                          <span>{organizationTypes?.find(organizationType => organizationType.id === type)?.locales?.en ?? 'Unknown'}</span>
                        </div>
                      ))}

                    {organization.isCorporate && (
                      <div className="mb-2 mr-2">
                        <span>Corporate</span>
                      </div>
                    )}

                    {!organization.isCorporate && (organization.types == null || organization.types.length === 0) && <i>No types</i>}
                  </div>
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Domains{' '}
                  {organization.domains != null &&
                    organization.domains.length > 0 &&
                    (organization.areDomainsWhitelisted ? <span>(Whitelisted)</span> : <span>(Not Whitelisted)</span>)}
                </dt>
                <dd className="mt-2 text-gray-900">
                  {organization.domains != null && organization.domains.length > 0 ? (
                    organization.domains.map(domain => (
                      <div key={`known-domains-${domain}`} className="mb-2 mr-2">
                        <span>{domain}</span>
                      </div>
                    ))
                  ) : (
                    <i>No domains</i>
                  )}
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Address</dt>
                <dd className="mt-1 text-gray-900">{organization.address ?? <i>No address</i>}</dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Country</dt>
                <dd className="mt-1 text-gray-900">{organization.country ? formatCountry(organization.country) : <i>No country</i>}</dd>
              </div>
            </dl>
          </section>
        </div>
      </div>
    </Card>
  );
};

export default OrganizationDetailsCard;
