import { Assets } from '@packages/assets';
import { Routes } from '@packages/constants';
import { useTranslation } from '@packages/translations';
import { FirebaseError } from 'firebase/app';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import InputField from '../components/Forms/InputField';
import AuthLayout from '../components/Layouts/AuthLayout';
import Button from '../components/Shared/Button';
import { FIREBASE_AUTH_ERRORS } from '../lib/firebase/authErrors';
import { sendPasswordReset } from '../lib/firebase/functions';

export type ResetFormValues = {
  email: string;
};

const ForgotPassword = () => {
  const { t } = useTranslation();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error>();

  const initialValues = {
    email: '',
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email('Email is invalid').required('Email is required'),
  });

  async function onSubmit(values: ResetFormValues) {
    try {
      await sendPasswordReset(values.email);
      setSuccess(true);
    } catch (err: any) {
      if (err instanceof FirebaseError) {
        setError(FIREBASE_AUTH_ERRORS[err.code] ?? err.message);
      }

      setError(new Error('Something went wrong. Please try again later.'));
    }
  }

  return (
    <AuthLayout title={t('meta_title_reset_password')}>
      <div className="mt-6 mb-10 mb-md-5">
        <Link to={Routes.web.FORGOT_PASSWORD} className="d-block">
          <img src={Assets.logo.paragonixLogoLightPng} alt="" style={{ height: '18px' }} />
        </Link>
      </div>
      <div>
        <h5 className="mb-2 text-xl font-medium text-dark">{t('reset_password_title')}</h5>
        <p className="text-muted">{t('reset_password_subtitle')}</p>
      </div>

      <div className="mt-8">
        <div className="mt-6">
          {success && (
            <div className="mb-6 border rounded px-6 py-3 text-[#40925d] border-[#d2fcd5] bg-[#e1fdea]">{t('reset_password_success')}</div>
          )}
          {error && <div className="mb-6 border rounded px-6 py-3 text-[#924040] border-[#fcd2d2] bg-[#fde1e1]">{error}</div>}
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={values => onSubmit(values)}>
            {({ errors, touched, isSubmitting }) => (
              <Form className="space-y-6">
                <div>
                  <InputField
                    type="email"
                    placeholder={t('email_placeholder')}
                    label={t('email')}
                    name="email"
                    error={errors.email}
                    touched={touched.email}
                  />
                </div>

                <div>
                  <Button type="submit" variant="success" block isLoading={isSubmitting}>
                    {t('reset')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <div className="text-center my-14">
        <p>
          {t('reset_password_text').replace('%s', '')}{' '}
          <Link to={Routes.web.LOGIN} className="font-medium text-dark">
            {t('login')}
          </Link>
        </p>
      </div>

      <p className="mt-4 text-center text-muted">© {new Date().getFullYear()} Paragonix Technologies Inc.</p>
    </AuthLayout>
  );
};

export default ForgotPassword;
