import { OrganizationMemberStatus, OrganizationStatus } from '@packages/firebase';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { MdOutlineAddCircle } from 'react-icons/md';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import InputField from '../components/Forms/InputField';
import SelectField from '../components/Forms/SelectField';
import AppContent from '../components/Layouts/AppLayout/Content';
import OrganizationDetailsCard from '../components/Organization/OrganizationDetailsCard';
import Button from '../components/Shared/Button';
import Card from '../components/Shared/Card';
import { useAuthUser } from '../contexts/useAuthUser';
import { logout } from '../lib/firebase/auth';
import { useFetchOrganizationUserRolesConfig } from '../lib/firebase/firestore';
import { joinOrganization } from '../lib/firebase/functions';
import { appStoreRedirect } from '../utils/appStoreRedirect';

const OrganizationJoin = () => {
  const { organizationId } = useParams();
  const [params] = useSearchParams();

  const [error] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const { authUser, organization, member, isLoading } = useAuthUser();

  const [isConfirmed, setIsConfirmed] = useState(false);

  const initialValues = {
    startOfEmployment: '',
    title: '',
    role: '',
  };

  const validationSchema = yup.object().shape({
    startOfEmployment: yup.string().required('Please enter a start of employment'),
    role: yup.string().required('Please select a role'),
    title: yup.string().required('Please enter a title'),
  });

  const config = useFetchOrganizationUserRolesConfig();

  useEffect(() => {
    const email = params.get('email');

    appStoreRedirect();

    if (isLoading || config.isLoading) {
      return;
    }

    if (organization == null) {
      navigate('/');
      return;
    }

    if (authUser != null && email != null && email !== authUser.email) {
      // eslint-disable-next-line no-restricted-globals
      const isConfirmed = confirm(
        `You are invited to join ${organization.name} with a different email address. You are currently signed in with ${authUser.email}. The invite is for ${email}. Please sign out and sign in with ${email}. Click OK to sign out!`,
      );

      if (isConfirmed) {
        logout();
        return;
      }

      navigate('/');
      return;
    }

    if (organization.status !== OrganizationStatus.ACTIVE) {
      navigate(`/organization/${organization.id}`);
      return;
    }

    if (member == null || member.status !== OrganizationMemberStatus.INVITED) {
      navigate(`/organization/${organization.id}`);
      return;
    }

    setIsConfirmed(true);
  }, [isLoading, config.isLoading]);

  async function onSubmit({ startOfEmployment, title, role }) {
    setIsSubmitting(true);

    joinOrganization(organizationId as string, title, role, startOfEmployment)
      .then(() => {
        toast.success(`You have joined the organization`, { autoClose: 5000 });
        navigate(`/organization/${organization?.id}`);
      })
      .catch(error => {
        toast.error(`There was an Error: ${error.message}`, { autoClose: 5000 });
      })
      .finally(() => setIsSubmitting(false));
  }

  if (organization == null) {
    return null;
  }

  if (!isConfirmed) {
    return null;
  }

  return (
    <AppContent>
      <Helmet>
        <title>Organization join | Paragonix</title>
      </Helmet>

      <div className="space-y-5">
        <div>
          <OrganizationDetailsCard organization={organization} />
        </div>

        <div>
          <Card title="Complete Organization Profile" className="3xl:col-span-12">
            <div className="mt-10">
              {error && error.message}
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={values => onSubmit(values)}>
                {({ errors, touched }) => (
                  <Form className="sm:grid sm:grid-cols-12 sm:gap-4">
                    <div className="pt-1 sm:col-span-3">
                      <label
                        htmlFor="startOfEmployment"
                        className={classNames('text-dark block font-medium', { 'text-red-500': error && touched })}
                      >
                        Start Of Employment
                      </label>
                    </div>
                    <div className="sm:col-span-9">
                      <InputField
                        name="startOfEmployment"
                        type="date"
                        error={errors.startOfEmployment}
                        touched={touched.startOfEmployment}
                      />
                    </div>

                    <div className="pt-1 sm:col-span-3">
                      <label htmlFor="role" className={classNames('text-dark block font-medium', { 'text-red-500': error && touched })}>
                        Role
                      </label>
                    </div>
                    <div className="sm:col-span-9">
                      <SelectField name="role" error={errors.role} touched={touched.role}>
                        <option key="select-role" value="">
                          --- Select a Role ---
                        </option>
                        {config.userRoles?.map(role => (
                          <option key={role.id} value={role.id}>
                            {role.locales?.en}
                          </option>
                        ))}
                      </SelectField>
                    </div>

                    <div className="pt-1 sm:col-span-3">
                      <label htmlFor="title" className={classNames('text-dark block font-medium', { 'text-red-500': error && touched })}>
                        Title
                      </label>
                    </div>
                    <div className="sm:col-span-9">
                      <InputField
                        name="title"
                        type="text"
                        placeholder="e.g. Transplant Surgeon"
                        error={errors.title}
                        touched={touched.title}
                      />

                      <Button
                        type="submit"
                        isLoading={isSubmitting}
                        disabled={Object.keys(errors).length !== 0 || Object.keys(touched).length === 0}
                        className="mt-4"
                        Icon={MdOutlineAddCircle}
                      >
                        Join Organization
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Card>
        </div>
      </div>
    </AppContent>
  );
};

export default OrganizationJoin;
