import { Routes } from '@packages/constants';
import { AuditLogType } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { MdLogout, MdVolumeOff, MdVolumeUp } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuthUser } from '../../contexts/useAuthUser';
import { createAuditLog, muteSession, unmuteSession } from '../../lib/firebase/firestore';
import { leaveSession } from '../../lib/firebase/functions';
import Button from '../Shared/Button';
import Card from '../Shared/Card';
import CopyToClipboardForm from '../Shared/CopyToClipboardForm';
import MembersTable from './MembersTable';

export default function InfoTab({ session, members }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authUser } = useAuthUser();
  const [isLoading, setIsLoading] = useState(false);

  const isLive = useMemo(() => {
    return session?.joinKey && !session.finished;
  }, [session]);

  const isMuted = useMemo(() => {
    const member = members?.find(elem => elem.id === authUser.uid);

    return member?.muted ?? false;
  }, [authUser.uid, members]);

  async function handleMuteSession() {
    setIsLoading(true);

    muteSession(session.id)
      .then(() =>
        toast.success(t('mute_session_message'), {
          autoClose: 3000,
        }),
      )
      .catch(err => {
        toast.error(`Unable to mute session: ${err.message}`);
      })
      .finally(() => setIsLoading(false));
  }

  async function handleUnmuteSession() {
    setIsLoading(true);

    unmuteSession(session.id)
      .then(() =>
        toast.success(t('unmute_session_message'), {
          autoClose: 3000,
        }),
      )
      .catch(err => {
        toast.error(`Unable to unmute session: ${err.message}`);
      })
      .finally(() => setIsLoading(false));
  }

  async function handleLeaveSession() {
    // eslint-disable-next-line no-alert
    const leave = window.confirm(t('session_leave_dialog_text'));

    if (!leave) {
      return;
    }

    setIsLoading(true);

    leaveSession(session.id)
      .then(() => createAuditLog(authUser.uid, session.id, AuditLogType.SESSION_LEFT))
      .then(() => {
        navigate(Routes.web.DASHBOARD);
        toast.success(t('leave_session_success_message'), {
          autoClose: 3000,
        });
      })
      .catch(err => {
        toast.error(t('leave_session_error_message').replace('%s', err.message));
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <div className="grid grid-cols-2 gap-5">
      {isLive && (
        <Card title={t('session_key')} className="col-span-2 xl:col-span-1">
          <p className="text-muted">{t('join_key_copy_text')}</p>
          <div className="mt-6">
            <CopyToClipboardForm text={session.joinKey.toString()} />
          </div>
        </Card>
      )}
      <Card
        title={session.finished ? t('leave_session') : t('feature_mute_title')}
        className={classNames(isLive ? 'col-span-2 xl:col-span-1' : 'col-span-2')}
      >
        {session.finished ? (
          <>
            <p className="text-muted">{t('leave_session_text')}</p>
            <Button variant="danger" onClick={() => handleLeaveSession()} className="mt-6" Icon={MdLogout} isLoading={isLoading}>
              {t('leave_session')}
            </Button>
          </>
        ) : (
          <>
            <p className="text-muted">{t('feature_mute_text')}</p>
            {isMuted ? (
              <Button variant="primary" isLoading={isLoading} onClick={() => handleUnmuteSession()} className="mt-6" Icon={MdVolumeUp}>
                <span>{t('unmute_session')}</span>
              </Button>
            ) : (
              <Button variant="primary" isLoading={isLoading} onClick={() => handleMuteSession()} className="mt-6" Icon={MdVolumeOff}>
                <span>{t('mute_session')}</span>
              </Button>
            )}
          </>
        )}
      </Card>

      <Card title={t('session_members_title')} className="col-span-2">
        <MembersTable members={members} />
      </Card>
    </div>
  );
}
