import { useTranslation } from '@packages/translations';
import EmptyLayout from '../components/Layouts/EmptyLayout';
import TextContent from '../components/Shared/TextContent';
import TermsOfUseText from '../components/TermsOfUseText';

const TermsOfUse = () => {
  const { t } = useTranslation();

  return (
    <EmptyLayout title={t('terms_of_use')}>
      <TextContent title={t('terms_of_use')}>
        <TermsOfUseText />
      </TextContent>
    </EmptyLayout>
  );
};

export default TermsOfUse;
