import { Routes } from '@packages/constants';
import { useTranslation } from '@packages/translations';
import { Link } from 'react-router-dom';
import EmptyLayout from '../components/Layouts/EmptyLayout';
import Button from '../components/Shared/Button';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <EmptyLayout title={t('meta_title_not_found')}>
      <div className="my-20 text-center mb-5">
        <h1 className="text-[#495057] text-[52px] md:text-[83px] font-[300]">404</h1>
        <h4 className="text-[#495057] uppercase text-[19px] font-[500]">{t('page_not_found')}</h4>
        <div className="mt-10 text-center">
          <Link className="btn btn-dark" to={Routes.web.DASHBOARD}>
            <Button>{t('to_dashboard')}</Button>
          </Link>
        </div>
      </div>
    </EmptyLayout>
  );
};

export default NotFound;
