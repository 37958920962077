import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { Assets } from '@packages/assets';
import CookieBanner from '../CookieBanner';

export type AuthLayoutProps = {
  title: string;
  children: ReactNode;
};

export default function AuthLayout({ title, children }: AuthLayoutProps) {
  return (
    <>
      <Helmet>
        <title>{title} | Paragonix</title>
        {/* eslint-disable jsx-a11y/html-has-lang */}
        <html className="h-full bg-white" />
        <body className="auth-page h-full" />
      </Helmet>

      <main className="h-full ">
        <div className="flex h-full">
          <div className="w-[58%] lg:w-[41%] xl:w-1/3 mx-2 flex flex-1 flex-col py-12 px-8 md:flex-none md:px-16 lg:px-18 overflow-scroll">
            <div className="mx-auto w-full md:max-w-96 z-10">{children}</div>
          </div>
          <div className="relative hidden w-0 flex-1 md:block h-full">
            <img className="absolute inset-0 h-full w-full object-cover" src={Assets.backgrounds.authJpg} alt="" />
          </div>
          <div
            className="absolute bg-cover inline w-full h-full opacity-10 md:hidden"
            style={{ backgroundImage: `url(${Assets.backgrounds.authJpg}` }}
          />
        </div>
      </main>
      <CookieBanner />
    </>
  );
}
