import { useTranslation } from '@packages/translations';
import { MdThermostat } from 'react-icons/md';

function ChartPlaceholder() {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex flex-col justify-center items-center text-center py-10">
      <div className="bg-grey-100 bg-product-light rounded-full p-6 mb-6">
        <MdThermostat className="h-9 w-9 text-product" />
      </div>
      <div className="text-[18px] font-[600] mb-3">{t('session_insufficient_readings_title')}</div>
      <div className="text-muted text-[14px]">{t('session_insufficient_readings_text')}</div>
    </div>
  );
}

export default ChartPlaceholder;
