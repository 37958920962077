import classNames from 'classnames';
import { ReactNode } from 'react';
import { IconType } from 'react-icons';

interface ButtonProps {
  size?: 'sm' | 'base';
  variant?: 'primary' | 'outline' | 'danger' | 'success';
  disabled?: boolean;
  onClick?: () => void;
  children: ReactNode;
  className?: string;
  type?: 'button' | 'submit';
  block?: boolean;
  Icon?: IconType;
  isLoading?: boolean;
}

export default function Button({
  size = 'base',
  variant = 'primary',
  disabled,
  onClick = () => {},
  children,
  className,
  type = 'button',
  block = false,
  Icon,
  isLoading = false,
}: ButtonProps) {
  const variants = {
    primary: {
      default: 'bg-[#343a40] border-[#343a40] text-[#eff2f7] hover:border-[#2c3136] hover:bg-[#2c3136]',
    },
    outline: {
      default: 'bg-transparent border-[#eff2f7] text[#212529] hover:border-[#eff2f7] hover:bg-[#eff2f7] hover:text-black',
    },
    danger: {
      default: 'bg-[#f46a6a] border-[#f46a6a] text-[#eff2f7] hover:bg-[#cf5a5a] hover:border-[#C35556]',
    },
    success: {
      default: 'bg-[#34c38f] border-[#34c38f] text-[#fff] hover:bg-[#2ca67a] hover:border-[#2ca67a]',
    },
  };

  const sizeClass = size === 'sm' ? 'px-2 py-1.5 text-sm' : 'px-3 py-2 text-base';

  return (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      className={classNames(
        { 'w-full': block },
        disabled ? 'opacity-[0.65]' : 'pointer',
        variants[variant].default,
        sizeClass,
        'inline-flex items-center justify-center rounded border gap-2 outline-none',
        className,
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {!isLoading && Icon && <Icon className="w-5 h-5" />}
      {isLoading && (
        <div className="flex justify-center">
          <div className="inline-block w-5 h-5 border-2 rounded-full spinner-border animate-spin" role="status" />
        </div>
      )}
      <div>{children}</div>
    </button>
  );
}
