import { Routes } from '@packages/constants';
import { useTranslation } from '@packages/translations';
import { Helmet } from 'react-helmet-async';
import { MdOutlineAddCircle } from 'react-icons/md';
import { Link } from 'react-router-dom';
import AppContent from '../components/Layouts/AppLayout/Content';
import SessionTable from '../components/Session/Table/index';
import Button from '../components/Shared/Button';
import Card from '../components/Shared/Card';
import EmptyPlaceholder from '../components/Shared/EmptyPlaceholder';
import { useAuthUser } from '../contexts/useAuthUser';
import { useFetchSessions } from '../lib/firebase/firestore';

const Sessions = () => {
  const { t } = useTranslation();
  const { organization } = useAuthUser();

  const [sessions, areSessionsLoading] = useFetchSessions({ organizationId: organization?.id });

  if (areSessionsLoading) {
    return null;
  }

  if (sessions == null || sessions.length === 0) {
    return (
      <AppContent>
        <Helmet>
          <title>{t('meta_title_dashboard')} | Paragonix</title>
        </Helmet>
        <EmptyPlaceholder>
          <h3 className="text-[20px] font-medium">{t('session_empty_title')}</h3>
          <p className="text-muted mt-2 mb-10">{t('session_empty_subtitle')}</p>
          <Link to={Routes.web.JOIN_NO_KEY}>
            <Button Icon={MdOutlineAddCircle}>{t('join_session')}</Button>
          </Link>
        </EmptyPlaceholder>
      </AppContent>
    );
  }

  return (
    <AppContent>
      <Helmet>
        <title>{t('meta_title_sessions')} | Paragonix</title>
      </Helmet>
      <Card title={t('sessions')}>
        <SessionTable
          columns={[
            'joinKey',
            'createdAt',
            'sessionState',
            'organ',
            'donationType',
            'sessionType',
            'probeMin',
            'probeMax',
            'probeAvg',
            'unosId',
            'participation',
            'details',
          ]}
          sessions={sessions}
          options={{ hasFilters: true, hasPagination: true, hasRowSelect: true, initialSortBy: [{ id: 'createdAt', desc: true }] }}
        />
      </Card>
    </AppContent>
  );
};

export default Sessions;
