import { SessionType, SessionTypeUtil } from '@packages/firebase';
import { BadgeStyleTypes } from '../../Badge';
import Badge from '../../Badges/Badge';

function sessionTypeFormatter({ value }: { value: SessionType }) {
  if (value == null) {
    return <Badge variant="neutral">Unknown</Badge>;
  }

  const styleType: BadgeStyleTypes = value === SessionType.TRAINING ? 'warning' : 'success';

  return <Badge variant={styleType}>{SessionTypeUtil.toUIString(value)}</Badge>;
}

export default sessionTypeFormatter;
