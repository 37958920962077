import { useTranslation } from '@packages/translations';
import { formatTemperature } from '@packages/utils';
import { MdCircle } from 'react-icons/md';
import { TooltipProps } from 'recharts';

interface Props extends TooltipProps<string, number> {
  color: string | undefined;
}

function ChartTooltip({ active, payload, color }: Props) {
  const { t } = useTranslation();

  if (!active || payload == null || payload?.length !== 1) {
    return null;
  }

  const p = payload.find(elem => elem.dataKey === 'probe')?.payload;

  if (!p) {
    return null;
  }

  const { time, currentTime, probe } = p;

  return (
    <div className="bg-white shadow-lg">
      <div className="px-8 py-1 text-center text-white rounded-top" style={{ backgroundColor: color }}>
        <div className="text-[20px] font-[500]">{currentTime ?? '-'}</div>
        <div>+{time != null && time?.length > 0 ? time : '0m'}</div>
      </div>
      <div className="flex flex-row px-8 py-4">
        <div className="text-center">
          <div className="text-[20px]">{formatTemperature(probe, 1)}</div>
          <div className="uppercase text-muted text-[10px] inline-flex items-baseline">
            <MdCircle className="mr-1 text-product" />
            <span>{t('probe')}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChartTooltip;
