import { Menu, Transition } from '@headlessui/react';
import { User } from '@packages/firebase';
import classNames from 'classnames';
import { Fragment, useState } from 'react';
import { MdExpandMore } from 'react-icons/md';
import { useAuthUser } from '../../../contexts/useAuthUser';
import { logout } from '../../../lib/firebase/auth';
import Avatar from '../../Shared/Avatar';
import ProfileModal from '../../Shared/ProfileModal';
import SettingsModal from '../../Shared/SettingsModal';

export const HeaderDropdown = () => {
  return <Dropdown />;
};

const Dropdown = () => {
  const { user } = useAuthUser();

  return <UserDropdown user={user} />;
};

const UserDropdown = ({ user }: { user: User }) => {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);

  return (
    <>
      <Menu as="div" className="inline-block text-left sm:relative">
        <div>
          <Menu.Button className="inline-flex w-full items-center justify-center px-4 py-2 text-gray-700">
            <Avatar firstname={user?.firstname} lastname={user?.lastname} />

            <div className="hidden sm:flex">
              <div className="ml-2">
                <span>{`${user?.firstname} ${user?.lastname}`}</span>
              </div>

              <MdExpandMore className="-mr-1 ml-2 h-5 w-5" />
            </div>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-[10px] right-[10px] z-10 mt-4 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-xl sm:right-0 sm:mt-3 sm:w-64">
            <div className="py-1.5">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => setProfileOpen(true)}
                    className={classNames(
                      active ? 'text-black' : 'text-black',
                      'group block w-full px-10 py-2 text-left font-[400] hover:bg-[#f8f9fa] sm:px-4',
                    )}
                  >
                    <div>
                      <span className="text-xs text-gray-700">{user.email}</span>
                    </div>

                    <div>
                      <span>Profile</span>
                    </div>
                  </button>
                )}
              </Menu.Item>
            </div>

            <div className="py-1.5">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => setSettingsOpen(true)}
                    className={classNames(
                      active ? 'text-black' : 'text-black',
                      'group flex w-full items-center px-10 py-2 font-[400] hover:bg-[#f8f9fa] sm:px-4',
                    )}
                  >
                    <span>Settings</span>
                  </button>
                )}
              </Menu.Item>
            </div>

            <div className="py-1.5">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => logout()}
                    className={classNames(
                      active ? 'text-black' : 'text-black',
                      'group flex w-full items-center px-10 py-2 font-[400] hover:bg-[#f8f9fa] sm:px-4',
                    )}
                  >
                    <span className="text-red-500">Logout</span>
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <SettingsModal isOpen={settingsOpen} setIsOpen={setSettingsOpen} />
      <ProfileModal isOpen={profileOpen} setIsOpen={setProfileOpen} />
    </>
  );
};
