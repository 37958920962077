import { Routes } from '@packages/constants';
import { useTranslation } from '@packages/translations';
import { Assets } from '@packages/assets';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import withUser from '../components/HOC/withUser';
import EmptyLayout from '../components/Layouts/EmptyLayout';
import PrivacyPolicyText from '../components/PrivacyPolicyText';
import Button from '../components/Shared/Button';
import Switch from '../components/Shared/Switch';
import TextContent from '../components/Shared/TextContent';
import TermsOfUseText from '../components/TermsOfUseText';
import { CURRENT_TERMS_VERSION } from '../constants';
import { useAuthUser } from '../contexts/useAuthUser';
import { acceptTerms } from '../lib/firebase/firestore';

type Documents = 'terms_of_use' | 'privacy_policy';

const AcceptTerms = () => {
  const { t } = useTranslation();
  const { user, authUser } = useAuthUser();
  const navigate = useNavigate();

  const [termsDocument, setTermsDocument] = useState<Documents>('terms_of_use');
  const [acceptTermsOfUse, setAcceptTermsOfUse] = useState(false);
  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);

  function onAcceptTerms() {
    acceptTerms(authUser?.uid)?.then(() => navigate(Routes.web.DASHBOARD));
  }

  function onChangeDocument(document: Documents) {
    setTermsDocument(document);
    scrollTop();
  }

  function scrollTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  useEffect(() => {
    if (user != null && user.acceptedTermsVersion >= CURRENT_TERMS_VERSION) {
      navigate(Routes.web.DASHBOARD);
    }
  });

  return (
    <EmptyLayout title={t('meta_title_accept_terms')}>
      <div className="px-5 sm:px-10 md:px-24 pt-20 lg:pt-40">
        <Link to={Routes.web.ACCEPT_TERMS}>
          <img src={Assets.logo.paragonixLogoLightPng} alt="" style={{ height: '18px' }} />
        </Link>

        <h1 className="text-3xl md:text-4xl font-medium text-dark mt-16 mb-6">{t('terms_approval_title')}</h1>
        <p className="text-xl md:text-[20px] text-muted">{t('terms_approval_text')}</p>

        <div className="my-12 px-4 py-4 sm:px-8 sm:py-8 md:px-16 md:py-16 shadow-2xl">
          {termsDocument === 'terms_of_use' && (
            <TextContent title={t('terms_of_use')}>
              <TermsOfUseText />
            </TextContent>
          )}
          {termsDocument === 'privacy_policy' && (
            <TextContent title={t('privacy_policy')}>
              <PrivacyPolicyText />
            </TextContent>
          )}
        </div>
        <div className="mb-20 px-5">
          {termsDocument === 'terms_of_use' && (
            <div className="flex flex-col md:flex-row items-center justify-between mb-16">
              <div className="form-check form-switch form-switch-lg mb-10 md:mb-0">
                <Switch checked={acceptTermsOfUse} onChange={() => setAcceptTermsOfUse(!acceptTermsOfUse)}>
                  <span className="text-sm text-dark font-medium">{t('accept_terms_of_use_text')}</span>
                </Switch>
              </div>

              <div className="w-full md:w-auto flex flex-col-reverse">
                <Button disabled={!acceptTermsOfUse} onClick={() => onChangeDocument('privacy_policy')}>
                  {t('next')}
                </Button>
              </div>
            </div>
          )}

          {termsDocument === 'privacy_policy' && (
            <div className="flex flex-col md:flex-row justify-between items-center mb-16">
              <div className="mb-10 md:mb-0">
                <Switch checked={acceptPrivacyPolicy} onChange={() => setAcceptPrivacyPolicy(!acceptPrivacyPolicy)}>
                  <span className="text-sm text-dark font-medium">{t('accept_privacy_policy_text')}</span>
                </Switch>
              </div>

              <div className="flex flex-col-reverse w-full md:w-auto md:flex-row gap-2">
                <Button disabled={false} onClick={() => onChangeDocument('terms_of_use')}>
                  {t('back')}
                </Button>
                <Button variant="success" disabled={!acceptTermsOfUse || !acceptPrivacyPolicy} onClick={() => onAcceptTerms()}>
                  {t('to_dashboard')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </EmptyLayout>
  );
};

export default withUser(AcceptTerms);
