import { Assets } from '@packages/assets';
import { Routes } from '@packages/constants';
import { OrganizationOption } from '@packages/firebase';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import InputField from '../components/Forms/InputField';
import SelectField from '../components/Forms/SelectField';
import withUser from '../components/HOC/withUser';
import EmptyLayout from '../components/Layouts/EmptyLayout';
import Button from '../components/Shared/Button';
import Card from '../components/Shared/Card';
import SearchFilter from '../components/Shared/Table/filters/SearchFilter';
import { useAuthUser } from '../contexts/useAuthUser';
import { useFetchOrganizationOptionsConfig, useFetchOrganizationUserRolesConfig } from '../lib/firebase/firestore';
import { requestOrganizationAccess } from '../lib/firebase/functions';

const OrganizationSelect = () => {
  const { member } = useAuthUser();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  // Step 1
  const [organization, setOrganization] = useState<any>();

  if (member != null) {
    navigate(Routes.web.DASHBOARD);
    return null;
  }

  return (
    <EmptyLayout title="Organization join | Paragonix">
      <div className="mx-auto max-w-xl px-6 py-12">
        <div className="flex justify-center pb-8">
          <img src={Assets.logo.paragonixLogoLightPng} style={{ height: '18px' }} alt="" />
        </div>

        {searchParams.has('leave') && <SelectNewOrganization />}

        {!searchParams.has('leave') && organization == null && <SelectOrganizationOption onSelect={setOrganization} />}

        {!searchParams.has('leave') && organization != null && (
          <JoinOrganization organization={organization} onBack={() => setOrganization(undefined)} />
        )}
      </div>
    </EmptyLayout>
  );
};

const SelectNewOrganization = () => {
  const navigate = useNavigate();

  return (
    <Card title="Already Part of a New Organization?">
      <div className="space-y-4">
        <p className="text-muted font-[14px]">
          To make your transition to a new team as smooth as possible, let us know if you’re already part of a new team.
        </p>

        <div className="space-y-2.5">
          <Button className="w-full" onClick={() => navigate('/select-organization')}>
            Yes
          </Button>

          <Button className="w-full" variant="outline" onClick={() => navigate('/')}>
            No
          </Button>
        </div>
      </div>
    </Card>
  );
};

const SelectOrganizationOption = ({ onSelect }: { onSelect: (selected: any) => void }) => {
  const { organizationOptions, isLoading } = useFetchOrganizationOptionsConfig();

  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationOption>();
  const [search, setSearch] = useState<string>();

  const filteredOptions = useMemo(() => {
    if (organizationOptions == null) {
      return [];
    }

    if (search == null || search.length === 0) {
      return organizationOptions;
    }

    return organizationOptions.filter(
      option => option.name.toLowerCase().includes(search.toLowerCase()) || option.handle.toLowerCase().includes(search.toLowerCase()),
    );
  }, [organizationOptions, isLoading, search]);

  async function createNewOrganization() {
    const confirm = window.confirm(
      'Are you sure the organization does not exist in the list? If so, click OK to create a new organization.',
    );

    if (!confirm) {
      return;
    }

    onSelect({ name: search });
  }

  async function joinOrganization() {
    if (selectedOrganization == null) {
      return;
    }

    const confirm = window.confirm(`Are you sure you want to join ${selectedOrganization.name}?`);

    if (!confirm) {
      return;
    }

    if (selectedOrganization.organizationId != null) {
      onSelect({ id: selectedOrganization.organizationId });
    } else {
      onSelect({ name: selectedOrganization.name, handle: selectedOrganization.handle });
    }
  }

  return (
    <Card title="Choose Organization">
      <div className="space-y-4">
        <p className="text-muted font-[14px]">Choose which organization you will be using this account with.</p>

        <SearchFilter onChange={event => setSearch(event?.target?.value)} placeholder="Search Organizations" />

        <hr />

        <div>
          {isLoading ? (
            <div className="flex h-[500px] w-full items-center justify-center rounded border">
              <div className="spinner-border inline-block h-7 w-7 animate-spin rounded-full border-2" role="status" />
            </div>
          ) : (
            <div className="h-[500px] w-full space-y-2.5 overflow-y-scroll">
              {search != null && search.length > 0 && (
                <button
                  type="button"
                  className="block w-full rounded border-[#343a40] bg-[#343a40] py-1.5 px-2.5 text-left text-[#eff2f7] hover:border-[#2c3136] hover:bg-[#2c3136]"
                  onClick={() => createNewOrganization()}
                >
                  <p className="text-white">
                    <strong>{search}</strong>
                  </p>
                  <p className="text-sm text-white">Add a new organization</p>
                </button>
              )}

              {filteredOptions.map((option, index) => (
                <button
                  type="button"
                  key={`org-option-${index}`}
                  onClick={() => setSelectedOrganization(elem => (elem?.id !== option.id ? option : undefined))}
                  className={classNames(
                    selectedOrganization?.id === option.id ? 'border-success-500 border-2' : 'border',
                    'block w-full rounded py-1.5 px-2.5 text-left hover:bg-gray-50',
                  )}
                >
                  <p>{option.name}</p>
                  <p className="text-muted text-sm">{option.handle}</p>
                </button>
              ))}
            </div>
          )}
        </div>

        <hr />

        <Button className="w-full" disabled={selectedOrganization == null} onClick={() => joinOrganization()}>
          Continue
        </Button>
      </div>
    </Card>
  );
};

const JoinOrganization = ({ organization, onBack }: { organization: any; onBack: () => void }) => {
  const navigate = useNavigate();
  const config = useFetchOrganizationUserRolesConfig();
  const [error] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    startOfEmployment: '',
    title: '',
    role: '',
  };

  const validationSchema = yup.object().shape({
    startOfEmployment: yup.string().required('Please enter a start of employment'),
    role: yup.string().required('Please select a role'),
    title: yup.string().required('Please enter a title'),
  });

  async function onSubmit({ startOfEmployment, title, role }) {
    if (organization == null) {
      return;
    }

    if (startOfEmployment == null || title == null || role == null) {
      return;
    }

    const confirm = window.confirm(`Are you sure the data you entered is correct? If so, click OK to join!`);

    if (!confirm) {
      return;
    }

    setIsSubmitting(true);

    try {
      await requestOrganizationAccess({ organization, startOfEmployment, title, role });
      toast.success('Your account has been created successfully! You are all ready to go!');
      navigate(Routes.web.DASHBOARD);
    } catch (err: any) {
      toast.error(err.message);
      setIsSubmitting(false);
    }
  }

  return (
    <Card title="Your Profile">
      <div className="space-y-4">
        <p className="text-muted font-[14px]">
          State which role best matches the role in your team, enter your job title and start of employment.
        </p>

        {error && error.message}

        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={values => onSubmit(values)}>
          {({ errors, touched }) => (
            <Form>
              <div className="space-y-4">
                <InputField name="startOfEmployment" type="date" error={errors.startOfEmployment} touched={touched.startOfEmployment} />

                <SelectField name="role" label="Role" error={errors.role} touched={touched.role}>
                  <option key="select-role" value="">
                    --- Select a Role ---
                  </option>
                  {config.userRoles?.map(role => (
                    <option key={role.id} value={role.id}>
                      {role.locales?.en}
                    </option>
                  ))}
                </SelectField>

                <InputField
                  name="title"
                  label="Title"
                  type="text"
                  placeholder="e.g. Transplant Surgeon"
                  error={errors.title}
                  touched={touched.title}
                />

                <Button type="submit" className="w-full" disabled={isSubmitting} isLoading={isSubmitting}>
                  Continue
                </Button>

                <Button className="w-full" variant="outline" onClick={() => onBack()}>
                  Back
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Card>
  );
};

export default withUser(OrganizationSelect);
