import { DonationType, ProductType, Session, SessionState, SessionType } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import classNames from 'classnames';
import { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import CellFormatters from '../../Shared/Table/CellFormatters';

import configureTable from '../../Shared/Table/configureTable';

import { useAuthUser } from '../../../contexts/useAuthUser';
import SortToggle from '../../Shared/Table/SortToggle';
import SessionTableFilters from './Filters';
import SessionTablePagination from './Pagination';

type SessionTableColumn =
  | 'joinKey'
  | 'createdAt'
  | 'sessionState'
  | 'organ'
  | 'donationType'
  | 'sessionType'
  | 'probeMin'
  | 'probeAvg'
  | 'probeMax'
  | 'unosId'
  | 'participation'
  | 'details';

type SessionTableProps = {
  sessions: Session[] | undefined;
  columns: SessionTableColumn[];
  options: any;
};

export default function SessionTable({ sessions, columns, options }: SessionTableProps) {
  const { t } = useTranslation();
  const { authUser } = useAuthUser();

  const joinKeyFilter = (rows, ids, filterValue) => {
    return rows.filter(row => {
      return ids.some(id => {
        const rowValue = row.values[id].joinKey;
        return String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase());
      });
    });
  };

  const allColumns: Column[] = [
    {
      Header: t('key'),
      id: 'joinKey',
      accessor: row => new Session(row as any),
      Cell: CellFormatters.joinKey,
      filter: joinKeyFilter,
    },
    {
      Header: t('date'),
      id: 'createdAt',
      accessor: 'createdAt',
      Cell: CellFormatters.datetime,
      sortType: 'datetime',
    },
    {
      Header: t('session_state'),
      id: 'sessionState',
      accessor: row => new Session(row as any).getState(),
      Cell: CellFormatters.sessionState,
      filter: (rows, columnIds, filterValue: SessionState) => {
        if (!filterValue || columnIds[0] !== 'sessionState') return rows;

        return rows.filter(row => row.values.sessionState === filterValue);
      },
    },
    {
      Header: t('organ'),
      id: 'organ',
      accessor: 'organ',
      Cell: CellFormatters.organ,
      filter: (rows, columnIds, filterValue: ProductType) => {
        if (!filterValue || columnIds[0] !== 'organ') return rows;

        return rows.filter(row => row.values.organ === filterValue);
      },
    },
    {
      Header: t('donation_type'),
      id: 'donationType',
      accessor: 'donationType',
      Cell: CellFormatters.donationType,
      filter: (rows, columnIds, filterValue: DonationType) => {
        if (!filterValue || columnIds[0] !== 'donationType') return rows;

        return rows.filter(row => row.values.donationType === filterValue);
      },
    },
    {
      Header: t('session_type'),
      id: 'sessionType',
      accessor: 'sessionType',
      Cell: CellFormatters.sessionType,
      filter: (rows, columnIds, filterValue: SessionType) => {
        if (!filterValue || columnIds[0] !== 'sessionType') return rows;

        return rows.filter(row => row.values.sessionType === filterValue);
      },
    },
    {
      id: 'probeMin',
      Header: `${t('min_abbrevation')} ${t('temperature_abbrevation')}`,
      accessor: 'reportData.probeMinimum',
      sortType: 'basic',
      Cell: CellFormatters.temperature,
    },
    {
      id: 'probeMax',
      Header: `${t('max_abbrevation')} ${t('temperature_abbrevation')}`,
      accessor: 'reportData.probeMaximum',
      sortType: 'basic',
      Cell: CellFormatters.temperature,
    },
    {
      id: 'probeAvg',
      Header: `${t('avg_abbrevation')} ${t('temperature_abbrevation')}`,
      accessor: 'reportData.probeAverage',
      sortType: 'basic',
      Cell: CellFormatters.temperature,
    },
    {
      id: 'unosId',
      Header: 'Donor ID',
      accessor: 'unosId',
      sortType: 'basic',
      Cell: row => row.value ?? '-',
    },
    {
      id: 'participation',
      Header: 'Participation',
      sortType: 'basic',
      accessor: 'userIds',
      Cell: row => (row.value.includes(authUser.uid) ? 'Member' : 'Organization'),
    },
    {
      Header: t('view_details'),
      id: 'details',
      accessor: (row: any) => row.id,
      Cell: CellFormatters.viewDetails,
      disableSortBy: true,
    },
  ].filter(Boolean) as Column[];

  const pickedColumns = useMemo<Column[]>(() => {
    return columns.map(columnId => allColumns.find(c => c.id === columnId)).filter((c): c is Column => !!c);
  }, [columns]);

  const data = useMemo(() => {
    if (sessions == null) {
      return [];
    }

    return sessions.filter(session => session.createdAt != null);
  }, [sessions]);

  const { tableOptions, tablePlugins } = configureTable(pickedColumns, data, options);
  const table = useTable(tableOptions, ...tablePlugins);

  return (
    <>
      {options?.hasFilters && <SessionTableFilters table={table} />}

      <div className="overflow-hidden overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="overflow-scroll">
            <table className="min-w-full">
              <thead className="mb-1 hidden bg-gray-200 lg:table-header-group">
                {table.headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th
                        scope="col"
                        className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-4"
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        <div className="flex items-center space-x-1">
                          <span className="text-base">{column.render('Header')}</span>
                          <span>
                            <SortToggle isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody className="rounded border border-gray-100 bg-white lg:border-none" {...table.getTableBodyProps()}>
                {(options?.hasPagination ? table.page : table.rows).map(row => {
                  table.prepareRow(row);

                  return (
                    <tr className="border-b border-gray-100 hover:bg-gray-50" {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td
                            className={classNames(
                              { 'hidden md:table-cell': cell.column.id !== 'joinKey' },
                              'whitespace-nowrap px-4 py-4 text-gray-500',
                            )}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {options?.hasPagination && <SessionTablePagination table={table} />}
        </div>
      </div>
    </>
  );
}
