import { Assets } from '@packages/assets';
import { Routes } from '@packages/constants';
import { OrganTypeUtil, OrganizationMemberStatus, OrganizationStatus, TimelineEntryTypeUtil } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import classNames from 'classnames';
import { useMemo } from 'react';
import { MdHome, MdOutlineAddCircle, MdOutlineFormatListBulleted, MdOutlineHelpOutline } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { useAuthUser } from '../../../contexts/useAuthUser';
import { useChat } from '../../../contexts/useChat';
import { useFetchSessions } from '../../../lib/firebase/firestore';

type SidebarProps = {
  isSidebarOpen: boolean;
  toggleSidebar: (forceClose: boolean) => void;
};

export default function Sidebar({ isSidebarOpen, toggleSidebar }: SidebarProps) {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { organization, member } = useAuthUser();

  const [activeSessions, areSessionsLoading] = useFetchSessions({ organizationId: organization?.id, finished: false });
  const { unreadChannelsCount } = useChat();

  const navigationItems = useMemo(
    () => [
      {
        name: 'Dashboard',
        href: Routes.web.DASHBOARD,
        icon: MdHome,
        isActive: (pathname: string) => pathname === Routes.web.ROOT || pathname === Routes.web.DASHBOARD,
      },
      {
        name: 'Sessions',
        href: Routes.web.SESSIONS,
        icon: MdOutlineFormatListBulleted,
        current: pathname.includes(Routes.web.SESSIONS),
        isActive: (pathname: string) => pathname === Routes.web.SESSIONS,
      },
      {
        name: 'Support',
        href: Routes.web.SUPPORT,
        icon: MdOutlineHelpOutline,
        current: pathname.includes(Routes.web.SUPPORT),
        isActive: (pathname: string) => pathname === Routes.web.SUPPORT,
      },
    ],
    [pathname],
  );

  return (
    <div className={classNames({ hidden: !isSidebarOpen }, 'fixed top-0 bottom-0 z-[12] w-[250px] bg-[#12182c] lg:block')}>
      <div className="flex justify-center pt-8">
        <Link to={Routes.web.DASHBOARD}>
          <img className="h-[19px] w-auto" src={Assets.logo.paragonixLogoDarkPng} alt="Workflow" />
        </Link>
      </div>
      <div className="h-100 z-[20]">
        <ul className="mt-14 px-6">
          <li className="mb-6 text-[11px] font-[600] uppercase tracking-[0.05] text-[#6a7187]">{t('sidebar_menu')}</li>

          {navigationItems.map(item => {
            if (item == null) {
              return null;
            }

            return (
              <li key={item.name} className="mb-6">
                <Link
                  to={item.href}
                  onClick={() => toggleSidebar(true)}
                  className={classNames(item.isActive(pathname) ? 'text-white' : 'text-[#a6b0cf]', 'group mb-3 hover:text-white')}
                >
                  <div className="flex items-center">
                    <item.icon
                      className={classNames(
                        item.isActive(pathname) ? 'text-white' : 'text-[#6a7187]',
                        'mr-5 h-7 w-7 flex-shrink-0 group-hover:text-white',
                      )}
                      aria-hidden="true"
                    />
                    <span>{item.name}</span>
                  </div>
                </Link>
              </li>
            );
          })}

          {organization != null &&
            organization.status === OrganizationStatus.ACTIVE &&
            member != null &&
            member.status === OrganizationMemberStatus.ACTIVE && (
              <>
                <li className="mb-5 mt-5 text-[11px] font-[600] uppercase tracking-[0.05] text-[#6a7187]">Organization</li>

                <li className="mb-6">
                  <Link
                    to={`/organization/${organization.id}`}
                    onClick={() => toggleSidebar(true)}
                    className={classNames(pathname.startsWith('/organization') ? 'text-white' : 'text-[#a6b0cf]', 'group hover:text-white')}
                  >
                    <div className="flex items-center space-x-2.5">
                      {organization.logoUrl ? (
                        <div className="flex h-8 w-8 items-center justify-center rounded-md border bg-gray-100">
                          <img className="inline-block max-h-full w-8 rounded-md" src={organization.logoUrl} alt="" />
                        </div>
                      ) : (
                        <div className="inline-block h-8 w-8 rounded-md border bg-gray-100" />
                      )}

                      <div>{organization.name}</div>
                    </div>
                  </Link>
                </li>
              </>
            )}

          <li className="mb-5 mt-5 text-[11px] font-[600] uppercase tracking-[0.05] text-[#6a7187]">{t('sidebar_live_sessions')}</li>

          <li className="mb-6">
            <Link
              to={Routes.web.JOIN_NO_KEY}
              onClick={() => toggleSidebar(true)}
              className={classNames(
                pathname.startsWith(Routes.web.JOIN_NO_KEY) ? 'text-white' : 'text-[#a6b0cf]',
                'group hover:text-white',
              )}
            >
              <div className="">
                <div className="flex flex-row items-center">
                  <MdOutlineAddCircle
                    className={classNames(
                      pathname.startsWith(Routes.web.JOIN_NO_KEY) ? 'text-white' : 'text-[#6a7187]',
                      'mr-5 h-7 w-7 flex-shrink-0 group-hover:text-white',
                    )}
                  />
                  <span>{t('join_session')}</span>
                </div>
              </div>
            </Link>
          </li>

          {!areSessionsLoading &&
            activeSessions != null &&
            activeSessions.map((session: any) => {
              const sessionPath = Routes.web.SESSION_DETAIL.replace(':id', session.id);

              return (
                <li key={`live-session-menu-item-${session.joinKey}`} className="mb-6">
                  <Link
                    to={sessionPath}
                    onClick={() => toggleSidebar(true)}
                    className={classNames(pathname === sessionPath ? 'text-white' : 'text-[#a6b0cf]', 'mb-3 hover:text-white')}
                  >
                    <div className="flex justify-between">
                      <div className="flex items-start">
                        <div className="mr-5">
                          <img className="h-8 w-8" src={OrganTypeUtil.iconPath(session.organ, true)} alt="" />
                        </div>
                        <div>
                          <div className="inline-flex items-center font-medium">
                            <div>{`Session ${session.joinKey}`}</div>
                            {!!unreadChannelsCount[session.id] && unreadChannelsCount[session.id] > 0 && (
                              <div className="text-product ml-2 rounded bg-white px-1.5 text-sm">{unreadChannelsCount[session.id]}</div>
                            )}
                          </div>
                          {session.unosId != null && <div>{`Donor ID: ${session.unosId}`}</div>}
                          <div className="text-muted text-[12px]">
                            {TimelineEntryTypeUtil.toUIString(session.lastTimelineEvent, session.product)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
