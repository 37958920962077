import { useTranslation } from '@packages/translations';
import { MdClose } from 'react-icons/md';
import Modal from '../Shared/Modal';

function VideoModal({ isOpen, onToggle, title, videoUrl }) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} setIsOpen={onToggle} size="6xl">
      <div className="py-5 px-5 border-b flex justify-between items-start">
        <span className="text-[16px] font-[500]">{title}</span>
        <button type="button" onClick={onToggle}>
          <MdClose className="text-muted h-5 w-5" />
        </button>
      </div>
      <div className="p-5">
        <video width="100%" height="100%" controls autoPlay>
          <source src={videoUrl} type="video/mp4" />
          <track kind="captions" />
          {t('video_tag_not_supported')}
        </video>
      </div>
    </Modal>
  );
}

export default VideoModal;
