import { formatDate, FormatDateOptions } from '@packages/utils';
import { useAuthUser } from '../../../../contexts/useAuthUser';

function datetimeFormatter({ value }: { value: Date }) {
  const { user } = useAuthUser();

  const options: FormatDateOptions = {
    hasTimezone: user.settings.includeTimezone,
    has24Hours: user.settings.force24HoursFormat,
  };

  return <span>{value != null ? formatDate(value, options) : '-'}</span>;
}

export default datetimeFormatter;
