import { getFunctions, httpsCallable } from 'firebase/functions';
import app, { FIREBASE_REGION } from './config';

export type RequestOrganizationAccessParams = {
  organization: WithId | WithName;
  startOfEmployment: string;
  role: string;
  title: string;
};

type WithId = {
  id: string;
  name?: never;
  handle?: never;
};

type WithName = {
  id?: never;
  name: string;
  handle?: string;
};

export function getFirebaseFunctions() {
  return getFunctions(app, FIREBASE_REGION);
}

export async function sendOrganizationAdminInvite(organizationId: string) {
  const functions = getFirebaseFunctions();

  const fn = httpsCallable(functions, 'sendOrganizationAdminInvite');

  return fn({ organizationId });
}

export async function joinSession(joinKey: number) {
  const functions = getFirebaseFunctions();
  const fn = httpsCallable(functions, 'joinSession');

  return fn({ os: 'web', joinKey, schemaVersion: 4 });
}

export async function leaveSession(sessionId: string) {
  const functions = getFirebaseFunctions();
  const fn = httpsCallable(functions, 'leaveSession');

  return fn({ sessionId });
}

export async function getStreamToken() {
  const functions = getFirebaseFunctions();
  const fn = httpsCallable(functions, 'ext-auth-chat-getStreamUserToken');

  return fn();
}

export async function sendPasswordReset(email: string) {
  const functions = getFirebaseFunctions();
  const fn = httpsCallable(functions, 'sendPasswordReset');

  return fn({ email, source: 'web' });
}

export async function confirmOrganizationValidation(organizationId: string, userIds: string[]) {
  const functions = getFirebaseFunctions();
  const fn = httpsCallable(functions, 'confirmOrganizationValidation');

  return fn({ organizationId, userIds });
}

export async function joinOrganization(organizationId: string, title: string, role: string, startOfEmployment: string) {
  const functions = getFirebaseFunctions();
  const fn = httpsCallable(functions, 'joinOrganization');

  return fn({ organizationId, startOfEmployment, title, role });
}

export async function leaveOrganization(organizationId: string) {
  const functions = getFirebaseFunctions();
  const fn = httpsCallable(functions, 'leaveOrganization');

  return fn({ organizationId });
}

export async function requestOrganizationAccess(params: RequestOrganizationAccessParams) {
  const functions = getFirebaseFunctions();
  const fn = httpsCallable(functions, 'requestOrganizationAccess');

  return fn(params);
}

export async function sendEmailVerification() {
  const functions = getFirebaseFunctions();
  const fn = httpsCallable(functions, 'sendEmailVerification');

  return fn({ source: 'web' });
}

export async function confirmEmailVerification(code: string) {
  const functions = getFirebaseFunctions();
  const fn = httpsCallable(functions, 'confirmEmailVerification');

  return fn({ code });
}

export function activateOrganizationMember(organizationId: string, userId: string) {
  const functions = getFirebaseFunctions();

  const fn = httpsCallable(functions, 'activateOrganizationMember');

  return fn({ organizationId, userId });
}

export function deactivateOrganizationMember(organizationId: string, userId: string) {
  const functions = getFirebaseFunctions();

  const fn = httpsCallable(functions, 'deactivateOrganizationMember');

  return fn({ organizationId, userId });
}

export function grantOrganizationAccess(organizationId: string, userId: string) {
  const functions = getFirebaseFunctions();

  const fn = httpsCallable(functions, 'grantOrganizationAccess');

  return fn({ organizationId, userIds: [userId] });
}

export function sendOrganizationMemberInvite(organizationId: string, userIds: string[]) {
  const functions = getFirebaseFunctions();

  const fn = httpsCallable(functions, 'sendOrganizationMemberInvite');

  return fn({ organizationId, userIds });
}

export function inviteToOrganization(organizationId: string, email: string) {
  const functions = getFirebaseFunctions();

  const fn = httpsCallable(functions, 'inviteToOrganization');

  return fn({ organizationId, email });
}
