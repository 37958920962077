import { useTranslation } from '@packages/translations';
import CookieConsent from 'react-cookie-consent';

function CookieBanner() {
  const { t } = useTranslation();

  return (
    <CookieConsent
      buttonText={t('accept')}
      declineButtonText={t('decline')}
      disableButtonStyles
      location="bottom"
      buttonClasses="bg-white text-black p-3 rounded "
      buttonWrapperClasses="container flex justify-end mt-10"
      containerClasses="w-full px-10 flex flex-col content-center items-center !bg-[#343a40] text-muted py-16"
      contentClasses="mb-5"
      contentStyle={{ flex: 'none', margin: '0px' }}
      style={{ zIndex: 10000 }}
    >
      <div className="container">{t('cookie_banner_text')}</div>
    </CookieConsent>
  );
}

export default CookieBanner;
