import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { MdOutlineFilterList } from 'react-icons/md';
import Badge from '../../Badges/Badge';

export default function Select({ label, values, onChange }: { label: string; values: any; onChange: any }) {
  const [selected, setSelected] = useState<any>(values[0]);

  const onSelectChange = selectedState => {
    const s = values.find(state => state.value === selectedState.value);
    setSelected(s);
    onChange(s?.value);
  };

  return (
    <Listbox value={selected} onChange={onSelectChange}>
      {({ open }) => (
        <div className="relative">
          <Listbox.Button className="relative pointer rounded-md border border-[#eff2f7] text-[#212529] bg-white py-2 pr-3 pl-12 text-base  hover:border-[#eff2f7] hover:bg-[#eff2f7] hover:text-black">
            <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
              <MdOutlineFilterList className="w-5 h-5" />
            </span>
            {selected?.value && <Badge variant={selected.badge}>{selected.title}</Badge>}
            {!selected.value && <span className="block truncate">{label}</span>}
          </Listbox.Button>

          <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute z-10 mt-1 min-w-[10rem] max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {values.map(person => (
                <Listbox.Option
                  key={person.value}
                  className="text-gray-900 relative cursor-pointer select-none py-2 px-4 hover:bg-[#eff2f7] hover:text-black"
                  value={person}
                >
                  {person.title}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
