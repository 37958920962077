import classNames from 'classnames';
import { ErrorMessage, Field } from 'formik';
import { ReactElement } from 'react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

type InputFieldProps = {
  type: 'text' | 'email' | 'password' | 'date';
  name: string;
  label?: string;
  placeholder?: string;
  cornerElement?: ReactElement;
  error?: string;
  touched?: boolean;
  disabled?: boolean;
};

export default function InputField({ type, name, label, error, touched, placeholder, cornerElement, disabled = false }: InputFieldProps) {
  return (
    <div>
      {label && (
        <div className="flex justify-between">
          <label htmlFor={name} className={classNames('text-dark block font-medium', { 'text-red-500': error && touched })}>
            {label}
          </label>

          {cornerElement}
        </div>
      )}
      <div className="relative mt-1 rounded-md shadow-sm">
        <Field
          id={name}
          name={name}
          placeholder={placeholder}
          type={type}
          className={classNames(
            'block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 pr-10 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 disabled:cursor-not-allowed disabled:opacity-50',
            { 'border-red-500': error && touched },
          )}
          disabled={disabled}
        />

        {error && touched ? (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <HiOutlineExclamationCircle className="h-5 w-5 text-red-500" />
          </div>
        ) : null}
      </div>

      <p className="mt-2 text-sm text-red-600">
        <ErrorMessage name={name} />
      </p>
    </div>
  );
}
